import { NavigationTitleBoundary } from '../NavigationTitleBoundary';
import { ToolbarContainer } from '@orthly/ui';
import { FlossPalette, stylesFactory, Badge, Grid, Tab, Tabs, createStyles, withStyles } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';

const useStyles = stylesFactory(() => ({
    root: {
        alignContent: 'flex-start',
        overflow: 'auto',
    },
    toolbar: {
        background: FlossPalette.TAN,
        borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        padding: 0,
        alignItems: 'flex-end',
    },
}));

const TabBadge = withStyles(() =>
    createStyles({
        anchorOriginTopRightRectangle: {
            transform: 'none',
            transformOrigin: '0 0',
        },
        badge: {
            backgroundColor: FlossPalette.STAR_GRASS,
            color: FlossPalette.WHITE,
            marginLeft: 5,
            position: 'relative',
        },
    }),
)(Badge);

export const TabLabel: React.FC<{ count?: number; children: React.ReactNode }> = props => {
    const { count } = props;
    if (typeof count !== 'number' || count <= 0) {
        return <>{props.children}</>;
    }
    return (
        <TabBadge badgeContent={count} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} overlap={'rectangular'}>
            {props.children}
        </TabBadge>
    );
};

export interface RouterTabItem {
    Component?: React.ComponentType;
    path: string;
    label?: string;
    count?: number;
}

interface RouterTabsProps {
    items: RouterTabItem[];
    defaultPath?: string;
}

function getNavTitle(basePath: string, activeItem?: RouterTabItem): string {
    const activeItemLabel = activeItem ? _.startCase(activeItem.label ?? activeItem.path) : null;
    return _.uniq(_.compact([activeItemLabel, _.startCase(basePath)])).join(' | ');
}

export const RouterTabs: React.FC<RouterTabsProps> = props => {
    const { items } = props;
    const classes = useStyles();
    const navigate = useNavigate();
    const { url: basePath } = useRouteMatch();
    const { pathname: fullPath, ...locationRest } = useLocation();
    const getFullPath = React.useCallback(
        (tabPath: string) => (tabPath === '' ? basePath : `${basePath}/${tabPath}`),
        [basePath],
    );
    const onTabChange = React.useCallback(
        (newPath: string) => fullPath !== newPath && navigate({ pathname: newPath, ...locationRest }),
        [navigate, fullPath, locationRest],
    );
    const activeItem = React.useMemo(() => {
        return items.find(i =>
            i.path === '' ? fullPath === getFullPath(i.path) : fullPath.includes(getFullPath(i.path)),
        );
    }, [items, fullPath, getFullPath]);
    const defaultPath = props.defaultPath ?? items[0]?.path ?? '/';
    return (
        <NavigationTitleBoundary title={getNavTitle(basePath, activeItem)}>
            <Grid container className={classes.root}>
                <ToolbarContainer sticky>
                    <Tabs
                        indicatorColor={'secondary'}
                        textColor={'inherit'}
                        value={getFullPath(activeItem?.path ?? defaultPath)}
                        onChange={(_evt, fullPath) => onTabChange(fullPath)}
                    >
                        {items.map(({ path, label, count }) => (
                            <Tab
                                key={path}
                                label={<TabLabel count={count}>{label ?? _.startCase(path)}</TabLabel>}
                                value={getFullPath(path)}
                            />
                        ))}
                    </Tabs>
                </ToolbarContainer>
                <Switch>
                    {items.map(item =>
                        !item.Component ? null : (
                            <Route key={item.path} component={item.Component} path={getFullPath(item.path)} />
                        ),
                    )}
                    {props.children}
                    <Redirect to={getFullPath(defaultPath)} />
                </Switch>
            </Grid>
        </NavigationTitleBoundary>
    );
};

import { RouterUtils } from '../../utils/router/RouterUtils';
import { CreateOrganizationAction } from './actions/CreateOrganizationAction';
import type { LabsGqlOrganizationWithDescendantsFragment } from '@orthly/graphql-operations';
import { useListOrganizationsWithHierarchySummaryQuery } from '@orthly/graphql-react';
import { downloadAsCsv, MUITable } from '@orthly/mui-table';
import { IOrganizationType } from '@orthly/retainer-common';
import { Text, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

interface OrganizationTableRow {
    id: string;
    name: string;
    organization: string[];
    created_at: string;
    practices_count: number;
}

interface OrganizationTableCsvRow {
    id: string;
    name: string;
    organization: string;
    created_at: string;
    practices_count: number;
}

interface OrganizationsTableToolbarRightProps {
    parentOrg?: LabsGqlOrganizationWithDescendantsFragment;
    onSubmit: () => Promise<void>;
}

const OrganizationsTableToolbarRight: React.FC<OrganizationsTableToolbarRightProps> = props => {
    const { parentOrg, onSubmit } = props;
    return (
        <Grid style={{ overflowX: 'auto', padding: '10px 0 ' }}>
            <Grid
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: 'max-content',
                    gap: 4,
                }}
            >
                <Grid item>
                    <CreateOrganizationAction parentOrg={parentOrg} onSubmit={onSubmit} />
                </Grid>
            </Grid>
        </Grid>
    );
};

interface OrganizationsTableProps {
    parentOrg?: LabsGqlOrganizationWithDescendantsFragment;
    refetchParentOrg?: () => Promise<void>;
}

interface OrganizationFieldProps {
    organization: string[];
}

export const OrganizationField: React.FC<OrganizationFieldProps> = ({ organization }) => {
    const first = _.first(organization);
    const last = _.last(organization);
    return (
        <>
            {first && <strong>{first}</strong>}
            {first && last && first !== last && (
                <>
                    <br />
                    <Text variant={'caption'}>{last}</Text>
                </>
            )}
        </>
    );
};

function tableRowsToCsvRows(tableRows: OrganizationTableRow[]): OrganizationTableCsvRow[] {
    return tableRows.map(row => ({
        ...row,
        organization: row.organization.join(' > '),
    }));
}

export const OrganizationsTable: React.FC<OrganizationsTableProps> = props => {
    const { parentOrg, refetchParentOrg } = props;
    const basePath = `${RouterUtils.pathForScreen('accounts')}/organizations`;
    const navigate = useNavigate();
    const { data, loading, refetch } = useListOrganizationsWithHierarchySummaryQuery({
        variables: {
            filter: parentOrg
                ? { ids: parentOrg.descendants.filter(o => o.type === IOrganizationType.parent).map(o => o.id) }
                : null,
        },
    });
    const rows = React.useMemo<OrganizationTableRow[]>(() => {
        return (data?.listOrganizationsWithHierarchySummary ?? []).map(org => ({
            ..._.pick(org, ['id', 'name', 'practices_count']),
            organization: org.ancestors.map(ancestor => ancestor.name).reverse(),
            created_at: moment(org.created_at).format('MM/DD/YYYY'),
        }));
    }, [data]);
    return (
        <Grid container style={{ width: '100%' }}>
            <Grid item xs={12}>
                <MUITable<OrganizationTableRow>
                    title={parentOrg ? `${parentOrg.legal_name} - Sub-organizations` : 'Organizations'}
                    loading={loading}
                    toolbarOptions={{
                        CustomRight: () => (
                            <OrganizationsTableToolbarRight
                                parentOrg={parentOrg}
                                onSubmit={async () => {
                                    if (parentOrg && refetchParentOrg) {
                                        await refetchParentOrg();
                                        return;
                                    }
                                    await refetch();
                                }}
                            />
                        ),
                    }}
                    displayOptions={{ filter: true, fixedSearch: true }}
                    eventHooks={{ onRowClick: row => navigate(`${basePath}/${row.id}`) }}
                    columns={[
                        { name: 'id', render: row => row.id, hidden: true },
                        {
                            name: 'Name',
                            field: row => row.name,
                            render: row => <strong>{row.name}</strong>,
                            defaultSort: 'asc',
                        },
                        {
                            name: 'Organization',
                            field: row => row.organization.join(' '),
                            render: row => <OrganizationField organization={row.organization} />,
                        },
                        { name: 'Practices', render: row => row.practices_count },
                        { name: 'Created Date', render: row => row.created_at, defaultSort: 'desc' },
                    ]}
                    actions={{
                        global: [
                            { icon: 'refresh', position: 'toolbar', onClick: refetch, disabled: loading },
                            {
                                tooltip: 'Export',
                                icon: 'save_alt',
                                position: 'toolbar',
                                onClick: () => {
                                    downloadAsCsv(tableRowsToCsvRows(rows), 'Organizations Export');
                                },
                            },
                        ],
                    }}
                    data={rows}
                />
            </Grid>
        </Grid>
    );
};

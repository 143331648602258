import { GradientDirectionControl } from '../../Controls/GradientDirection';
import { controlsRefAtom, rendererRefAtom, sceneRefAtom, store } from '../../store/store';
import { disableControlsAtom } from '../../store/store';
import { styled } from '@orthly/ui-primitives';
import React from 'react';
import { Scene, PerspectiveCamera, WebGLRenderer, DirectionalLight, AmbientLight } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

const THREERoot: React.FC = () => {
    const mountRef = React.useRef<HTMLDivElement>(null);

    const Root = styled('div')({
        flex: 1,
        height: '100vh',
        overflow: 'hidden',
    });

    React.useEffect(() => {
        const mount = mountRef.current;
        if (!mount) {
            return;
        }

        console.log('init Scene');

        // Scene setup
        const scene = new Scene();
        store.set(sceneRefAtom, scene);
        const camera = new PerspectiveCamera(75, mount.clientWidth / mount.clientHeight, 0.1, 1000);
        const renderer = new WebGLRenderer({ alpha: true, antialias: true });
        store.set(rendererRefAtom, renderer);

        const canvas = renderer.domElement;
        canvas.style.objectFit = 'contain';
        canvas.style.width = '100%';
        canvas.style.height = '100%';
        canvas.style.display = 'block'; // prevents small gap below the canvas
        mount.appendChild(canvas);

        const light1 = new DirectionalLight(0xffffff, 2);
        light1.position.set(0, 200, 0);
        scene.add(light1);

        const light2 = new DirectionalLight(0xffffff, 2);
        light2.position.set(100, 200, 100);
        scene.add(light2);

        const light3 = new DirectionalLight(0xffffff, 2);
        light3.position.set(-100, -200, -100);
        scene.add(light3);

        const gradientDirectionControl = new GradientDirectionControl(scene, camera, renderer);

        // scene.rotation.x = - Math.PI / 2;
        // scene.add( new AxesHelper( 100 ))

        const ambientLight = new AmbientLight(0xffffff, 1); // Uniform lighting from all directions
        scene.add(ambientLight);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.update();
        store.set(controlsRefAtom, controls);

        // Subscribe to disableControlsAtom
        const unsubscribe = store.sub(disableControlsAtom, () => {
            const isDisabled = store.get(disableControlsAtom);
            controls.enabled = !isDisabled;
        });

        camera.position.z = 5;

        // Animation loop
        function animate() {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        }
        animate();

        // Handle resize with ResizeObserver
        const resizeObserver = new ResizeObserver(() => {
            camera.aspect = mount.clientWidth / mount.clientHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(mount.clientWidth, mount.clientHeight, false);
            renderer.render(scene, camera);
        });
        resizeObserver.observe(mount);

        // Cleanup on unmount
        return () => {
            unsubscribe(); // Clean up the subscription
            resizeObserver.disconnect();
            gradientDirectionControl.dispose();
            mount.removeChild(renderer.domElement);
        };
    }, []);

    return <Root ref={mountRef} />;
};

export default THREERoot;

import { useFirebaseStorage } from '../../context';
import { getFirebaseDownloadUrl } from '../../hooks';
import { canModify } from './Authorization';
import type { SmileLibraryCacheEntry, SmileLibraryToothTemplateCacheEntry } from './SmileLibraryCatalogCacheManager';
import { SmileLibraryCacheEntrySchema, SmileLibraryCatalogCacheManager } from './SmileLibraryCatalogCacheManager';
import { SmileLibraryCreationForm } from './SmileLibraryCreationForm';
import { CadSmileLibraryList_Fragment, SmileLibraryList } from './SmileLibraryList.graphql';
import { useQuery } from '@apollo/client';
import type {
    CadSmileLibraryList_FragmentFragment,
    CadSmileLibraryList_QueryQuery,
} from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { useSession } from '@orthly/session-client';
import { LoadBlocker, StackY } from '@orthly/ui';
import { Box, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

export const CadSmileLibraryList_Query = graphql(`
    query CadSmileLibraryList_Query {
        getAllCadSmileLibraries {
            ...CadSmileLibraryList_Fragment
        }
    }
`);

export async function updateSmileLibraryCache(
    currentCatalog: SmileLibraryCacheEntry[],
    getTemplateDataCallback: (template: SmileLibraryToothTemplateCacheEntry) => Promise<Blob>,
) {
    await SmileLibraryCatalogCacheManager.create(currentCatalog, getTemplateDataCallback);
}

export const SmileLibraryManagerLandingPage: React.VFC = () => {
    const firebase = useFirebaseStorage();

    const getTemplateDataCallback = React.useCallback(
        async (template: SmileLibraryToothTemplateCacheEntry) => {
            const downloadUrl = await getFirebaseDownloadUrl(firebase, template.dcmPath);
            const response = await fetch(downloadUrl);
            if (!response.ok) {
                throw new Error(
                    `Failed to download design file from ${downloadUrl} ${response.status} ${response.statusText}`,
                );
            }
            const blob = await response.blob();
            return blob;
        },
        [firebase],
    );

    const { data, refetch } = useQuery<CadSmileLibraryList_QueryQuery>(CadSmileLibraryList_Query, {
        variables: {},
    });

    const currentCatalog = React.useMemo(() => {
        if (!data?.getAllCadSmileLibraries) {
            return;
        }
        const result: SmileLibraryCacheEntry[] = [];
        for (const fragment of data?.getAllCadSmileLibraries) {
            const fragmentData = getFragmentData<CadSmileLibraryList_FragmentFragment>(
                CadSmileLibraryList_Fragment,
                fragment,
            );
            const parsedData = SmileLibraryCacheEntrySchema.safeParse(fragmentData);
            if (!parsedData.success) {
                continue;
            }
            result.push(parsedData.data);
        }
        return result;
    }, [data?.getAllCadSmileLibraries]);

    const [updatingCache, setUpdatingCache] = React.useState(false);
    const updateCacheCallback = React.useCallback(async () => {
        if (currentCatalog === undefined) {
            return;
        }
        setUpdatingCache(true);
        await updateSmileLibraryCache(currentCatalog, getTemplateDataCallback);
        setUpdatingCache(false);
    }, [currentCatalog, getTemplateDataCallback]);

    const clearCacheCallback = async () => {
        setUpdatingCache(true);
        // @ts-expect-error
        await (await navigator.storage.getDirectory()).remove();
        setUpdatingCache(false);
    };

    const existingNames = React.useMemo(() => {
        const names =
            data?.getAllCadSmileLibraries?.map(f => {
                const fragmentData = getFragmentData<CadSmileLibraryList_FragmentFragment>(
                    CadSmileLibraryList_Fragment,
                    f,
                );
                return fragmentData.name;
            }) ?? [];
        return new Set(names);
    }, [data?.getAllCadSmileLibraries]);

    const session = useSession();
    const enableModification = canModify(session);

    return (
        <StackY sx={{ width: '100%', flex: 'auto' }}>
            <Box sx={{ padding: '16px' }}>
                <Text sx={{ marginBottom: '8px' }} variant={'h4'}>
                    Smile Libraries
                </Text>
                <Text variant={'body1'} color={'DARK_GRAY'}>
                    Configuration of smile libraries used in Design Editor
                </Text>
            </Box>
            <SmileLibraryCreationForm
                existingNames={existingNames}
                successCallback={async () => await refetch()}
                enableCreation={enableModification}
            >
                <Button disabled={!currentCatalog || updatingCache} variant={'secondary'} onClick={clearCacheCallback}>
                    Clear Cache
                </Button>
                <Button disabled={!currentCatalog || updatingCache} variant={'secondary'} onClick={updateCacheCallback}>
                    Update Cache
                </Button>
            </SmileLibraryCreationForm>
            <LoadBlocker blocking={updatingCache}>
                <SmileLibraryList
                    data={data?.getAllCadSmileLibraries?.map(f =>
                        getFragmentData<CadSmileLibraryList_FragmentFragment>(CadSmileLibraryList_Fragment, f),
                    )}
                    rowEditCallback={refetch}
                    enableModification={enableModification}
                />
            </LoadBlocker>
        </StackY>
    );
};

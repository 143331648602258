import { PrintTable } from '../../../../components/PrintTable';
import AddSourceImportRows from './AddSourceImportRows';
import {
    useImportBillingCreditsState,
    useRowsWithPartnerName,
    useSubmitCredits,
} from './ImportBillingCreditsHooks.graphql';
import { ImportBillingCreditsRowDisplay } from './ImportBillingCreditsRowDisplay';
import type { ErrorRow } from './types';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useOrdersByIds } from '@orthly/graphql-react';
import { LoadBlocker, SimpleSelect, SimpleTextField } from '@orthly/ui';
import { Button, Collapse, Grid, Text } from '@orthly/ui-primitives';
import { values, sortBy, compact, keyBy, groupBy } from 'lodash';
import React from 'react';

const AdminImportBillingCreditsOrderPrices_Query = graphql(`
    query AdminImportBillingCreditsOrderPrices_Query($order_ids: [String!]!) {
        getOrderPriceEntitiesByOrderIds(order_ids: $order_ids) {
            total_price_cents
            order_id
            item_id
            unit {
                unit_type
                preference_field_id
                material
                unn
                unns
            }
        }
    }
`);

const SubmissionErrorsTable: React.FC<{ submissionErrors: ErrorRow[] }> = ({ submissionErrors }) => {
    const displayRows = useRowsWithPartnerName(submissionErrors);

    return (
        <Collapse in={!!displayRows.length} style={{ width: '100%', paddingTop: 8 }}>
            <Grid container style={{ padding: '8px 0' }}>
                <PrintTable title={'Submission Errors'} rows={displayRows} rootStyle={{ width: '100%' }} />
            </Grid>
        </Collapse>
    );
};

export const ImportBillingCredits: React.FC = () => {
    const {
        inputRows,
        onRemoveRow,
        onEditRow,
        onDropAccepted,
        resetRows,
        onPracticesSelected,
        creditCategories,
        creditCategoriesLoading,
    } = useImportBillingCreditsState();
    const [defaultDescription, setDefaultDescription] = React.useState<string | null>(null);
    const [defaultCategory, setDefaultCategory] = React.useState<string | undefined>(undefined);

    const creditCategoryOptions = React.useMemo(() => {
        return sortBy(
            creditCategories.map(category => ({
                label: category.name,
                value: category.id,
            })) ?? [],
            category => category.value,
        );
    }, [creditCategories]);

    const finalizedRows = React.useMemo(
        () =>
            values(inputRows).map(r => ({
                ...r,
                description: r.description || defaultDescription,
                credit_category: r.credit_category || defaultCategory,
            })),
        [defaultDescription, defaultCategory, inputRows],
    );

    const orderIds = React.useMemo(() => compact(finalizedRows.map(row => row.order_id)), [finalizedRows]);
    const { orders } = useOrdersByIds(orderIds);
    const ordersById = React.useMemo(() => keyBy(compact(orders), p => p.id), [orders]);

    const { data: { getOrderPriceEntitiesByOrderIds: orderPrices = [] } = {} } = useQuery(
        AdminImportBillingCreditsOrderPrices_Query,
        {
            variables: { order_ids: orderIds },
            skip: !orderIds.length,
            fetchPolicy: 'network-only',
        },
    );

    const orderPricesByOrderId = React.useMemo(() => groupBy(orderPrices, p => p.order_id), [orderPrices]);

    const { onSubmit, dataError, anyMissingCategory, submitting, submissionErrors } = useSubmitCredits(
        finalizedRows,
        onRemoveRow,
        ordersById,
        orderPricesByOrderId,
    );

    return (
        <LoadBlocker
            blocking={submitting || creditCategoriesLoading}
            ContainerProps={{ style: { padding: 16 } }}
            loader={'linear'}
        >
            <AddSourceImportRows
                visible={!finalizedRows.length}
                onDropAccepted={onDropAccepted}
                onPracticesSelected={onPracticesSelected}
            />
            <SubmissionErrorsTable submissionErrors={submissionErrors} />
            <Collapse in={!!finalizedRows.length} style={{ width: '100%', paddingTop: 8 }}>
                <Grid container spacing={2}>
                    <Grid container item xs={12} direction={'column'} sx={{ gap: '16px' }}>
                        <SimpleTextField
                            onChange={value => setDefaultDescription(value ?? null)}
                            value={defaultDescription ?? ''}
                            label={'Default Internal Notes'}
                        />
                        <SimpleSelect
                            label={'Default Category'}
                            options={creditCategoryOptions}
                            value={defaultCategory}
                            onChange={value => setDefaultCategory(value)}
                            errorText={anyMissingCategory ? 'Must set category for all rows' : ''}
                        />
                        <Grid container style={{ paddingTop: 8 }} justifyContent={'space-between'}>
                            <Button variant={'alert-secondary'} onClick={() => resetRows()}>
                                Reset
                            </Button>
                            <Button disabled={!!dataError} variant={'primary'} onClick={onSubmit}>
                                Submit
                            </Button>
                        </Grid>
                        <Grid container style={{ paddingTop: 2 }} justifyContent={'flex-end'}>
                            <Text variant={'body2'} color={'ATTENTION'}>
                                {dataError ? `Error: ${dataError}` : ''}&nbsp;
                            </Text>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <ImportBillingCreditsRowDisplay
                            onRemoveRow={onRemoveRow}
                            onEditRow={onEditRow}
                            inputRows={finalizedRows}
                            creditCategoryOptions={creditCategoryOptions}
                            ordersById={ordersById}
                            orderPricesByOrderId={orderPricesByOrderId}
                        />
                    </Grid>
                </Grid>
            </Collapse>
        </LoadBlocker>
    );
};

export default ImportBillingCredits;

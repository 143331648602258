import { useUpdatePreference } from '../useUpdatePreference';
import { SimpleInput, SimplePhoneInput } from '@orthly/ui';
import { Button, CheckboxPrimitive as Checkbox, Box } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface PreferredContactInfoProps {
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userMobilePhone: string;
    drPrefEmail: string;
    drPrefMobilePhone: string;
    drPrefName: string;
    drPrefId: string;
}

export const PreferredContactInfo: React.FC<PreferredContactInfoProps> = ({
    userFirstName,
    userLastName,
    userEmail,
    userMobilePhone,
    drPrefEmail,
    drPrefMobilePhone,
    drPrefName,
    drPrefId,
}) => {
    const dictFromProps = {
        preference_set_id: drPrefId,
        contact_email: drPrefEmail,
        contact_phone: drPrefMobilePhone,
        name: drPrefName,
    };
    const [preferredDrPref, setPreferredDrPref] = React.useState(dictFromProps);
    const [checked, setChecked] = React.useState(false);
    const userFullName = `${userFirstName} ${userLastName}`;

    const { submit: submitUpdateDrPref, submitting: submittingUpdateDrPref } = useUpdatePreference();
    const savePref = async () => {
        await submitUpdateDrPref(preferredDrPref).then(result => {
            const drPrefResult = result.data.setDoctorPreferences;
            setPreferredDrPref({
                preference_set_id: drPrefResult.id,
                contact_email: drPrefResult.contact_email,
                contact_phone: drPrefResult.contact_phone,
                name: drPrefResult.name,
            });
        });
    };
    const updateField = (field: { property: string; value: string }) =>
        setPreferredDrPref({ ...preferredDrPref, [field.property]: field.value });
    const isEdited =
        preferredDrPref.contact_email !== drPrefEmail ||
        preferredDrPref.contact_phone !== drPrefMobilePhone ||
        preferredDrPref.name !== drPrefName;

    React.useEffect(() => {
        setChecked(userEmail !== drPrefEmail || userMobilePhone !== drPrefMobilePhone || userFullName !== drPrefName);
    }, [userEmail, userMobilePhone, userFullName, drPrefEmail, drPrefMobilePhone, drPrefName]);

    const toggleCheckbox = () => {
        setChecked(prev => {
            const next = !prev;

            if (next) {
                setPreferredDrPref(dictFromProps);
            } else {
                setPreferredDrPref({
                    preference_set_id: drPrefId,
                    contact_email: userEmail,
                    contact_phone: userMobilePhone,
                    name: userFullName,
                });
            }

            return next;
        });
    };

    return (
        <>
            <Box
                component={checked ? 'div' : 'label'}
                sx={{ display: 'flex', alignItems: 'center', minHeight: '58px', gridColumn: '1 / -1' }}
            >
                <Checkbox checked={checked} onChange={toggleCheckbox} color={'secondary'} />

                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                    {checked ? (
                        <Box
                            sx={{
                                width: '83%',
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                                gridGap: '8px',
                            }}
                        >
                            <SimpleInput
                                label={'Preferred Name'}
                                value={preferredDrPref.name}
                                onChange={value => updateField({ property: 'name', value: value ?? '' })}
                                placeholder={drPrefName}
                            />
                            <SimpleInput
                                label={'Preferred Email'}
                                value={preferredDrPref.contact_email}
                                onChange={value => updateField({ property: 'contact_email', value: value ?? '' })}
                                placeholder={drPrefEmail}
                            />
                            <SimplePhoneInput
                                label={'Preferred Mobile Phone'}
                                value={preferredDrPref.contact_phone}
                                onChange={value => updateField({ property: 'contact_phone', value: value ?? '' })}
                                placeholder={drPrefMobilePhone}
                            />
                        </Box>
                    ) : (
                        'Customize the contact information for this practice'
                    )}
                    <Button
                        startIcon={'Save'}
                        onClick={savePref}
                        variant={'primary'}
                        disabled={!isEdited || _.isEqual(preferredDrPref, dictFromProps) || submittingUpdateDrPref}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </>
    );
};

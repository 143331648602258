import {
    LabsGqlStaffRole,
    LabsGqlStaffRoleExternal,
    LabsGqlStaffRoleInternal,
    LabsGqlStaffRoleLab,
    LabsGqlStaffRoleParent,
    LabsGqlStaffRolePractice,
} from '@orthly/graphql-schema';
import { Divider, FormControl, InputLabel, MenuItem, Select } from '@orthly/ui-primitives';
import type { SelectChangeEvent } from '@orthly/ui-primitives';
import React from 'react';

interface RoleSelectProps {
    setRole: React.Dispatch<React.SetStateAction<string | undefined>>;
    organizationType: string | undefined;
    setOrganizationType: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const setRolesForDropdown = (
    organizationType: string | undefined,
    setRoles: React.Dispatch<React.SetStateAction<string[]>>,
    setSelectedRole: React.Dispatch<React.SetStateAction<string | undefined>>,
) => {
    if (organizationType === 'internal') {
        setRoles(Object.values(LabsGqlStaffRoleInternal));
    } else if (organizationType === 'external') {
        setRoles(Object.values(LabsGqlStaffRoleExternal));
    } else if (organizationType === 'parent') {
        setRoles(Object.values(LabsGqlStaffRoleParent));
    } else if (organizationType === 'practice') {
        setRoles(Object.values(LabsGqlStaffRolePractice));
    } else if (organizationType === 'lab') {
        setRoles(Object.values(LabsGqlStaffRoleLab));
    } else {
        setRoles(Object.values(LabsGqlStaffRole));
        setSelectedRole(undefined);
    }
};

const isInternal = (role: string) => Object.values(LabsGqlStaffRoleInternal).includes(role as LabsGqlStaffRoleInternal);
const isExternal = (role: string) => Object.values(LabsGqlStaffRoleExternal).includes(role as LabsGqlStaffRoleExternal);
const isParent = (role: string) => Object.values(LabsGqlStaffRoleParent).includes(role as LabsGqlStaffRoleParent);
const isPractice = (role: string) => Object.values(LabsGqlStaffRolePractice).includes(role as LabsGqlStaffRolePractice);
const isLab = (role: string) => Object.values(LabsGqlStaffRoleLab).includes(role as LabsGqlStaffRoleLab);

const setOrganizationTypeForRole = (
    role: string,
    setOrganizationType: React.Dispatch<React.SetStateAction<string | undefined>>,
    organizationType: string | undefined,
) => {
    // if admin and org type is set, don't change it b/c admin role exists for all org types
    if (role === 'admin' && organizationType) {
        return;
    }
    if (isInternal(role)) {
        setOrganizationType('internal');
    } else if (isExternal(role)) {
        setOrganizationType('external');
    } else if (isParent(role)) {
        setOrganizationType('parent');
    } else if (isPractice(role)) {
        setOrganizationType('practice');
    } else if (isLab(role)) {
        setOrganizationType('lab');
    } else {
        setOrganizationType(undefined);
    }
};

export const RoleSelect: React.FC<RoleSelectProps> = ({ setRole, organizationType, setOrganizationType }) => {
    const [selectedRole, setSelectedRole] = React.useState<string | undefined>('');
    const [roles, setRoles] = React.useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const role = event.target.value as string;
        setSelectedRole(role);
        setRole(role);
        setOrganizationTypeForRole(role, setOrganizationType, organizationType);
    };

    const formatRoleLabel = (role: string) => {
        return role
            .replace(/__/g, ' ') // Replace double underscores with a space
            .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters
            .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
    };

    React.useEffect(() => {
        setRolesForDropdown(organizationType, setRoles, setSelectedRole);
    }, [organizationType]);

    return (
        <FormControl style={{ minWidth: 200 }}>
            <InputLabel id={'staff-role-label'}>Select Role</InputLabel>
            <Select labelId={'staff-role-label'} value={selectedRole} onChange={handleChange} displayEmpty>
                <MenuItem
                    value={undefined}
                    onClick={() => {
                        setRoles(Object.values(LabsGqlStaffRole));
                        setOrganizationType(undefined);
                    }}
                >
                    All
                </MenuItem>
                <Divider />
                {roles.map(role => (
                    <MenuItem key={role} value={role}>
                        {formatRoleLabel(role)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

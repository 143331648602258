import { showTextureWindowAtom } from '../../store/store';
import THREERoot from '../THREERoot/THREERoot';
import { ChangingIconToggleButton } from '../UIPrimitives/ChangingIconToggleButton/ChangingIconToggleButton';
import { styled } from '@orthly/ui';
import { ArrowEastIcon, ArrowWestIcon } from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import React from 'react';

const Container = styled('div')({
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'stretch',
});

const Div1 = styled('div')({
    flex: 1,
    backgroundColor: 'lightgray',
});

const Div2 = styled('div')<{ isVisible: boolean }>(({ isVisible }) => ({
    flex: isVisible ? 1 : 0,
    backgroundColor: 'darkgray',
    transition: 'flex 0.3s ease',
    overflow: 'hidden',
}));

const ToggleTextureViewIconButton = styled(ChangingIconToggleButton)({
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    zIndex: 9999,
});

export const WindowManager: React.FC = () => {
    const [isTextureWindowOpen, setIsTextureWindowOpen] = useAtom(showTextureWindowAtom);

    return (
        <Container>
            <Div1>
                <THREERoot />
            </Div1>
            <Div2 isVisible={isTextureWindowOpen}>Div 2</Div2>
            <ToggleTextureViewIconButton
                active={isTextureWindowOpen}
                onClick={() => setIsTextureWindowOpen(!isTextureWindowOpen)}
                activeTooltip={'Hide Texture View'}
                inactiveTooltip={'Show Texture View'}
                activeIcon={<ArrowEastIcon />}
                inactiveIcon={<ArrowWestIcon />}
                tooltipPlacement={'left'}
            />
        </Container>
    );
};

import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import { Divider, FormControl, InputLabel, MenuItem, Select } from '@orthly/ui-primitives';
import type { SelectChangeEvent } from '@orthly/ui-primitives';
import React from 'react';

interface OrganizationTypeSelectProps {
    setOrganizationType: React.Dispatch<React.SetStateAction<string | undefined>>;
    organizationType: string | undefined;
}

export const OrganizationTypeSelect: React.FC<OrganizationTypeSelectProps> = ({
    setOrganizationType,
    organizationType,
}) => {
    const [selectedType, setSelectedType] = React.useState<LabsGqlOrganizationType | ''>('');

    const handleChange = (event: SelectChangeEvent<LabsGqlOrganizationType>) => {
        const type = event.target.value as LabsGqlOrganizationType;
        setSelectedType(type);
        setOrganizationType(type);
    };

    React.useEffect(() => {
        setSelectedType(organizationType as LabsGqlOrganizationType);
    }, [organizationType]);

    return (
        <FormControl style={{ minWidth: 120 }}>
            <InputLabel id={'organization-type-label'}>Org Type</InputLabel>
            <Select
                style={{
                    borderRadius: '0 8px 8px 0',
                }}
                labelId={'organization-type-label'}
                value={selectedType}
                onChange={handleChange}
            >
                <MenuItem value={undefined}>All</MenuItem>
                <Divider />
                {Object.values(LabsGqlOrganizationType).map(type => (
                    <MenuItem key={type} value={type}>
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

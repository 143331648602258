import { useUsersAction } from '../../state/Users.actions';
import type { UserRole } from '../../state/Users.types';
import type { IteroInfoEditUserRoles } from '../EditUser.util';
import { NotificationLogsDialog } from '../NotificationLogsDialog';
import { PreferredContactInfo } from '../PreferredContactInfo';
import {
    options,
    orgOptions,
    preferredLabOnboardingOptions,
    roleOptions,
    useIteroFields,
    valueToRole,
} from './EditUserRole.util.graphql';
import { useEditUserRole } from './useEditUserRole';
import type { LabsGqlOrganizationSummaryFragment } from '@orthly/graphql-operations';
import type { StaffRole } from '@orthly/retainer-common';
import {
    SimpleSelect,
    SimpleAutocomplete,
    SimpleMultiSelect,
    TrashIcon,
    LoadBlocker,
    SimpleInput,
    SimpleCheckbox,
} from '@orthly/ui';
import { FlossPalette, IconButton, Icon, Tooltip, styled, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

interface EditUserRoleProps {
    organizations: LabsGqlOrganizationSummaryFragment[];
    idx: number;
    role: UserRole;
    inactive?: boolean;
    iteroInfo?: IteroInfoEditUserRoles;
}

const Layout = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 2fr 2fr',
    gap: '8px',
    width: '100%',
});

const IconWrapper = styled('div')({
    display: 'flex',
    placeItems: 'center',
    gap: '8px',
});
/**
 * This component is used to edit a single user role.
 * You can view the storybook at https://storybook-dev.dandyserv.net/main/admin/index.html?path=/story/edituserrole--edit-user-role
 *
 */
export const EditUserRole: React.FC<EditUserRoleProps> = ({ organizations, role, idx, inactive, iteroInfo }) => {
    const { iteroFields, setIteroFields, submitIteroFields, submittingIteroMutations } = useIteroFields(
        role.member_id,
        role.organization_id,
        iteroInfo,
    );

    const organizationOptions = orgOptions(organizations, role);
    const { submitting, removeRoleFn, saveRoleFn, reactivateRoleFn, isPractice, drPref, user } = useEditUserRole({
        role,
        idx,
        submitIteroFields,
        submittingIteroMutations,
    });

    const updateRole = useUsersAction('UPDATE_ROLE');
    const color = role.activated_at ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.GRAY;
    const activatedAtHelperText = role.activated_at
        ? `Practice profile activated at ${role.activated_at}`
        : 'Practice profile has not be activated.';

    const { value: enableNotificationLogTableOps } = useFeatureFlag('enableNotificationLogTableOps');

    return (
        <LoadBlocker blocking={submitting}>
            <Layout
                style={
                    inactive
                        ? {
                              border: `1px solid ${FlossPalette.ATTENTION_BACKGROUND}`,
                              borderRadius: '4px',
                              background: FlossPalette.ATTENTION_BACKGROUND,
                              padding: 4,
                          }
                        : {
                              border: `1px solid ${FlossPalette.DARK_TAN}`,
                              borderRadius: '4px',
                              background: FlossPalette.TAN,
                              padding: 4,
                          }
                }
            >
                {drPref && (
                    <Text
                        medium
                        variant={'body2'}
                        sx={{ marginLeft: '8px', marginTop: '8px', gridColumn: '1 / -1', alignContent: 'center' }}
                    >
                        Dr preference ID: {drPref.id}
                    </Text>
                )}
                <SimpleSelect
                    label={'Type'}
                    value={role.type}
                    disabled={role.deactivated || !role.editing}
                    options={options}
                    onChange={value =>
                        updateRole({
                            idx,
                            property: 'type',
                            value: valueToRole(value as string) ?? undefined,
                        })
                    }
                />
                <SimpleMultiSelect
                    disabled={role.deactivated || !role.editing || !role.type}
                    label={'Roles'}
                    value={role.roles}
                    options={roleOptions(role)}
                    onChange={value => updateRole({ idx, property: 'roles', value: (value as StaffRole[]) ?? [] })}
                />
                <SimpleAutocomplete
                    // forces a rerender when orgs size changes (aka loading completes)
                    // because the value of the autocomplete can only be set on initial render
                    key={`${organizationOptions.length}`}
                    disabled={role.deactivated || !role.editing || !role.type}
                    label={_.startCase(role.type ?? 'Organization')}
                    options={organizationOptions}
                    variant={'standard'}
                    onChange={value => {
                        updateRole({ idx, property: 'organization_id', value: value ?? undefined });
                    }}
                    AutocompleteProps={{
                        style: { width: '100%' },
                        value: role.organization_id
                            ? organizationOptions.find(opt => opt.value === role.organization_id)
                            : null,
                    }}
                />
                <IconWrapper>
                    {isPractice && (
                        <Tooltip title={activatedAtHelperText}>
                            <Icon
                                icon={'CheckIcon'}
                                style={{
                                    color: color,
                                    marginLeft: 10,
                                }}
                            />
                        </Tooltip>
                    )}
                    {drPref && enableNotificationLogTableOps && (
                        <NotificationLogsDialog doctorPreferencesId={drPref.id} doctorPreferencesName={drPref.name} />
                    )}
                    {role.deactivated ? (
                        <IconButton
                            onClick={async () => {
                                await reactivateRoleFn();
                            }}
                        >
                            <Icon icon={'Restore'} />
                        </IconButton>
                    ) : (
                        <>
                            <IconButton
                                onClick={async () => {
                                    await saveRoleFn();
                                }}
                            >
                                <Icon icon={role.editing ? 'Save' : 'PencilIcon'} />
                            </IconButton>
                            <IconButton
                                onClick={async () => {
                                    await removeRoleFn();
                                }}
                            >
                                <TrashIcon style={{ color: FlossPalette.ATTENTION }} />
                            </IconButton>
                        </>
                    )}
                </IconWrapper>

                {/* Itero doctor ID */}
                {iteroFields.iteroDoctorId ? (
                    <>
                        <SimpleInput
                            key={iteroFields.iteroDoctorId}
                            label={'Itero Doctor ID'}
                            value={iteroFields.iteroDoctorId}
                            fullWidth
                            disabled={!role.editing}
                            onChange={value => setIteroFields('iteroDoctorId', value || '')}
                        />
                        <SimpleSelect
                            key={iteroFields.setDandyAsPreferredLabStatus}
                            label={'Set Dandy As Preferred Itero Lab'}
                            value={iteroFields.setDandyAsPreferredLabStatus}
                            options={preferredLabOnboardingOptions}
                            disabled={!role.editing}
                            onChange={value => value && setIteroFields('setDandyAsPreferredLabStatus', value)}
                        />
                        <SimpleCheckbox
                            label={'Wants Preference Review'}
                            checked={iteroFields.wantsPreferenceReview}
                            CheckboxProps={{
                                disabled: !role.editing,
                            }}
                            setChecked={() =>
                                setIteroFields('wantsPreferenceReview', !iteroFields.wantsPreferenceReview)
                            }
                        />

                        <SimpleCheckbox
                            label={'Automatically Place Orders On Hold'}
                            checked={iteroFields.shouldImmediatelyPlaceOrder}
                            CheckboxProps={{
                                disabled: !role.editing,
                            }}
                            setChecked={() =>
                                setIteroFields('shouldImmediatelyPlaceOrder', !iteroFields.shouldImmediatelyPlaceOrder)
                            }
                        />
                    </>
                ) : undefined}
                {drPref && user && isPractice && !role.deactivated && (
                    <PreferredContactInfo
                        userFirstName={user.first_name}
                        userLastName={user.last_name}
                        userEmail={user.email}
                        userMobilePhone={user.mobile_phone_number ?? ''}
                        drPrefEmail={drPref.contact_email ?? ''}
                        drPrefMobilePhone={drPref.contact_phone ?? ''}
                        drPrefName={drPref.name}
                        drPrefId={drPref.id}
                    />
                )}
            </Layout>
        </LoadBlocker>
    );
};

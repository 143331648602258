import { OrganizationSelect } from './OrganizationSelect';
import { OrganizationTypeSelect } from './OrganizationTypeSelect';
import { Box } from '@orthly/ui-primitives';
import React from 'react';

interface OrganizationWithTypeSelectProps {
    setOrganizationId: React.Dispatch<React.SetStateAction<string | undefined>>;
    setOrganizationType: React.Dispatch<React.SetStateAction<string | undefined>>;
    organizationType: string | undefined;
}

export const OrganizationWithTypeSelect: React.FC<OrganizationWithTypeSelectProps> = ({
    setOrganizationId,
    setOrganizationType,
    organizationType,
}) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <OrganizationSelect
                queryFilters={{
                    // @ts-ignore
                    type: organizationType ? [organizationType] : undefined,
                }}
                setOrganizationId={setOrganizationId}
                setOrganizationType={setOrganizationType}
            />
            <OrganizationTypeSelect setOrganizationType={setOrganizationType} organizationType={organizationType} />
        </Box>
    );
};

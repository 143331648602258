import { UserToolbar } from './UserToolbar';
import { useUsersAction } from './state/Users.actions';
import { useUsersSelector } from './state/Users.context';
import type { UserScreen } from './state/Users.types';
import type { LabsGqlUserDtoFragment } from '@orthly/graphql-operations';
import { useDesignStaffStatusBulkQuery, useListRetainerUsersQuery } from '@orthly/graphql-react';
import type { LabsGqlDesignStaffStatus } from '@orthly/graphql-schema';
import { LabsGqlOrganizationType, LabsGqlStaffRole } from '@orthly/graphql-schema';
import MUITable from '@orthly/mui-table';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import { LoadBlocker } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import { DesignerStatusIndicator } from '@orthly/veneer';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const ScreenToFilter: {
    [K in UserScreen]: { organization_type?: LabsGqlOrganizationType; role?: LabsGqlStaffRole; deactivated?: boolean };
} = {
    all: {},
    internal__all: { organization_type: LabsGqlOrganizationType.Internal },
    internal__admin: { organization_type: LabsGqlOrganizationType.Internal, role: LabsGqlStaffRole.Admin },
    internal__billingAdmin: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalBillingAdmin,
    },
    internal__revenueOperations: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalRevenueOperations,
    },
    internal__developer: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalDeveloper,
    },
    internal__automationsAdmin: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalAutomationsAdmin,
    },
    internal__configurationsAdmin: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalConfigurationsAdmin,
    },
    internal__labManagementAdmin: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalLabManagementAdmin,
    },
    internal__designManager: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalDesignManager,
    },
    internal__designStaff: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalDesignStaff,
    },
    internal__designTrainer: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalDesignTrainer,
    },
    internal__employee: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalEmployee,
    },
    internal__accountManager: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalAccountManager,
    },
    internal__salesAccountExecutive: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalSalesAccountExecutive,
    },
    internal__customerExperience: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalCustomerExperience,
    },
    internal__orderOperations: {
        organization_type: LabsGqlOrganizationType.Internal,
        role: LabsGqlStaffRole.InternalOrderOperations,
    },
    practice__all: { organization_type: LabsGqlOrganizationType.Practice },
    practice__admin: { organization_type: LabsGqlOrganizationType.Practice, role: LabsGqlStaffRole.Admin },
    practice__doctor: {
        organization_type: LabsGqlOrganizationType.Practice,
        role: LabsGqlStaffRole.PracticeDoctor,
    },
    practice__billing: {
        organization_type: LabsGqlOrganizationType.Practice,
        role: LabsGqlStaffRole.PracticeBilling,
    },
    practice__assistant: {
        organization_type: LabsGqlOrganizationType.Practice,
        role: LabsGqlStaffRole.PracticeAssistant,
    },
    practice__receptionist: {
        organization_type: LabsGqlOrganizationType.Practice,
        role: LabsGqlStaffRole.PracticeReceptionist,
    },
    practice__office_manager: {
        organization_type: LabsGqlOrganizationType.Practice,
        role: LabsGqlStaffRole.PracticeOfficeManager,
    },
    lab__all: { organization_type: LabsGqlOrganizationType.Lab },
    lab__admin: { organization_type: LabsGqlOrganizationType.Lab, role: LabsGqlStaffRole.Admin },
    lab__designer: { organization_type: LabsGqlOrganizationType.Lab, role: LabsGqlStaffRole.LabDesigner },
    lab__qc: { organization_type: LabsGqlOrganizationType.Lab, role: LabsGqlStaffRole.LabQc },
    external__all: { organization_type: LabsGqlOrganizationType.External },
    external__admin: { organization_type: LabsGqlOrganizationType.External, role: LabsGqlStaffRole.Admin },
    external__designer: {
        organization_type: LabsGqlOrganizationType.External,
        role: LabsGqlStaffRole.ExternalDesigner,
    },
    deactivated: {
        deactivated: true,
    },
};

const useDesignerStatusData = (users: LabsGqlUserDtoFragment[]) => {
    const shouldSkip = users.length === 0;
    const { data, ...props } = useDesignStaffStatusBulkQuery({
        variables: { user_ids: users.map(user => user.id) },
        skip: shouldSkip,
    });

    if (!data) {
        return { data: null, ...props };
    }

    const transformedData = data.designStaffStatusBulk.reduce<Record<string, LabsGqlDesignStaffStatus>>(
        (result, entry) => ({
            ...result,
            [entry.id]: entry.status,
        }),
        {},
    );

    return { data: transformedData, ...props };
};

interface UsersListProps {
    users: LabsGqlUserDtoFragment[];
    shouldShowDesignerStatus?: boolean;
}

const UsersList: React.VFC<UsersListProps> = props => {
    const { users, shouldShowDesignerStatus } = props;
    const { enqueueSnackbar } = useSnackbar();
    const editUser = useUsersAction('START_EDITING');
    const { data: designerStatusByUserId, error: designerStatusErr } = useDesignerStatusData(
        shouldShowDesignerStatus ? users : [],
    );
    const history = useHistory();

    React.useCallback(() => {
        designerStatusErr && enqueueSnackbar('Failed to load designer statuses', { variant: 'error' });
    }, [enqueueSnackbar, designerStatusErr]);

    return (
        <MUITable<LabsGqlUserDtoFragment>
            title={''}
            columns={[
                { name: 'Name', render: row => `${row.first_name} ${row.last_name}` },
                {
                    name: 'Login Email',
                    render: 'email',
                },
                {
                    name: 'Contact Phone Number',
                    render: row => row.phone_number && PhoneNumberUtils.prettyPhoneNumber(row.phone_number),
                },
                {
                    name: 'Mobile Login Phone Number',
                    render: row =>
                        row.mobile_phone_number && PhoneNumberUtils.prettyPhoneNumber(row.mobile_phone_number),
                },
                {
                    name: 'Designer Status',
                    hidden: !designerStatusByUserId,
                    field: row => designerStatusByUserId?.[row.id],
                    render: row => {
                        const status = designerStatusByUserId?.[row.id];
                        return status ? <DesignerStatusIndicator status={status} /> : '-';
                    },
                },
            ]}
            eventHooks={{
                onRowClick: user => {
                    editUser({ user });
                    history.push(`/users/${user.id}`);
                },
            }}
            rowOptions={{ selectable: false }}
            displayOptions={{ elevation: 0, filter: false }}
            toolbarOptions={{ hidden: true }}
            data={users}
        />
    );
};

export const UsersListRoot: React.FC = () => {
    const { screen, search, orgId } = useUsersSelector(s => ({
        screen: s.view.screen,
        search: s.view.search,
        orgId: s.view.orgId,
    }));
    const { loading, data, refetch } = useListRetainerUsersQuery({
        variables: { filter: { ...ScreenToFilter[screen], search, orgIds: orgId ? [orgId] : [] } },
    });

    React.useEffect(() => {
        void refetch();
    }, [screen, search, refetch, orgId]);

    const shouldShowDesignerStatus = React.useMemo(() => /design/.test(screen), [screen]);

    const users = React.useMemo<LabsGqlUserDtoFragment[]>(() => data?.listUsers ?? [], [data]);
    return (
        <Grid container style={{ height: '100vh', overflow: 'auto', alignContent: 'flex-start' }}>
            <UserToolbar />
            <LoadBlocker blocking={loading}>
                <UsersList users={users} shouldShowDesignerStatus={shouldShowDesignerStatus} />
            </LoadBlocker>
        </Grid>
    );
};

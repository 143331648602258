import { OrderDetailSidebarListItem } from '../../screens/Orders/components/OrdersListView/OrderDetailSidebarListItem.graphql';
import { useOrdersListViewData } from '../../screens/Orders/components/OrdersListView/useOrdersListViewData.graphql';
import { useOrdersListViewOrderIds } from '../../screens/Orders/components/OrdersListView/useOrdersListViewOrderIds';
import { useOrdersOverviewState } from '../../screens/Orders/state/OrdersOverview.selectors';
import { RouterUtils } from '../../utils/router/RouterUtils';
import { useCurrentOrderDetailId } from '../../utils/router/useOpenOrderDetail';
import { ORDER_DETAIL_LIST_ITEM_HEIGHT } from './OrderDetailSidebarItemLayout';
import { OrderDetailSidebarLayout } from './OrderDetailSidebarLayout';
import { IconButton, ChevronLeft, Text, styled } from '@orthly/ui-primitives';
import { VirtualList } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const TitleText = styled(Text)({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: 8,
    flexBasis: 0,
    flexGrow: 1,
});

const OrderDetailSidebarTitle: React.VFC = () => {
    const screenTitle = useOrdersOverviewState(s => s.view?.title ?? _.startCase(s.screen));
    const navigate = useNavigate();

    return (
        <>
            <IconButton
                role={'button'}
                aria-label={'Return to orders'}
                size={'small'}
                onClick={() => navigate(RouterUtils.pathForScreen('orders'))}
            >
                <ChevronLeft />
            </IconButton>
            <TitleText variant={'h5'}>{screenTitle}</TitleText>
        </>
    );
};

export const OrderDetailSidebar: React.FC = () => {
    const selectedOrderId = useCurrentOrderDetailId();
    const { data: idsData, loading } = useOrdersListViewOrderIds();

    return (
        <OrderDetailSidebarLayout toolbarContent={<OrderDetailSidebarTitle />} position={'left'} loading={loading}>
            <VirtualList
                listItemHeight={ORDER_DETAIL_LIST_ITEM_HEIGHT}
                useItemData={useOrdersListViewData}
                ListItem={OrderDetailSidebarListItem}
                initialFocusedIndex={
                    selectedOrderId ? idsData.findIndex(({ id }) => id === selectedOrderId) : undefined
                }
            />
        </OrderDetailSidebarLayout>
    );
};

import LiveDesignReviewSVG from '../../../../../../../assets/images/LiveDesignReview.svg';
import VideoDesignReviewSVG from '../../../../../../../assets/images/VideoDesignReview.svg';
import { DesignReviewOptions } from '../GuidedWaxupSelectDesignReviewOptionModal';
import { Box, Button, FlossPalette, Icon, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const HeaderLayout = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 32px 0px 32px',
    [theme.breakpoints.down('sm')]: {
        padding: '0px',
        gap: '24px',
    },
}));

const HeaderTitle = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'column',
        gap: '12px',
    },
}));

const Subtext = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        padding: '0px 24px',
    },
}));

const DesignReviewCollaborationImg = styled('img')({
    display: 'block',
    margin: 'auto',
    maxWidth: '100%',
});

const EarlyAccessBadge = styled('div')({
    backgroundColor: FlossPalette.SECONDARY_FOREGROUND,
    borderRadius: 8,
    display: 'flex',
    width: '120px',
    height: '24px',
    padding: '4px 8px',
    alignItems: 'center',
});

const CloseButtonMobile = styled(Button)({
    background: FlossPalette.TAN,
    height: '48px',
    width: '48px',
    minWidth: '48px',
});

const HeaderWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '70px',
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        position: 'relative',
        padding: '16px 24px 16px 24px',
    },
}));

interface GuidedWaxupSelectDesignReviewOptionModalHeaderProps {
    openAbandonmentModal?: () => void;
    isMobile: boolean;
    selectedDesignReviewOption: DesignReviewOptions;
}

export const GuidedWaxupSelectDesignReviewOptionModalHeader: React.VFC<
    GuidedWaxupSelectDesignReviewOptionModalHeaderProps
> = ({ openAbandonmentModal, isMobile, selectedDesignReviewOption }) => {
    const titleVariant = isMobile ? 'h6' : 'h4';
    const shouldShowLdrCollabImg =
        !isMobile && selectedDesignReviewOption !== DesignReviewOptions.CONTINUE_WITHOUT_REVIEW;

    return (
        <HeaderLayout>
            <HeaderWrapper>
                {shouldShowLdrCollabImg && (
                    <DesignReviewCollaborationImg
                        src={
                            selectedDesignReviewOption === DesignReviewOptions.REQUEST_LDR
                                ? LiveDesignReviewSVG
                                : VideoDesignReviewSVG
                        }
                    />
                )}
                <HeaderTitle>
                    <Text variant={titleVariant} medium>
                        {`Connect with a ${isMobile ? 'designer' : 'Design Expert'}`}
                    </Text>
                    <EarlyAccessBadge>
                        <Text variant={'caption'} color={'WHITE'}>
                            Free early access
                        </Text>
                    </EarlyAccessBadge>
                </HeaderTitle>
                {isMobile && (
                    <CloseButtonMobile variant={'secondary'} onClick={openAbandonmentModal}>
                        <Icon icon={'XIcon'} />
                    </CloseButtonMobile>
                )}
            </HeaderWrapper>
            <Subtext>
                <Text variant={'body2'} color={'GRAY'}>
                    We see you have feedback on this design. We'd love to get it just right for you!
                </Text>
            </Subtext>
        </HeaderLayout>
    );
};

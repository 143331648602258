import type { ActiveCamera } from '../../../../../hooks/useCameraSync';
import { VeneerUseOrderRevisionItemsDesign_Fragment } from '../../../OrderDesignActions.graphql';
import type { ComparisonModeEvent } from '../../hooks/useComparisonModeTracking';
import { useComparisonModeTracking } from '../../hooks/useComparisonModeTracking';
import { useGuidedWaxupContext } from '../../state/GuidedWaxupContext';
import { type PresetInfo, useGuidedWaxupAction, useGuidedWaxupSelector } from '../../state/GuidedWaxupState';
import { PreviousStructuredRejectionNotesMobile } from '../StructuredRejectionNotes/PreviousStructuredRejectionNotesMobile';
import { type DesignRevision, formatPresets, type PresetData } from './ModelViewerFooterBannerHelper';
import { AnnotationButton } from './components/AnnotationButton';
import { DesignVersionDropdown } from './components/DesignVersionDropdown';
import { DesignVersionTitle } from './components/DesignVersionTitle';
import { ModelViewerFooterBannerAnnotations } from './components/ModelViewerFooterBannerAnnotations';
import type { FragmentType, OrderDesignPreviewDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import { OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import { getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlDesignOrderDoctorReviewStatus } from '@orthly/graphql-schema';
import { LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import {
    Button,
    FlossPalette,
    styled,
    Text,
    useScreenIsLgOrUp,
    useScreenIsMobileOrVerticalTablet,
} from '@orthly/ui-primitives';
import React from 'react';

const FooterContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
const MainLineContainer = styled('div')<{ selectedDesignRevisionAlreadyReviewed: boolean }>(
    ({ selectedDesignRevisionAlreadyReviewed }) => ({
        backgroundColor: selectedDesignRevisionAlreadyReviewed ? FlossPalette.DARK_TAN : FlossPalette.BLACK,
        borderTop: selectedDesignRevisionAlreadyReviewed ? `1px solid ${FlossPalette.DARK_TAN}` : '',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: '48px',
        gap: '8px',
        padding: '8px 16px',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    }),
);
const MainLineTitle = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
});
const NewBadge = styled('div')({
    backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
    borderRadius: 2,
    width: 'fit-content',
    height: 'fit-content',
    padding: '0 8px',
});

const AnnotationsContainer = styled('div')({
    backgroundColor: FlossPalette.DARK_TAN,
    borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    color: FlossPalette.DARK_GRAY,
    gap: '4px',
});

interface ModelViewFooterBannerProps {
    selectedWaxup?: OrderDesignPreviewDesign_FragmentFragment['doctor_review'] | null;
    selectedPreset: LabsGqlGuidedWaxupPresetType;
    selectedDesignRevisionAlreadyReviewed: boolean;
    isComparisonModeActive: boolean;
    /**
     * Whether this ModelViewFooterBannerProps instance is meant to be the "primary" display or not.
     * The primary display will render the `selectedDesignFragment`,
     * whereas the non-primary display renders the other design fragment.
     */
    isPrimaryDisplay: boolean;
}

export interface ModelViewerFooterBannerInnerProps {
    designFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    selectedPreset: LabsGqlGuidedWaxupPresetType;
    selectedDesignRevisionStatus?: LabsGqlDesignOrderDoctorReviewStatus;
    selectedDesignRevisionCreatedAtDate?: string;
    selectedDesignRevisionAlreadyReviewed: boolean;
    isComparisonModeActive: boolean;
    isLargeScreen: boolean;
    isMobile: boolean;
    setPresetStatus: (payload: {
        presetName: LabsGqlGuidedWaxupPresetType;
        status?: LabsGqlGuidedWaxupPresetStatus | undefined;
    }) => unknown;
    toggleComparisonMode: () => void;
    trackComparisonMode: (event: ComparisonModeEvent) => void;
    designRevisions: readonly DesignRevision[];
    selectedDesignRevisionId?: string;
    formattedPresets?: PresetData[];
    comparisonDesignRevisionId?: string;
    setSelectedDesignRevisionId?: (id?: string | undefined) => void;
    setControlsActive(which: ActiveCamera): void;
    isComparisonSelectorAvailable: boolean;
    hideAnnotationButton?: boolean;
}

export const ModelViewerFooterBannerInner: React.VFC<ModelViewerFooterBannerInnerProps> = ({
    isLargeScreen,
    isMobile,
    selectedPreset,
    designFragment,
    selectedDesignRevisionAlreadyReviewed,
    selectedDesignRevisionStatus,
    selectedDesignRevisionCreatedAtDate,
    formattedPresets,
    isComparisonModeActive,
    designRevisions,
    selectedDesignRevisionId,
    comparisonDesignRevisionId,
    setPresetStatus,
    toggleComparisonMode,
    trackComparisonMode,
    setSelectedDesignRevisionId,
    setControlsActive,
    isComparisonSelectorAvailable,
    hideAnnotationButton,
}) => {
    const designFragmentData = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, designFragment);
    const annotations = designFragmentData?.doctor_review?.annotations || [];

    // "Formatted" presets are the active presets for the new, in-review design.
    // If they exist, we use those. Otherwise, we use the annotations from the design fragment.
    const displayedAnnotations = (formattedPresets ?? annotations).filter(
        annotation =>
            [annotation.preset_type, LabsGqlGuidedWaxupPresetType.GeneralView].includes(selectedPreset) &&
            (annotation.preset_status !== LabsGqlGuidedWaxupPresetStatus.Rejected ||
                annotation.structured_notes?.length ||
                annotation.notes.trim().length),
    );

    const showAnnotation = isComparisonModeActive && !!displayedAnnotations.length && isLargeScreen;
    const [showPreviousNotes, setShowPreviousNotes] = React.useState(false);
    const showAnnotationButton =
        !hideAnnotationButton && isComparisonModeActive && !selectedDesignRevisionAlreadyReviewed;

    return (
        <FooterContainer id={'footer-container'}>
            <MainLineContainer selectedDesignRevisionAlreadyReviewed={selectedDesignRevisionAlreadyReviewed}>
                <MainLineTitle>
                    {!selectedDesignRevisionAlreadyReviewed && (
                        <NewBadge>
                            <Text variant={'caption'} color={'BLACK'}>
                                NEW!
                            </Text>
                        </NewBadge>
                    )}
                    {isComparisonSelectorAvailable ? (
                        <DesignVersionDropdown
                            designRevisions={designRevisions}
                            selectedDesignRevisionId={selectedDesignRevisionId}
                            comparisonDesignRevisionId={comparisonDesignRevisionId}
                            setSelectedDesignRevisionId={id => setSelectedDesignRevisionId?.(id)}
                            trackComparisonMode={trackComparisonMode}
                            setControlsActive={setControlsActive}
                        />
                    ) : (
                        <DesignVersionTitle
                            selectedDesignRevisionAlreadyReviewed={selectedDesignRevisionAlreadyReviewed}
                            selectedDesignRevisionStatus={selectedDesignRevisionStatus}
                            selectedDesignRevisionCreatedAtDate={selectedDesignRevisionCreatedAtDate}
                            color={selectedDesignRevisionAlreadyReviewed ? 'BLACK' : 'WHITE'}
                        />
                    )}
                </MainLineTitle>
                {showAnnotationButton && (
                    <AnnotationButton
                        selectedPreset={selectedPreset}
                        setPresetStatus={setPresetStatus}
                        toggleComparisonMode={toggleComparisonMode}
                        trackComparisonMode={trackComparisonMode}
                    />
                )}
                {isMobile && selectedDesignRevisionAlreadyReviewed && (
                    <Button variant={'ghost'} onClick={() => setShowPreviousNotes(true)}>
                        View previous notes
                    </Button>
                )}
            </MainLineContainer>
            {showAnnotation && (
                <AnnotationsContainer id={'annotations-container'}>
                    {displayedAnnotations?.map(presetData => (
                        <ModelViewerFooterBannerAnnotations key={presetData?.preset_type} presetData={presetData} />
                    ))}
                </AnnotationsContainer>
            )}
            {showPreviousNotes && (
                <PreviousStructuredRejectionNotesMobile
                    designFragment={designFragment}
                    onClose={() => setShowPreviousNotes(false)}
                    onBack={() => setShowPreviousNotes(false)}
                />
            )}
        </FooterContainer>
    );
};

export const ModelViewerFooterBanner: React.VFC<ModelViewFooterBannerProps> = ({
    selectedWaxup,
    selectedPreset,
    selectedDesignRevisionAlreadyReviewed,
    isComparisonModeActive,
    isPrimaryDisplay,
}) => {
    const setPresetStatus = useGuidedWaxupAction('SET_PRESET_STATUS');
    const presets: Partial<Record<LabsGqlGuidedWaxupPresetType, PresetInfo>> = useGuidedWaxupSelector(s => s.presets);
    const formattedPresets: PresetData[] = formatPresets({
        presets,
        selectedPreset,
    });

    const {
        toggleComparisonMode,
        designRevisionFragments,
        selectedDesignRevisionId,
        comparisonDesignRevisionId,
        comparisonDesignFragment,
        setComparisonDesignRevisionId,
        selectedDesignFragment,
        setControlsActive,
        isComparisonSelectorAvailable,
        onlyShowModelViewer,
    } = useGuidedWaxupContext();

    const trackComparisonMode = useComparisonModeTracking();

    const designRevisions: readonly DesignRevision[] = getFragmentData(
        VeneerUseOrderRevisionItemsDesign_Fragment,
        designRevisionFragments,
    );

    const designFragment = isPrimaryDisplay ? selectedDesignFragment : comparisonDesignFragment;

    const isLargeScreen = useScreenIsLgOrUp();
    const isMobile = useScreenIsMobileOrVerticalTablet();

    return (
        <ModelViewerFooterBannerInner
            toggleComparisonMode={toggleComparisonMode}
            designRevisions={designRevisions}
            selectedDesignRevisionId={isPrimaryDisplay ? selectedDesignRevisionId : comparisonDesignRevisionId}
            comparisonDesignRevisionId={isPrimaryDisplay ? comparisonDesignRevisionId : selectedDesignRevisionId}
            setSelectedDesignRevisionId={isPrimaryDisplay ? undefined : setComparisonDesignRevisionId}
            designFragment={designFragment}
            setPresetStatus={setPresetStatus}
            isLargeScreen={isLargeScreen}
            isMobile={isMobile}
            selectedPreset={selectedPreset}
            formattedPresets={isPrimaryDisplay ? formattedPresets : undefined}
            selectedDesignRevisionStatus={selectedWaxup?.status}
            selectedDesignRevisionCreatedAtDate={selectedWaxup?.created_at}
            selectedDesignRevisionAlreadyReviewed={selectedDesignRevisionAlreadyReviewed}
            trackComparisonMode={trackComparisonMode}
            isComparisonModeActive={isComparisonModeActive}
            setControlsActive={setControlsActive}
            isComparisonSelectorAvailable={isComparisonSelectorAvailable}
            hideAnnotationButton={onlyShowModelViewer ?? false}
        />
    );
};

import { teethHexValues } from '@orthly/items';
import { Color } from 'three';

export type ColorControlPoints = number[];
export type GradientFunction = (t: number) => Color;

/**
 * Computes the adjusted t value using Bezier-like curve logic using the handles.
 *
 * @param {number} t - The normalized position in the range 0-1
 * @param {number} start - Start position of the segment.
 * @param {number} handle1 - The position of the first handle.
 * @param {number} handle2 - The position of the second handle.
 * @param {number} end - End position of the segment.
 * @returns {number} Adjusted t
 */
function bezierCurve(
    t: number,
    start: number = 0,
    handle1: number = 25,
    handle2: number = 75,
    end: number = 100,
): number {
    const inverseHandle1 = end - handle1 + start;
    const inverseHandle2 = end - handle2 + start;

    // Using cubic bezier curve calculation
    const adjustedT =
        (1 - t) * (1 - t) * (1 - t) * start +
        3 * (1 - t) * (1 - t) * t * inverseHandle1 +
        3 * (1 - t) * t * t * inverseHandle2 +
        t * t * t * end;

    return (adjustedT - start) / (end - start);
}

const startColor = new Color();
const endColor = new Color();
const lerpedColor = new Color();

// For Testing
// const staticColors: string[] = [
//     "#FF0000", "#FF7F00", "#FFFF00", "#7FFF00", "#00FF00",
//     "#00FF7F", "#00FFFF", "#007FFF", "#0000FF", "#7F00FF"
// ];

const defaultShade = teethHexValues.A1;

/**
 * Returns a function that interpolates between colors based on the control points.
 *
 * @param {string[]} colors - The colors to interpolate between
 * @param {ColorControlPoints} controlPoints - The control points to use for the interpolation
 * @returns {GradientFunction} A function that takes a number t (0-100) and returns a Color
 *                            representing the interpolated color at that position
 */

export function Gradient(colors: string[], controlPoints: ColorControlPoints): GradientFunction {
    return (t: number) => {
        if (colors.length === 0) {
            lerpedColor.set(defaultShade);
        } else if (colors.length === 1) {
            lerpedColor.set(colors[0] ?? defaultShade);
        } else {
            const expectedControlPointCount = 4 + (colors.length - 2) * 3;
            if (controlPoints.length !== expectedControlPointCount) {
                lerpedColor.set(defaultShade);
                return lerpedColor;
            }

            const numSegments = colors.length - 1;
            let currentSegment = 0;
            let normalizedT = 0;
            for (let i = 0; i < numSegments; i++) {
                const start = controlPoints[i === 0 ? 0 : i * 3] ?? 0;
                const end = controlPoints[i * 3 + 3] ?? 1;
                if (start <= t && t <= end) {
                    currentSegment = i;
                    normalizedT = (t - start) / (end - start);
                }
            }

            startColor.set(colors[currentSegment] ?? defaultShade);
            endColor.set(colors[currentSegment + 1] ?? defaultShade);
            const segmentControlPoints = controlPoints.slice(currentSegment * 3, currentSegment * 3 + 4);
            if (segmentControlPoints.length < 4) {
                lerpedColor.set(defaultShade);
                return lerpedColor;
            }

            const [start, handle1, handle2, end] = segmentControlPoints;
            const adjustedT = bezierCurve(normalizedT, start, handle1, handle2, end);
            lerpedColor.lerpColors(startColor, endColor, adjustedT);

            // For Testing
            // const index = Math.min(Math.floor(adjustedT * staticColors.length), staticColors.length - 1);
            // lerpedColor.set(staticColors[index]);
        }
        return lerpedColor;
    };
}

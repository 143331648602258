import { NavigationTitleBoundary } from '../../components/NavigationTitleBoundary';
import { EditUser } from './EditUser/EditUser.graphql';
import { PaginatedUsersTable } from './PaginatedUsersTable';
import { UsersListRoot } from './UsersList';
import { UsersNavigation } from './UsersNavigation';
import { useUsersSelector } from './state/Users.context';
import { Grid } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

export const UsersRoot: React.FC = () => {
    const pageTitle = useUsersSelector(s =>
        s.open && s.user ? `${s.user.first_name} ${s.user.last_name} | User` : 'Users',
    );
    const { value: enablePaginatedUsersTableInOpsPortal = false } = useFeatureFlag(
        'enablePaginatedUsersTableInOpsPortal',
    );

    const UsersTable = enablePaginatedUsersTableInOpsPortal ? PaginatedUsersTable : UsersListRoot;

    return (
        <NavigationTitleBoundary title={pageTitle}>
            {!enablePaginatedUsersTableInOpsPortal && <UsersNavigation />}
            <Grid container style={{ height: '100vh', overflow: 'auto', alignContent: 'flex-start' }}>
                <Switch>
                    <Route exact path={'/users/new'} component={EditUser} />
                    <Route path={'/users/:id'} component={EditUser} />
                    <Route exact path={'/users'} component={UsersTable} />
                </Switch>
            </Grid>
        </NavigationTitleBoundary>
    );
};

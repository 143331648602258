import { selectedToolAtom } from '../../store/store';
import ExportGLBButton from '../ExportGLBButton/ExportGLBButton';
import ToolList from '../ToolList/ToolList';
import UploadSTLButton from '../UploadSTLButton/UploadSTLButton';
import GradientPanel from './GradientTool/GradientPanel';
import ShadePanel from './ShadeTool/ShadePanel';
import { styled } from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import React from 'react';

const ToolPanelRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    height: '100%',
});

const Panels = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    height: '100%',
    flexGrow: 1,
});

const Footer = styled('div')({
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
});

const HR = styled('hr')({
    width: '90%',
});

const ToolPanel: React.FC = () => {
    const [selectedTool] = useAtom(selectedToolAtom);

    return (
        <ToolPanelRoot>
            <ToolList />
            <HR />
            <Panels>
                {selectedTool === 'Gradient' && <GradientPanel />}
                {selectedTool === 'Shade' && <ShadePanel />}
            </Panels>
            <Footer>
                <UploadSTLButton />
                <ExportGLBButton />
            </Footer>
        </ToolPanelRoot>
    );
};

export default ToolPanel;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { ApplyShade } from '../utils/ApplyShade';
import { Gradient } from '../utils/Gradient';
import type { ShadeData } from '../utils/ShadeData';
import { pickShade } from '../utils/ShadeValues';
import type { Getter, Setter } from 'jotai';
import { atom, createStore } from 'jotai';
import type { Mesh, Scene, WebGLRenderer } from 'three';
import { Box3, Vector3 } from 'three';
import type { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export const fullScreenAtom = atom<boolean>(false);
export const drawerOpenAtom = atom<boolean>(true);
export const showTextureWindowAtom = atom<boolean>(false);
export const automaticAtom = atom<boolean>(false);
export const shadeCompleteAtom = atom<boolean>(false);

export const selectedToolAtom = atom<string>('Shade');

export const rendererRefAtom = atom<WebGLRenderer | null>(null);
export const sceneRefAtom = atom<Scene | null>(null);
export const controlsRefAtom = atom<OrbitControls | null>(null);

export const downloadCompleteAtom = atom<boolean>(true);

const updateToothGradient = (get: Getter, set: Setter) => {
    const tooth = get(toothRefAtom);
    const gradientVec = get(gradientVecAtom);
    const shadeData = get(shadeDataAtom);
    const controlPoints = get(controlPointsAtom);
    updateToothRef(tooth, gradientVec, shadeData, controlPoints);
    set(shadeCompleteAtom, true);
};

export const gradientVecAtom = atom<Vector3, Vector3[], void>(new Vector3(0, 1, 0), (get, set, update: Vector3) => {
    set(gradientVecAtom, update);
    updateToothGradient(get, set);
});

export const disableControlsAtom = atom<boolean>(false);

const updateToothRef = (tooth: Mesh | null, gradientVec: Vector3, shadeData: ShadeData, controlPoints: number[]) => {
    if (tooth && shadeData.length > 0) {
        const colors = shadeData.map(shade => shade.color);
        const gradientFunction = Gradient(colors, controlPoints);

        ApplyShade(tooth, gradientVec, gradientFunction);
    }
};

const adjustControlPoints = (controlPoints: number[], expectedCount: number): number[] => {
    const currentCount = controlPoints.length;
    if (currentCount === 0) {
        return Array(expectedCount)
            .fill(0)
            .map((_, i) => (i / (expectedCount - 1)) * 100);
    }
    if (expectedCount < 2) {
        return [];
    }

    const scale = (expectedCount - 1) / (currentCount - 1);
    const newControlPoints = Array(expectedCount)
        .fill(0)
        .map((_, i) => {
            const index = i / scale;
            const lowerIndex = Math.floor(index);
            const upperIndex = Math.ceil(index);
            if (lowerIndex === upperIndex) {
                return controlPoints[lowerIndex];
            }
            const lowerValue = controlPoints[lowerIndex] ?? 0;
            const upperValue = controlPoints[upperIndex] ?? 1;
            return lowerValue + (upperValue - lowerValue) * (index - lowerIndex);
        })
        .filter(point => point !== undefined);

    return newControlPoints;
};

export const shadeDataAtom = atom<ShadeData, [ShadeData], void>(pickShade('A1'), (get, set, update: ShadeData) => {
    set(shadeDataAtom, update);

    const expectedControlPointCount = update.length < 2 ? 0 : 4 + (update.length - 2) * 3;
    let controlPoints = get(controlPointsAtom);

    if (controlPoints.length !== expectedControlPointCount) {
        controlPoints = adjustControlPoints(controlPoints, expectedControlPointCount);
        set(controlPointsAtom, controlPoints);
    }

    updateToothGradient(get, set);
});

export const controlPointsAtom = atom<number[], [number[]], void>([], (get, set, update: number[]) => {
    set(controlPointsAtom, update);
    updateToothGradient(get, set);
});

export const toothRefAtom = atom<Mesh | null, [Mesh | null], void>(null, (get, set, update: Mesh | null) => {
    const scene = get(sceneRefAtom);
    const oldTooth = get(toothRefAtom);
    const controls = get(controlsRefAtom);
    if (scene) {
        if (oldTooth) {
            scene.remove(oldTooth);
        }
        if (update) {
            scene.add(update);
            update.geometry.computeBoundingBox();
            if (controls) {
                const box = new Box3().setFromObject(update);
                const center = box.getCenter(new Vector3());
                controls.target.copy(center);
                controls.update();
            }
        }
    }
    set(toothRefAtom, update);
    updateToothGradient(get, set);
});

export const store: ReturnType<typeof createStore> = createStore();

import { DisableCapacityStationRuleDialog } from '../EditDialogs/DisableCapacityStationRuleDialog';
import { EditRuleDialog } from '../EditDialogs/EditRuleDialog';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlCapacityStationRuleDto } from '@orthly/graphql-schema';
import { LoadBlocker, useRootActionCommand } from '@orthly/ui';
import { EditIcon, FlossPalette, IconButton, ToggleOnIcon, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

const EditRule_Mutation = graphql(`
    mutation EditRule_Mutation($updateCapacityStationRuleArgs: UpdateCapacityStationRuleArgs!) {
        updateCapacityStationRule(updateCapacityStationRuleArgs: $updateCapacityStationRuleArgs) {
            ...CapacityStationRuleDTO
        }
    }
`);

const DisableRule_Mutation = graphql(`
    mutation DisableRule_Mutsation($disableCapacityStationRuleArgs: DisableCapacityStationRuleArgs!) {
        disableCapacityStationRule(disableCapacityStationRuleArgs: $disableCapacityStationRuleArgs) {
            ...CapacityStationRuleDTO
        }
    }
`);

export const EditRuleRowActions: React.VFC<{
    row: LabsGqlCapacityStationRuleDto;
    refetchRules: () => Promise<unknown>;
}> = ({ row, refetchRules }) => {
    const editRuleMutation = useMutation(EditRule_Mutation);
    const {
        submit: editRule,
        submitting: editingRule,
        open: openEditRuleDialog,
        setOpen: setOpenEditRuleDialog,
    } = useRootActionCommand(editRuleMutation, {
        onSuccess: async () => {
            await refetchRules();
        },
        successMessage: 'Capacity Station Rule edited!',
    });

    const disableRuleMutation = useMutation(DisableRule_Mutation);
    const {
        submit: disableRule,
        submitting: disablingRule,
        open: openDisableRuleDialog,
        setOpen: setOpenDisableRuleDialog,
    } = useRootActionCommand(disableRuleMutation, {
        onSuccess: async () => {
            await refetchRules();
        },
        successMessage: 'Capacity Station Rule disabled!',
    });

    return (
        <>
            {!row.disabled && (
                <Tooltip title={'Disable'}>
                    <IconButton
                        onClick={() => setOpenDisableRuleDialog(true)}
                        disabled={row.disabled}
                        style={{ color: FlossPalette.STAR_GRASS }}
                    >
                        <LoadBlocker blocking={disablingRule} loader={'circular'}>
                            <ToggleOnIcon />
                        </LoadBlocker>
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title={'Edit'}>
                <IconButton onClick={() => setOpenEditRuleDialog(true)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <EditRuleDialog
                open={openEditRuleDialog}
                setOpen={setOpenEditRuleDialog}
                submit={editRule}
                submitting={editingRule}
                rule={row}
            />
            <DisableCapacityStationRuleDialog
                open={openDisableRuleDialog}
                setOpen={setOpenDisableRuleDialog}
                submit={disableRule}
                submitting={disablingRule}
                rule={row}
            />
        </>
    );
};

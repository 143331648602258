import ExportGLB from '../components/ExportGLB/ExportGLB';
import ToolPanel from '../components/ToolPanel/ToolPanel';
import { ChangingIconToggleButton } from '../components/UIPrimitives/ChangingIconToggleButton/ChangingIconToggleButton';
import { WindowManager } from '../components/WindowManager/WindowManager';
import { drawerOpenAtom, fullScreenAtom, store } from '../store/store';
import { styled } from '@orthly/ui';
import { ArrowEastIcon, ArrowWestIcon, Drawer, FullscreenExitIcon, FullscreenIcon } from '@orthly/ui-primitives';
import { Provider, useAtom } from 'jotai';
import React from 'react';

export interface PainterRootProps {
    designId?: (item: string) => void;
    designFiles?: string[];
    autorun?: boolean;
}

const Layout = styled('div')<{ isFullScreen: boolean }>(({ isFullScreen }) => ({
    display: 'flex',
    width: '100%',
    position: isFullScreen ? 'fixed' : 'relative',
    top: isFullScreen ? 0 : 'auto',
    bottom: isFullScreen ? 0 : 'auto',
    left: isFullScreen ? 0 : 'auto',
    right: isFullScreen ? 0 : 'auto',
    zIndex: isFullScreen ? 9998 : 'auto',
}));

const ToggleFullScreenIconButton = styled(ChangingIconToggleButton)({
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 9999,
});

const ToggleDrawerIconButton = styled(ChangingIconToggleButton)({
    position: 'absolute',
    top: '10px',
    left: '10px',
    zIndex: 9999,
});

const drawerWidth = 320;

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `${drawerWidth}px`,
    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
    position: 'relative',
}));

export const PainterRoot: React.FC<PainterRootProps> = ({ autorun }) => {
    const [isFullScreen, setIsFullScreen] = useAtom(fullScreenAtom);
    const [isDrawerOpen, setIsDrawerOpen] = useAtom(drawerOpenAtom);

    return (
        <Provider store={store}>
            <Layout isFullScreen={isFullScreen}>
                <Drawer
                    variant={'persistent'}
                    open={isDrawerOpen}
                    sx={{
                        zIndex: 100,
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            position: 'absolute',
                        },
                        '& .MuiDrawer-root': {
                            position: 'absolute',
                        },
                    }}
                >
                    <ToolPanel />
                </Drawer>
                <Main open={isDrawerOpen}>
                    <ToggleDrawerIconButton
                        active={isDrawerOpen}
                        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                        activeTooltip={'Hide Drawer'}
                        inactiveTooltip={'Show Drawer'}
                        activeIcon={<ArrowWestIcon />}
                        inactiveIcon={<ArrowEastIcon />}
                        tooltipPlacement={'right'}
                        enterDelay={500}
                    />
                    <WindowManager />
                </Main>
                <ToggleFullScreenIconButton
                    active={isFullScreen}
                    onClick={() => setIsFullScreen(!isFullScreen)}
                    activeTooltip={'Exit Full Screen'}
                    inactiveTooltip={'Enter Full Screen'}
                    activeIcon={<FullscreenExitIcon />}
                    inactiveIcon={<FullscreenIcon />}
                    tooltipPlacement={'left'}
                />
            </Layout>
            <ExportGLB autorun={!!autorun} />
        </Provider>
    );
};

import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

export const GetUserFromDrPrefId_Query = graphql(`
    query GetUserFromDrPrefId($preferences_id: String!) {
        getUserFromDrPrefId(preferences_id: $preferences_id) {
            id
            email
            first_name
            last_name
            mobile_phone_number
            is_mobile_verified
            is_account_activated
        }
    }
`);

export const useGetUserFromDrPrefId = (pref_id: string | undefined) => {
    const { data, loading, error, refetch } = useQuery(GetUserFromDrPrefId_Query, {
        variables: { preferences_id: pref_id ?? '' },
        skip: !pref_id,
    });

    return { user: data?.getUserFromDrPrefId, loading, error: error?.message, refetch };
};

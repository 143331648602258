import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const showLdrSurveyAtom = atom(false);
const surveyCountAtom = atomWithStorage('ldrSurveyCount', 0);

const MAX_COUNT = 3;

const showLdrSurveyWithLimitAtom = atom(
    get => {
        const showLdrSurvey = get(showLdrSurveyAtom);
        const surveyCount = get(surveyCountAtom);

        return showLdrSurvey && surveyCount <= MAX_COUNT;
    },
    (get, set, newValue: boolean) => {
        set(showLdrSurveyAtom, newValue);

        const surveyCount = get(surveyCountAtom);
        if (newValue && surveyCount <= MAX_COUNT) {
            set(surveyCountAtom, surveyCount + 1);
        }
    },
);

export const useShowLdrSurvey = () => {
    const { value: enableLdrSurvey = false } = useFeatureFlag('enableLdrSurvey');
    const [showLdrSurvey, setShowLdrSurvey] = useAtom(showLdrSurveyWithLimitAtom);

    if (enableLdrSurvey) {
        return [showLdrSurvey, setShowLdrSurvey] as const;
    }

    return [false, (_value: boolean) => {}] as const;
};

import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { dayjsExt as dayjs } from '@orthly/runtime-utils';

const LDR_MEETING_LENGTH_MINUTES = 30;

// use module augmentation to expose plugin types
declare module 'dayjs' {
    interface Dayjs {
        isToday(): boolean;
        isTomorrow(): boolean;
    }
}
const CalendlyUtilsGetNextAvailableLdrTime_Query = graphql(`
    query CalendlyGetNextAvailableLdrTime {
        calendlyGetNextAvailableLdrTime {
            start_time
        }
    }
`);

/**
 * This query returns the next available LDR meeting time within the next 7 days
 */
export const useCalendlyGetNextAvailableLdrTime = () => {
    const { data } = useQuery(CalendlyUtilsGetNextAvailableLdrTime_Query, {
        nextFetchPolicy: 'no-cache',
        fetchPolicy: 'no-cache',
    });

    return data?.calendlyGetNextAvailableLdrTime;
};

export const useGetFormattedNextAvailableLdrTime = () => {
    const nextAvailableLdrTime = useCalendlyGetNextAvailableLdrTime()?.start_time;

    if (nextAvailableLdrTime) {
        const date = dayjs(nextAvailableLdrTime);
        if (!date.isValid()) {
            return '';
        }

        const startTime = date.format('h:mm A');
        const endTime = date.add(LDR_MEETING_LENGTH_MINUTES, 'minutes').format('hh:mm A');
        let dayOfWeek = '';

        if (date.isToday()) {
            dayOfWeek = 'Today';
        } else if (date.isTomorrow()) {
            dayOfWeek = 'Tomorrow';
        } else {
            dayOfWeek = date.format('dddd');
        }
        return `Available as soon as ${dayOfWeek}, ${startTime} - ${endTime}!`;
    }
    return '';
};

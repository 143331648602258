import { useSalesforceUserSyncMutation } from '@orthly/graphql-react';
import { SimpleInput } from '@orthly/ui';
import { Grid, Text, Button } from '@orthly/ui-primitives';
import React from 'react';

interface SalesforceSyncProps {
    userId: string;
    salesforceContactId: string;
}

export const SalesforceSync: React.FC<SalesforceSyncProps> = props => {
    const { userId, salesforceContactId } = props;
    const [salesforceSyncClick] = useSalesforceUserSyncMutation();
    return (
        <Grid container direction={'column'} sx={{ marginBottom: '8px' }}>
            <Grid item sx={{ marginTop: '12px', marginBottom: '12px' }}>
                <Text variant={'h6'}>Salesforce Sync</Text>
            </Grid>
            <Grid container spacing={3} item direction={'row'} sx={{ margin: '8px 0px' }}>
                <Grid item container spacing={3} direction={'column'} xs={6} sx={{ paddingTop: 0, height: '100%' }}>
                    <SimpleInput
                        fullWidth
                        label={'Salesforce Contact ID'}
                        value={salesforceContactId}
                        disabled={true}
                        onChange={() => {}}
                    />
                </Grid>
                <Grid item sx={{ alignContent: 'center' }}>
                    <Button
                        onClick={() => {
                            void salesforceSyncClick({ variables: { id: userId } });
                        }}
                        variant={'primary'}
                    >
                        Force Sync
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

import { useGetPaginatedOrganizationsLazy } from '../../graphql/useGetPaginatedOrganizations.graphql';
import type { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import { Autocomplete, CircularProgress, TextField } from '@orthly/ui-primitives';
import { debounce } from 'lodash';
import React from 'react';

interface OrganizationSelectProps {
    setOrganizationId: React.Dispatch<React.SetStateAction<string | undefined>>;
    setOrganizationType?: React.Dispatch<React.SetStateAction<string | undefined>>;
    queryFilters?: {
        type?: LabsGqlOrganizationType[];
    };
}

export const OrganizationSelect: React.FC<OrganizationSelectProps> = ({
    setOrganizationId,
    setOrganizationType,
    queryFilters,
}) => {
    const [search, setSearch] = React.useState('');
    const [options, setOptions] = React.useState<{ id: string; name: string; type: string }[]>([]);
    const [hasMore, setHasMore] = React.useState(true);
    const [selectedOption, setSelectedOption] = React.useState<{ id: string; name: string; type: string } | null>(null);

    const currentPageRef = React.useRef(0);

    const { getOrganizations, data, loading } = useGetPaginatedOrganizationsLazy();

    React.useEffect(() => {
        // when we fetch data we need to load up the dropdown
        if (data?.organizations) {
            const { organizations, total: totalCount } = data;
            setOptions(prev => (currentPageRef.current === 0 ? organizations : [...prev, ...organizations]));

            const totalLoaded = currentPageRef.current * 7 + organizations.length;
            setHasMore(totalLoaded < totalCount);
        }
    }, [data]);

    React.useEffect(() => {
        const fetchOrganizations = async () => {
            // when the user clears the org type filter, we need to clear the selected org if it does not match the org type
            if (!queryFilters?.type && selectedOption?.type !== queryFilters?.type) {
                setSelectedOption(null);
                setOrganizationId(undefined);
                setOrganizationType && setOrganizationType(undefined); // Clear type

                await getOrganizations(0, 7, { name: '', ...queryFilters });
            }
        };

        fetchOrganizations().catch(error => {
            console.error('Error fetching organizations:', error);
        });
    }, [getOrganizations, queryFilters, search, selectedOption?.type, setOrganizationId, setOrganizationType]);

    React.useEffect(() => {
        const fetchOrganizations = async () => {
            // watch for changes in the org type filter
            if (selectedOption?.type && queryFilters?.type && selectedOption?.type !== queryFilters?.type[0]) {
                setSelectedOption(null);
                setOrganizationId(undefined);
                setOrganizationType && setOrganizationType(undefined); // Clear type

                await getOrganizations(0, 7, { name: '', ...queryFilters });
            }
        };

        fetchOrganizations().catch(error => {
            console.error('Error fetching organizations:', error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryFilters?.type]);

    // when a user types in an org name
    const handleSearch = debounce(async (value: string) => {
        setSearch(value);
        currentPageRef.current = 0;
        setHasMore(true);
        await getOrganizations(0, 7, { name: value, ...queryFilters });
    }, 300);

    // when you are scrolling in the dropdown
    const handleScroll = async (event: React.UIEvent<HTMLUListElement>) => {
        const listboxNode = event.target as HTMLUListElement;
        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight && hasMore && !loading) {
            currentPageRef.current += 1;
            await getOrganizations(currentPageRef.current, 7, { name: search, ...queryFilters });
        }
    };

    const handleChange = (_event: React.ChangeEvent<{}>, value: { id: string; name: string; type: string } | null) => {
        if (value) {
            setOrganizationId(value.id);
            setOrganizationType && setOrganizationType(value.type);
            setSelectedOption(value);
        } else {
            setOrganizationId(undefined);
            setOrganizationType && setOrganizationType(undefined);
            setSelectedOption(null);
        }
    };

    // when the dropdown is opened, or the arrow is clicked
    const handleOpen = async () => {
        currentPageRef.current = 0;
        await getOrganizations(0, 7, { name: search, ...queryFilters });
    };

    return (
        <Autocomplete
            sx={{ width: 300 }}
            options={options}
            getOptionLabel={option => option.name}
            value={selectedOption}
            onInputChange={(_event, value) => handleSearch(value)}
            onChange={handleChange}
            ListboxProps={{
                onScroll: handleScroll,
                style: {
                    maxHeight: '200px',
                    overflowY: 'auto',
                },
            }}
            loading={loading}
            onOpen={handleOpen}
            renderInput={params => (
                <TextField
                    {...params}
                    label={'Organizations'}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px 0 0 8px',
                        },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

import type { EditCapacityDialogProps } from '../CapacityStationsRoot.graphql';
import { EditCapacityStationRuleForm } from '../Forms/EditCapacityStationRuleForm.graphql';
import type { LabsGqlCapacityStationRuleDto } from '@orthly/graphql-schema';
import { RootActionDialog } from '@orthly/ui';
import React from 'react';

type EditRuleProps = EditCapacityDialogProps & {
    rule: LabsGqlCapacityStationRuleDto;
};

export const EditRuleDialog: React.VFC<EditRuleProps> = (props: EditRuleProps) => {
    const { submitting, open, setOpen, submit, rule } = props;
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Edit Rule'}
            content={
                <EditCapacityStationRuleForm
                    initialValues={{
                        name: rule.name,
                        lab_product_codes: rule.lab_product_codes,
                        matching_logic_json: rule.matching_logic_json,
                    }}
                    onSubmit={values => {
                        submit({
                            updateCapacityStationRuleArgs: {
                                ...values,
                                id: rule.id,
                                capacity_station_id: rule.capacity_station_id,
                            },
                        }).catch(console.error);
                    }}
                />
            }
            hideButton={true}
        />
    );
};

import { RouterUtils } from '../../../../utils/router/RouterUtils';
import type { AdminCompletedInboxDesignListItemFeedbackDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlWorkflowTaskFragment } from '@orthly/graphql-operations';
import type { LabsGqlGuidedQcResultEntryScoreVerdict } from '@orthly/graphql-schema';
import { Icon, RootActionDialog } from '@orthly/ui';
import { Text, Grid, IconButton, Tooltip } from '@orthly/ui-primitives';
import type { OrderOpsListItemContentProps } from '@orthly/veneer';
import { PreviousDesignRejectionDetails } from '@orthly/veneer';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const AdminCompletedInboxDesignListItemFeedbackDesign_Fragment = graphql(`
    fragment AdminCompletedInboxDesignListItemFeedbackDesign_Fragment on DesignOrderDesignRevisionDTO {
        design_prep_feedback
        triggered_by_task_id

        non_guided_qc_results {
            reasons
            notes
            passed
        }

        guided_qc_results {
            affected_unns
            markup_paths
            verdict
            name
            reasons
        }
    }
`);

interface CompletedInboxDesignListItemFeedbackProps {
    task: LabsGqlWorkflowTaskFragment;
    listItemContent: OrderOpsListItemContentProps;
}

interface CompletedInboxDesignListItemFeedbackWithDesignProps extends CompletedInboxDesignListItemFeedbackProps {
    design?: AdminCompletedInboxDesignListItemFeedbackDesign_FragmentFragment;
}

const DesignGuidedAuditButton: React.VFC<CompletedInboxDesignListItemFeedbackProps> = ({ task }) => {
    const navigate = useNavigate();

    if (task.__typename !== 'InternalDesignWorkflowTask' || !task.task_has_qa_evaluation_results) {
        return null;
    }

    return (
        <Grid item onClick={e => e.stopPropagation()}>
            <Tooltip title={'Guided Audit Results'}>
                <IconButton
                    size={'small'}
                    onClick={e => {
                        const url = `${RouterUtils.pathForScreen('design_qa')}/results/${task.lab_order_id}`;
                        // button 1 is the middle mouse button.
                        const openInNewWindow = e?.metaKey || e?.ctrlKey || e?.button === 1;
                        if (openInNewWindow) {
                            window.open(url);
                        } else {
                            navigate(url);
                        }
                    }}
                >
                    <Icon icon={'DesignIcon'} color={'primary'} />
                </IconButton>
            </Tooltip>
        </Grid>
    );
};

const DesignQcButton: React.VFC<CompletedInboxDesignListItemFeedbackWithDesignProps> = ({ design }) => {
    const [open, setOpen] = React.useState<boolean>(false);

    // This design was never reviewed
    if (!design?.guided_qc_results?.length && !design?.non_guided_qc_results) {
        return null;
    }

    const didPass = design?.non_guided_qc_results?.passed;

    return (
        <Grid item onClick={e => e.stopPropagation()}>
            <RootActionDialog
                open={open}
                setOpen={setOpen}
                loading={false}
                buttonText={''}
                maxWidth={'sm'}
                title={`Design QC Results`}
                showCloseButton
                CustomButton={() => (
                    <Tooltip title={'Design QC Results'}>
                        <IconButton
                            size={'small'}
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            <Icon
                                icon={didPass ? 'ThumbsUpIcon' : 'ThumbsDownIcon'}
                                color={didPass ? 'primary' : 'error'}
                            />
                        </IconButton>
                    </Tooltip>
                )}
                content={
                    <PreviousDesignRejectionDetails
                        rejection_reasons={design.non_guided_qc_results?.reasons ?? undefined}
                        rejection_notes={design.non_guided_qc_results?.notes ?? undefined}
                        guided_qc_results={design.guided_qc_results?.map(result => ({
                            ...result,
                            verdict: result.verdict as unknown as LabsGqlGuidedQcResultEntryScoreVerdict,
                        }))}
                    />
                }
            />
        </Grid>
    );
};

const PrepFeedbackButton: React.VFC<CompletedInboxDesignListItemFeedbackWithDesignProps> = ({ design }) => {
    const [open, setOpen] = React.useState<boolean>(false);

    if (!design?.design_prep_feedback?.length) {
        return null;
    }

    return (
        <Grid item onClick={e => e.stopPropagation()}>
            <RootActionDialog
                open={open}
                setOpen={setOpen}
                loading={false}
                buttonText={''}
                maxWidth={'sm'}
                title={`Prep Stage Feedback`}
                showCloseButton
                CustomButton={() => (
                    <Tooltip title={'Prep Feedback'}>
                        <IconButton
                            size={'small'}
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            <Icon icon={'ToolIcon'} color={'primary'} />
                        </IconButton>
                    </Tooltip>
                )}
                content={
                    <ol>
                        {design?.design_prep_feedback.map((line, idx) => (
                            <li key={idx}>
                                <Text variant={'body2'}>{line}</Text>
                            </li>
                        ))}
                    </ol>
                }
            />
        </Grid>
    );
};

export const CompletedInboxDesignListItemFeedback: React.VFC<CompletedInboxDesignListItemFeedbackProps> = props => {
    const designs = getFragmentData(
        AdminCompletedInboxDesignListItemFeedbackDesign_Fragment,
        props.listItemContent.orderDesignFragments,
    );
    const design = designs?.find(design => design.triggered_by_task_id === props.task.id);

    return (
        <Grid container direction={'row'}>
            <PrepFeedbackButton {...props} design={design} />
            <DesignQcButton {...props} design={design} />
            <DesignGuidedAuditButton {...props} />
        </Grid>
    );
};

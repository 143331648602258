import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import React from 'react';
import type { EventScheduledEvent, EventTypeViewedEvent } from 'react-calendly';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

type CalendlyModalProps = Omit<React.ComponentProps<typeof InlineWidget>, 'rootElement' | 'open'> & {
    /**
     * URL of the calendly calendar, eg: https://calendly.com/d/dzr-hft-66r/aligners-overview
     */
    url: string;
    /**
     * This field is used to send as data in the onEventScheduled and onEventTypeViewed events that are automatically fired.
     * It helps make sure that the events sent to Segment are properly tagged with a specified origin, so we can attribute
     * these events to a specific place in the code. eg: `Product Hubs - Aligners`
     */
    trackingOrigin?: string;
    /**
     * The onEventScheduled and onEventTypeViewed properties are optional. By default the Calendly components will automatically
     * fire events without these properties defined, as long as a `trackingOrigin` field is specified. If you'd like to customize
     * the events fired when a calendly schedule is shown or an appointment is scheduled, you can defined these properties to override
     * the default behavior.
     */
    onEventScheduled?: (e: EventScheduledEvent) => void;
    /**
     * The onEventScheduled and onEventTypeViewed properties are optional. By default the Calendly components will automatically
     * fire events without these properties defined, as long as a `trackingOrigin` field is specified. If you'd like to customize
     * the events fired when a calendly schedule is shown or an appointment is scheduled, you can defined these properties to override
     * the default behavior.
     */
    onEventTypeViewed?: (e: EventTypeViewedEvent) => void;
};

/**
 * The CalendlyModal is a component used to show a Schedule from Calendly. It loads an iframe which loads the interface
 * from Calendly, allowing users to select a time and a date to reserve a slot in a calendar.
 */
export const CalendlyContainer = ({
    url,
    pageSettings,
    utm,
    prefill,
    trackingOrigin,
    onEventScheduled,
    onEventTypeViewed,
}: CalendlyModalProps) => {
    const defaultEventTypeViewed = React.useCallback(() => {
        trackingOrigin &&
            BrowserAnalyticsClientFactory.Instance?.track('Practice - Calendly Modal Opened', {
                origin: trackingOrigin,
            });
    }, [trackingOrigin]);

    const defaultEventScheduled = React.useCallback(() => {
        trackingOrigin &&
            BrowserAnalyticsClientFactory.Instance?.track('Practice - Calendly Scheduled', { origin: trackingOrigin });
    }, [trackingOrigin]);

    useCalendlyEventListener({
        onEventTypeViewed: onEventTypeViewed || defaultEventTypeViewed,
        onEventScheduled: onEventScheduled || defaultEventScheduled,
    });

    return (
        <>
            <InlineWidget
                url={url}
                pageSettings={pageSettings}
                utm={utm}
                prefill={prefill}
                styles={{ height: '100%' }}
            />
        </>
    );
};

import { controlPointsAtom } from '../../store/store';
import GradientBackground from './GradientBackground';
import { Handle, SliderRail, Track } from './SliderComponents';
import { styled } from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import React from 'react';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';

const StyledGradienSlider = styled(Slider)({
    position: 'relative',
    height: '100%',
    marginLeft: '5%',
    touchAction: 'none',
});

const StyledRail = styled(Rail)({
    position: 'absolute',
    width: '10px',
    height: '100%',
    marginTop: '15px',
    borderRadius: '5px',
    backgroundColor: '#ccc',
});
const StyledHandles = styled('div')({
    position: 'absolute',
    zIndex: 2,
    height: '100%',
});
const StyledTracks = styled('div')({
    position: 'absolute',
    width: '10px',
    height: '100%',
});

const GradientSlider: React.FC = () => {
    const [values, setValues] = useAtom(controlPointsAtom);

    function onUpdate(newValues: ReadonlyArray<number>) {
        const newHandles = [values[0]]
            .concat(newValues, [values[values.length - 1]])
            .filter(number => number !== undefined);
        setValues(newHandles);
    }
    const domain = [0, 100];
    const handles = values.filter((_, index) => index !== 0 && index !== values.length - 1);

    return (
        <GradientBackground>
            {values.length > 1 && (
                <StyledGradienSlider vertical mode={2} step={1} domain={domain} values={handles} onUpdate={onUpdate}>
                    <StyledRail aria-label={'Rail'}>
                        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                    </StyledRail>
                    <Handles>
                        {({ handles, getHandleProps }) => (
                            <StyledHandles>
                                {handles.map(handle => (
                                    <Handle
                                        domain={domain}
                                        key={handle.id}
                                        handle={handle}
                                        getHandleProps={getHandleProps}
                                    />
                                ))}
                            </StyledHandles>
                        )}
                    </Handles>

                    <Tracks left={true} right={true}>
                        {({ tracks, getTrackProps }) => (
                            <StyledTracks>
                                {tracks
                                    .filter((_, index) => (index - 1) % 3 !== 0)
                                    .map(({ id, source, target }) => (
                                        <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                                    ))}
                            </StyledTracks>
                        )}
                    </Tracks>
                </StyledGradienSlider>
            )}
        </GradientBackground>
    );
};
export default GradientSlider;

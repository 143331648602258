import { GuidedWaxupSelectDesignReviewOptionModalContent } from './GuidedWaxupSelectDesignReviewOptionModalComponents/GuidedWaxupSelectDesignReviewOptionModalContent';
import { GuidedWaxupSelectDesignReviewOptionModalFooter } from './GuidedWaxupSelectDesignReviewOptionModalComponents/GuidedWaxupSelectDesignReviewOptionModalFooter';
import { GuidedWaxupSelectDesignReviewOptionModalHeader } from './GuidedWaxupSelectDesignReviewOptionModalComponents/GuidedWaxupSelectDesignReviewOptionModalHeader';
import { PracticeFullScreenDialog } from '@orthly/dentin';
import { RootActionDialog } from '@orthly/ui';
import { Box, styled } from '@orthly/ui-primitives';
import React from 'react';

export const enum DesignReviewOptions {
    CONTINUE_WITHOUT_REVIEW,
    REQUEST_LDR,
    REQUEST_VDR,
}

const StyledRootActionDialog = styled(RootActionDialog)({
    '.MuiPaper-root': {
        maxWidth: '796px',
        gap: '16px',
    },
    '.MuiGrid-root': {
        padding: '0px',
    },
});

const DesignReviewOptionsContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    [theme.breakpoints.down('sm')]: {
        gap: '24px',
    },
}));

interface GuidedWaxupSelectDesignReviewOptionModalProps {
    onDesignReviewedAction: () => Promise<void>;
    openAbandonmentModal: () => void;
    openCalendlyModal: () => void;
    openModal: () => void;
    onModalClose: () => void;
    /**
     * While this field could be ascertained within the component, we pass it in
     * to make it easier to test variants in Storybook and other areas.
     */
    isMobile: boolean;
    numRejections: number;
    isRefab: boolean;
}

interface InnerContentProps extends GuidedWaxupSelectDesignReviewOptionModalProps {
    selectedDesignReviewOption: DesignReviewOptions;
    setSelectedDesignReviewOption: (option: DesignReviewOptions) => void;
}

const InnerContent: React.VFC<InnerContentProps> = ({
    onDesignReviewedAction,
    openAbandonmentModal,
    openCalendlyModal,
    isMobile,
    selectedDesignReviewOption,
    setSelectedDesignReviewOption,
}) => {
    return (
        <DesignReviewOptionsContentWrapper>
            <GuidedWaxupSelectDesignReviewOptionModalContent
                selectedDesignReviewOption={selectedDesignReviewOption}
                setSelectedDesignReviewOption={setSelectedDesignReviewOption}
            />
            <GuidedWaxupSelectDesignReviewOptionModalFooter
                onDesignReviewedAction={onDesignReviewedAction}
                openAbandonmentModal={openAbandonmentModal}
                openCalendlyModal={openCalendlyModal}
                selectedDesignReviewOption={selectedDesignReviewOption}
                isMobile={isMobile}
            />
        </DesignReviewOptionsContentWrapper>
    );
};

export const GuidedWaxupSelectDesignReviewOptionModal: React.VFC<
    GuidedWaxupSelectDesignReviewOptionModalProps
> = props => {
    const { isMobile, openAbandonmentModal, openModal, onModalClose, numRejections, isRefab } = props;
    // if there are 3+ ddp rejections or if it's a refab default to LDR option
    const [selectedDesignReviewOption, setSelectedDesignReviewOption] = React.useState(
        numRejections > 2 || isRefab ? DesignReviewOptions.REQUEST_LDR : DesignReviewOptions.CONTINUE_WITHOUT_REVIEW,
    );

    if (isMobile) {
        return (
            <PracticeFullScreenDialog open>
                <GuidedWaxupSelectDesignReviewOptionModalHeader
                    isMobile={isMobile}
                    openAbandonmentModal={openAbandonmentModal}
                    selectedDesignReviewOption={selectedDesignReviewOption}
                />
                <InnerContent
                    {...props}
                    selectedDesignReviewOption={selectedDesignReviewOption}
                    setSelectedDesignReviewOption={setSelectedDesignReviewOption}
                />
            </PracticeFullScreenDialog>
        );
    }

    return (
        <StyledRootActionDialog
            fullHeight={true}
            loading={false}
            open
            showCloseButton
            setOpen={open => {
                // If the user attempts to close, we open the abandonment modal
                if (!open) {
                    openAbandonmentModal();
                } else {
                    // Otherwise, we re-open the modal.
                    openModal();
                }
            }}
            title={
                <GuidedWaxupSelectDesignReviewOptionModalHeader
                    isMobile={isMobile}
                    selectedDesignReviewOption={selectedDesignReviewOption}
                />
            }
            content={
                <InnerContent
                    {...props}
                    selectedDesignReviewOption={selectedDesignReviewOption}
                    setSelectedDesignReviewOption={setSelectedDesignReviewOption}
                />
            }
            CustomButton={() => null}
            onClose={onModalClose}
        />
    );
};

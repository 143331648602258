/* eslint-disable no-nested-ternary */
import { JsonDevTools } from '../../../components/JsonDevTools';
import { RouterUtils } from '../../../utils/router/RouterUtils';
import { ManufacturerTruckTimesEditor } from './ManufacturerTruckTimes/ManufacturerTruckTimesEditor.graphql';
import { EditManufacturerNotificationPrefsAction } from './actions/EditManufacturerNotificationPrefsAction';
import { LabsUtilsBase } from '@orthly/dentin';
import type {
    LabsGqlManufacturerProfileFragment,
    LabsGqlOrganizationDtoFragment,
    LabsGqlUpsertManufacturerProfileMutationVariables,
} from '@orthly/graphql-operations';
import { useGetOrgQuery, useUpsertManufacturerProfileMutation } from '@orthly/graphql-react';
import type { LabsGqlManufacturerFeatureStates } from '@orthly/graphql-schema';
import {
    LabsGqlInternalDesignAbility,
    LabsGqlPortalShipDestinationType,
    LabsGqlPortalShipShippingSpeed,
} from '@orthly/graphql-schema';
import type { SimpleTableRowProps } from '@orthly/ui';
import { LoadBlocker, QuickForm, SimpleTable, useChangeSubmissionFn } from '@orthly/ui';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    EditIcon,
    Grid,
    IconButton,
    Text,
} from '@orthly/ui-primitives';
import { DeliveryAddressForm } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';

interface ManufacturerDetailViewToolbarProps {
    loading: boolean;
    manufacturer?: LabsGqlOrganizationDtoFragment;
}

const ManufacturerDetailViewToolbar: React.FC<ManufacturerDetailViewToolbarProps> = props => {
    const { manufacturer, loading } = props;
    const navigate = useNavigate();
    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const manufacturerPageTitle = loading
        ? 'loading...'
        : !loading && !manufacturer
          ? 'Manufacturer Not Found'
          : manufacturer?.name ?? '(No Name)';
    return (
        <Box display={'flex'} alignItems={'center'} style={{ width: '100%' }}>
            <Box flexShrink={1}>
                {
                    <Button
                        variant={'ghost'}
                        onClick={() => navigate(RouterUtils.pathForScreen('labs'))}
                        startIcon={'ChevronLeft'}
                    >
                        Back
                    </Button>
                }
            </Box>
            <Box>
                <Text variant={'h6'}>{manufacturerPageTitle}</Text>
            </Box>
        </Box>
    );
};

type ProfileUpdate = Pick<
    Partial<LabsGqlUpsertManufacturerProfileMutationVariables['data']>,
    | 'design_capability'
    | 'shipping_address'
    | 'default_portalship_destination'
    | 'default_portalship_speed'
    | 'feature_states'
    | 'deactivated_at'
    | 'is_internal'
    | 'return_shipping_address'
    | 'exclude_from_gold_pricing'
>;

function useLabProfileUpdater(manufacturer: LabsGqlOrganizationDtoFragment) {
    const manufacturer_id = manufacturer.id;
    const [rawSubmit] = useUpsertManufacturerProfileMutation();
    return React.useCallback(
        (update: ProfileUpdate) => {
            const data: LabsGqlUpsertManufacturerProfileMutationVariables['data'] = {
                manufacturer_id,
                ...update,
            };
            return rawSubmit({ variables: { data } });
        },
        [manufacturer_id, rawSubmit],
    );
}

const EditManufacturerProfileProperty: React.FC<{ children: React.ReactNode; onClick: () => void }> = props => {
    return (
        <Grid item>
            {props.children}
            <IconButton onClick={() => props.onClick()}>
                <EditIcon />
            </IconButton>
        </Grid>
    );
};

interface ManufacturerDetailViewOverviewProps {
    refetch: () => Promise<unknown>;
    manufacturer: LabsGqlOrganizationDtoFragment;
}

type ManufacturerDetailField =
    | 'designCapability'
    | 'shippingAddress'
    | 'returnAddress'
    | 'defaultPortalShipDestination'
    | 'defaultPortalShipShippingSpeed'
    | 'portalShip'
    | 'isInternal'
    | 'deactivatedAt'
    | 'excludeFromGoldPricing';

const FieldTitles: Record<ManufacturerDetailField, string> = {
    designCapability: 'Lab Design Capability',
    returnAddress: 'Returns Address',
    shippingAddress: 'Shipping Address',
    defaultPortalShipDestination: 'Default PortalShip Destination',
    defaultPortalShipShippingSpeed: 'Default PortalShip Speed',
    portalShip: 'PortalShip Enablement',
    isInternal: 'Is Internal Lab',
    deactivatedAt: 'Deactivated At',
    excludeFromGoldPricing: 'Exclude from Gold Pricing',
};

interface ManufacturerEditPropertyDialogProps {
    profile: LabsGqlManufacturerProfileFragment | null;
    selectedField: ManufacturerDetailField;
    submit: (update: ProfileUpdate) => Promise<unknown>;
}

const ManufacturerEditPropertyDialog: React.VFC<ManufacturerEditPropertyDialogProps> = props => {
    const { profile, selectedField, submit } = props;
    switch (selectedField) {
        case 'defaultPortalShipDestination':
            return (
                <QuickForm<{ default_portalship_destination: LabsGqlPortalShipDestinationType | null }>
                    fields={{
                        default_portalship_destination: {
                            type: `select`,
                            options: Object.values(LabsGqlPortalShipDestinationType),
                        },
                    }}
                    initialValues={{ default_portalship_destination: profile?.default_portalship_destination }}
                    onSubmit={({ default_portalship_destination }) => submit({ default_portalship_destination })}
                />
            );
        case 'defaultPortalShipShippingSpeed':
            return (
                <QuickForm<{ default_portalship_speed: LabsGqlPortalShipShippingSpeed | 'None' }>
                    fields={{
                        default_portalship_speed: {
                            type: `select`,
                            options: [...Object.values(LabsGqlPortalShipShippingSpeed), 'None'],
                        },
                    }}
                    initialValues={{ default_portalship_speed: profile?.default_portalship_speed ?? 'None' }}
                    onSubmit={({ default_portalship_speed }) =>
                        default_portalship_speed === 'None'
                            ? submit({ default_portalship_speed: null })
                            : submit({ default_portalship_speed })
                    }
                />
            );
        case 'deactivatedAt':
            return (
                <QuickForm<{ active: boolean }>
                    fields={{ active: { type: `boolean`, label: 'Actively Route' } }}
                    initialValues={{ active: !!profile?.deactivated_at ? false : true }}
                    onSubmit={({ active }) => {
                        void submit({ deactivated_at: active ? null : new Date(Date.now()).toISOString() });
                    }}
                />
            );
        case 'isInternal':
            return (
                <QuickForm<{ is_internal: boolean }>
                    fields={{ is_internal: { type: `boolean`, label: 'Internal Lab' } }}
                    initialValues={{ is_internal: !!profile?.is_internal || false }}
                    onSubmit={({ is_internal }) => {
                        void submit({ is_internal });
                    }}
                />
            );
        case 'designCapability':
            return (
                <QuickForm<{ design_capability: LabsGqlInternalDesignAbility }>
                    fields={{
                        design_capability: { type: `select`, options: Object.values(LabsGqlInternalDesignAbility) },
                    }}
                    initialValues={{ design_capability: profile?.design_capability }}
                    onSubmit={({ design_capability }) => submit({ design_capability })}
                />
            );
        case 'portalShip':
            return (
                <QuickForm<{ is_portalship_enabled: boolean }>
                    fields={{ is_portalship_enabled: { type: `boolean`, label: 'PortalShip Enabled?' } }}
                    initialValues={{ is_portalship_enabled: !!profile?.feature_states?.portalShip }}
                    onSubmit={({ is_portalship_enabled }) => {
                        const feature_states: LabsGqlManufacturerFeatureStates = {};
                        feature_states.portalShip = is_portalship_enabled;
                        void submit({ feature_states });
                    }}
                />
            );
        case 'returnAddress':
            return (
                <DeliveryAddressForm
                    existingAddress={profile?.return_shipping_address}
                    onSubmit={return_shipping_address => submit({ return_shipping_address })}
                />
            );
        case 'shippingAddress':
            return (
                <DeliveryAddressForm
                    existingAddress={profile?.shipping_address}
                    onSubmit={shipping_address => submit({ shipping_address })}
                />
            );
        case 'excludeFromGoldPricing':
            return (
                <QuickForm<{ exclude_from_gold_pricing: boolean }>
                    fields={{ exclude_from_gold_pricing: { type: `boolean`, label: 'Exclude from Gold Pricing' } }}
                    initialValues={{ exclude_from_gold_pricing: profile?.exclude_from_gold_pricing }}
                    onSubmit={({ exclude_from_gold_pricing }) => {
                        void submit({ exclude_from_gold_pricing: exclude_from_gold_pricing });
                    }}
                />
            );
    }
};

// eslint-disable-next-line max-lines-per-function
const ManufacturerDetailViewOverview: React.FC<ManufacturerDetailViewOverviewProps> = ({ manufacturer, refetch }) => {
    const [activeFieldEdit, setActiveFieldEdit] = React.useState<ManufacturerDetailField | null>(null);
    const { submit, submitting } = useChangeSubmissionFn(useLabProfileUpdater(manufacturer), {
        closeOnComplete: true,
        successMessage: () => [activeFieldEdit ? `${FieldTitles[activeFieldEdit]} updated!` : '', {}],
        onSuccess: async () => {
            await refetch();
            setActiveFieldEdit(null);
        },
    });

    const labPropertiesRows: SimpleTableRowProps[] = [
        {
            name: 'Is Internal Lab',
            toolTipNote:
                'Being an internal lab means we will attempt to use labtrac integration when orders are accepted by the lab',
            value: (
                <EditManufacturerProfileProperty onClick={() => setActiveFieldEdit('isInternal')}>
                    {manufacturer.lab_profile?.is_internal ? 'True' : 'False'}
                </EditManufacturerProfileProperty>
            ),
        },
        {
            name: 'Active for Routing',
            toolTipNote:
                'Being inactive means this lab will NOT show up in the routing rule manufacturer selectors and manual routing lab selector',
            value: (
                <EditManufacturerProfileProperty onClick={() => setActiveFieldEdit('deactivatedAt')}>
                    {manufacturer.lab_profile?.deactivated_at ? 'Inactive' : 'Active'}
                </EditManufacturerProfileProperty>
            ),
        },
        {
            name: 'Design Capability',
            value: (
                <EditManufacturerProfileProperty onClick={() => setActiveFieldEdit('designCapability')}>
                    {manufacturer.lab_profile?.design_capability || LabsGqlInternalDesignAbility.Either}
                </EditManufacturerProfileProperty>
            ),
        },
        {
            name: 'Shipping Address',
            toolTipNote: 'Address orders will be shipped from',
            value: (
                <EditManufacturerProfileProperty onClick={() => setActiveFieldEdit('shippingAddress')}>
                    {manufacturer.lab_profile?.shipping_address
                        ? LabsUtilsBase.addressToString(manufacturer.lab_profile.shipping_address)
                        : 'Not set'}
                </EditManufacturerProfileProperty>
            ),
        },
        {
            name: 'Returns Address',
            toolTipNote: 'Address customer returns will be shipped to (if different from shipping address)',
            value: (
                <EditManufacturerProfileProperty onClick={() => setActiveFieldEdit('returnAddress')}>
                    {manufacturer.lab_profile?.return_shipping_address
                        ? LabsUtilsBase.addressToString(manufacturer.lab_profile.return_shipping_address)
                        : 'Not set'}
                </EditManufacturerProfileProperty>
            ),
        },
        {
            name: 'PortalShip Enabled',
            value: (
                <EditManufacturerProfileProperty
                    onClick={() => {
                        if (!manufacturer.lab_profile?.shipping_address) {
                            return alert('You must set a shipping address prior to enabling PortalShip.');
                        }
                        setActiveFieldEdit('portalShip');
                    }}
                >
                    {manufacturer.lab_profile?.feature_states?.portalShip ? 'Enabled' : 'Not Enabled'}
                </EditManufacturerProfileProperty>
            ),
        },
        {
            name: 'Default PortalShip Destination',
            value: (
                <EditManufacturerProfileProperty onClick={() => setActiveFieldEdit('defaultPortalShipDestination')}>
                    {manufacturer.lab_profile?.default_portalship_destination || 'Not Set'}
                </EditManufacturerProfileProperty>
            ),
            // TODO: stop hiding once DND-900 is completed
            cellStyle: { display: 'none' },
        },
        {
            name: 'Default PortalShip Speed',
            value: (
                <EditManufacturerProfileProperty onClick={() => setActiveFieldEdit('defaultPortalShipShippingSpeed')}>
                    {manufacturer.lab_profile?.default_portalship_speed
                        ? _.startCase(manufacturer.lab_profile.default_portalship_speed)
                        : 'Not Set'}
                </EditManufacturerProfileProperty>
            ),
        },
        {
            name: 'Exclude From Gold Pricing',
            toolTipNote: 'Exclude metal prices from our automated gold pricing',
            value: (
                <EditManufacturerProfileProperty onClick={() => setActiveFieldEdit('excludeFromGoldPricing')}>
                    {manufacturer.lab_profile?.exclude_from_gold_pricing ? 'True' : 'False'}
                </EditManufacturerProfileProperty>
            ),
        },
    ];

    const notificationPrefRows: SimpleTableRowProps[] = [
        { name: 'Emails', value: manufacturer.lab_notification_preferences?.emails.join(', ') || '(none)' },
        { name: 'Phone Number', value: manufacturer.lab_notification_preferences?.phone_number || '(none)' },
        { name: 'Time Zone', value: manufacturer.lab_notification_preferences?.time_zone || '(none)' },
    ];

    return (
        <Grid container style={{ width: '100%' }} spacing={0}>
            <Grid item xs={6} md={8} style={{ padding: 10 }}>
                <SimpleTable title={'Notification Preferences'} titleInPaper rows={notificationPrefRows} />
                <SimpleTable
                    PaperProps={{ style: { marginTop: '24px' } }}
                    title={'Lab Properties'}
                    titleInPaper
                    rows={labPropertiesRows}
                />

                <hr />

                <ManufacturerTruckTimesEditor
                    manufacturerId={manufacturer.id}
                    timeZone={
                        LabsUtilsBase.notificationTimeZoneAbbreviations[
                            manufacturer.lab_notification_preferences?.time_zone ?? ''
                        ] ?? null
                    }
                />
            </Grid>
            {manufacturer && (
                <Grid item xs={6} md={4} style={{ padding: 10 }}>
                    <EditManufacturerNotificationPrefsAction manufacturer={manufacturer} />
                    <JsonDevTools src={manufacturer} dataType={'Manufacturer'} title={'Manufacturer'} />
                </Grid>
            )}
            <Dialog open={!!activeFieldEdit} onClose={() => setActiveFieldEdit(null)}>
                <DialogTitle style={{ paddingBottom: 0 }}>
                    Edit {activeFieldEdit ? FieldTitles[activeFieldEdit] : ''}
                </DialogTitle>
                <DialogContent>
                    <LoadBlocker blocking={submitting}>
                        {!!activeFieldEdit && (
                            <ManufacturerEditPropertyDialog
                                selectedField={activeFieldEdit}
                                profile={manufacturer.lab_profile}
                                submit={submit}
                            />
                        )}
                    </LoadBlocker>
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

export const ManufacturersDetailView: React.FC = () => {
    const manufacturerId = useRouteMatch<{ manufacturerId: string }>().params?.manufacturerId;
    const { data, refetch, loading } = useGetOrgQuery({
        variables: { id: manufacturerId },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        skip: !manufacturerId,
    });
    const manufacturer = data?.getOrganization;
    return (
        <LoadBlocker loader={'dandy'} blocking={loading}>
            <Box flexDirection={'column'} style={{ height: '100%', width: '100%' }}>
                <ManufacturerDetailViewToolbar manufacturer={manufacturer} loading={loading} />
                {manufacturer && <ManufacturerDetailViewOverview manufacturer={manufacturer} refetch={refetch} />}
            </Box>
        </LoadBlocker>
    );
};

import { PracticeBanner } from '../../../PracticeBanner';
import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import { useSubExperiment } from '../../../Providers/Statsig';
import { isGuidedPreset } from '../GuidedWaxup.util';
import {
    PRESET_SIDEBAR_WIDTH,
    TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_COLLAPSED,
    TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_EXPANDED,
} from '../VisualConstants.util';
import { useIsOrderEligibleForLiveDesignReview } from '../components';
import { GuidedWaxupNavArea } from '../components/GuidedWaxupNavArea';
import { ScrollDownButton } from '../components/ScrollDownButton/ScrollDownButton';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useScrollToContainer } from '../state/GuidedWaxupState';
import { getFragmentData, VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import { LabsGqlDesignOrderDoctorReviewStatus } from '@orthly/graphql-schema';
import { Box, FlossPalette, styled } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const Wrapper = styled(Box)(
    ({
        isSidebarExpanded,
        doesPreviousWaxupHavePresets,
    }: {
        isSidebarExpanded: boolean;
        doesPreviousWaxupHavePresets: boolean;
    }) => ({
        width: `calc(100dvw - ${
            isSidebarExpanded ? TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_EXPANDED : TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_COLLAPSED
        }px + 220px)`,
        // We add left padding to the model viewer container itself
        // so the margin here is just the width of the sidebar. If the
        // previous waxup doesn't have presets, there's no sidebar
        marginLeft: doesPreviousWaxupHavePresets ? PRESET_SIDEBAR_WIDTH : 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    }),
);

const NavWrapper = styled(Box)({
    paddingTop: '16px',
    borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    position: 'relative',
});

const ScrollContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    height: '100%',
});

const ContentBanner: React.VFC = () => {
    const { designRevisionFragments, order } = useGuidedWaxupContext();

    const designRevisions = getFragmentData(
        VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc,
        designRevisionFragments,
    );
    const rejections = _.sumBy(designRevisions, revision =>
        revision.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected ? 1 : 0,
    );

    const isRefabOrRejection = rejections > 0 || !!order?.original_order_id;

    const isEnabled = useIsOrderEligibleForLiveDesignReview(order);
    const { value: enableLiveDesignReviewEnhancements } = useFeatureFlag('enableLiveDesignReviewEnhancements');
    const isInSubExperiment = useSubExperiment('ldr_experiment', 'ldr_design_sub_experiment_feb_2025')?.get(
        'show_variant',
    );

    if (!isEnabled || !enableLiveDesignReviewEnhancements || !isInSubExperiment) {
        return null;
    }

    return (
        <PracticeBanner
            banner={{
                type: isRefabOrRejection ? 'suggest' : 'info',
                title: isRefabOrRejection
                    ? 'Skip the back-and-forth! Schedule a live video call with a Design Expert to review, update, and approve designs in real time—saving you extra revisions.'
                    : 'Have design concerns? Schedule a live video call with a Design Expert to review, update, and approve designs in real time.',
                leftAlign: true,
            }}
        />
    );
};

export const ContentContainer: React.FC<{
    doesPreviousWaxupHavePresets?: boolean;
    submit?: () => Promise<unknown>;
}> = ({ children, doesPreviousWaxupHavePresets = true, submit }) => {
    const {
        isSidebarExpanded,
        selectedDesignRevisionAlreadyReviewed,
        selectedDesignRevisionId,
        selectedTab,
        onlyShowModelViewer,
    } = useGuidedWaxupContext();
    const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);
    const guidedPreset = isGuidedPreset(selectedTab);
    // We only want to hide the nav area in the General view for historic designs
    const hideNavArea = (!guidedPreset && selectedDesignRevisionAlreadyReviewed) || onlyShowModelViewer;

    useScrollToContainer(scrollContainerRef);

    return (
        <Wrapper
            doesPreviousWaxupHavePresets={doesPreviousWaxupHavePresets}
            isSidebarExpanded={isSidebarExpanded}
            key={selectedDesignRevisionId}
            data-testid={'model-viewer-container'}
        >
            <ScrollContainer className={'scrollbar-override'} ref={scrollContainerRef}>
                {children}
            </ScrollContainer>
            <NavWrapper>
                <ScrollDownButton
                    revealedText={
                        selectedDesignRevisionAlreadyReviewed ? 'Scroll to view more' : 'Scroll to approve or reject'
                    }
                    sx={{ right: '32px', top: '-48px' }}
                />
                {hideNavArea || <GuidedWaxupNavArea submit={submit} />}
                {hideNavArea || <ContentBanner />}
            </NavWrapper>
        </Wrapper>
    );
};

import { NarrowButton } from './Components/NarrowButton';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { DateUtils } from '@orthly/shared-types';
import { useDebouncedValue, StackY } from '@orthly/ui';
import { FlossPalette, SearchIcon, styled, TextField, InputAdornment, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const CourseForEvaluation_Query = graphql(`
    query CoursesForEvaluation_Query($query: String!) {
        listDesignTrainingCoursesForEvaluation(query: $query) {
            courseId
            designerName
            courseName
            completeCount
            totalCount
            lastCompletedAt
        }
    }
`);

const SearchField = styled(TextField)({ margin: 24, alignSelf: 'flex-start', width: 320 });

const StyledSearchIcon = styled(SearchIcon)({
    color: FlossPalette.GRAY,
    width: 20,
    height: 20,
    marginRight: 8,
    marginBottom: 4,
});

const TableLayout = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    alignSelf: 'flex-start',
    background: FlossPalette.TAN,
    columnGap: 48,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    width: 800,
    borderRadius: 8,
    marginLeft: 24,
});

const TableRow = styled('div')({
    display: 'grid',
    gridColumn: '1 / span 4',
    gridTemplateColumns: 'subgrid',
    height: 44,
    alignItems: 'center',
    padding: `0px 24px`,
    '&:not(:last-child)': {
        borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
    },
});

/** Renders table of training courses by designer, and a search field to search by designer. */
export const EvaluationBody: React.VFC<{}> = () => {
    const [searchText, setSearchText] = React.useState('');
    const debouncedSearchText = useDebouncedValue(searchText, 500);

    const { data } = useQuery(CourseForEvaluation_Query, { variables: { query: debouncedSearchText } });
    const navigate = useNavigate();

    return (
        <StackY>
            <SearchField
                variant={'standard'}
                value={searchText}
                onChange={event => {
                    setSearchText(event.target.value);
                }}
                label={'Search for a designer'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={'end'}>
                            <StyledSearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TableLayout>
                <TableRow>
                    <Text variant={'body2'} color={'GRAY'}>
                        Designer
                    </Text>
                    <Text variant={'body2'} color={'GRAY'}>
                        Course
                    </Text>
                    <Text variant={'body2'} color={'GRAY'}>
                        Completion
                    </Text>
                    <Text variant={'body2'} color={'GRAY'}>
                        Last Updated
                    </Text>
                </TableRow>

                {data?.listDesignTrainingCoursesForEvaluation.map(course => {
                    return (
                        <TableRow key={course.courseId}>
                            <Text variant={'body2'}>{course.designerName}</Text>
                            <Text variant={'body2'}>
                                <NarrowButton
                                    variant={'ghost'}
                                    onClick={() => {
                                        navigate(`/training/courses/${course.courseId}`);
                                    }}
                                >
                                    {course.courseName}
                                </NarrowButton>
                            </Text>
                            <Text variant={'body2'}>{`${course.completeCount} / ${course.totalCount}`}</Text>
                            <Text variant={'body2'}>
                                {course.lastCompletedAt
                                    ? DateUtils.relativeFormat(new Date(course.lastCompletedAt))
                                    : '-'}
                            </Text>
                        </TableRow>
                    );
                })}
            </TableLayout>
        </StackY>
    );
};

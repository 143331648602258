import { OrganizationWithTypeSelect } from '../../components/OrganizationSelect/OrganizationWithTypeSelect';
import { RoleSelect } from '../../components/RoleSelect';
import { AddUser } from './AddUser';
import { useGetPaginatedUsers } from './graphql/useGetPaginatedUsers.graphql';
import type { LabsGqlOrganizationType, LabsGqlStaffRole } from '@orthly/graphql-schema';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Text,
    FlossPalette,
    InputAdornment,
    SearchIcon,
    FormControl,
    Select,
    MenuItem,
} from '@orthly/ui-primitives';
import { debounce } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

export const PaginatedUsersTable: React.VFC = () => {
    const defaultRowsPerPage = 25;
    const [search, setSearch] = React.useState('');
    const [filterBy, setFilterBy] = React.useState<'name' | 'id' | 'email' | 'mobile_phone_number'>('name');
    const [currentPage, setCurrentPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
    const [organizationId, setOrganizationId] = React.useState<string | undefined>(undefined);
    const [organizationType, setOrganizationType] = React.useState<string | undefined>(undefined);
    const [role, setRole] = React.useState<string | undefined>(undefined);

    const navigate = useNavigate();

    const {
        users: rows,
        totalCount: totalRows,
        loading,
        refetch,
    } = useGetPaginatedUsers(currentPage, rowsPerPage, {
        name: filterBy === 'name' ? search : undefined,
        id: filterBy === 'id' ? search : undefined,
        email: filterBy === 'email' ? search : undefined,
        mobile_phone_number: filterBy === 'mobile_phone_number' ? search : undefined,
        organization_id: organizationId || undefined,
        // as is gross but we are passing the type as a string b/c it's coming from a select
        roles: role ? ([role] as LabsGqlStaffRole[]) : undefined,
        // as is gross but we are passing the type as a string b/c it's coming from a select
        staff_organization_type: organizationType ? ([organizationType] as LabsGqlOrganizationType[]) : undefined,
    });

    React.useEffect(() => {
        const fetchData = async () => {
            await refetch();
        };

        void fetchData();
    }, [rowsPerPage, refetch]);

    const handleSearch = debounce(value => {
        setSearch(value);
        setCurrentPage(0);
    }, 500);

    const handleChangePage = (__event: unknown, newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, defaultRowsPerPage));
        setCurrentPage(0); // Reset to the first page
    };

    const searchHeight = '61px';

    return (
        <Box sx={{ width: '100%', padding: '12px 24px 24px 24px' }}>
            <Box
                sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center',
                    paddingBottom: '12px',
                }}
            >
                <Text variant={'h5'}>Users</Text>
                <AddUser />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', width: '100%' }}>
                <Box sx={{ display: 'flex', width: '78%' }}>
                    <TextField
                        sx={{ width: '100%' }}
                        variant={'outlined'}
                        onChange={e => handleSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position={'start'}>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            style: {
                                padding: '0px 16px',
                                borderRadius: '8px 0 0 8px',
                                background: FlossPalette.TAN,
                                height: searchHeight,
                            },
                        }}
                    />
                    <Box sx={{ width: '200px' }}>
                        <FormControl fullWidth>
                            <Select
                                style={{
                                    borderRadius: '0 8px 8px 0',
                                    paddingBottom: '23px',
                                    height: searchHeight,
                                    marginRight: '8px',
                                }}
                                value={filterBy}
                                onChange={e => {
                                    setFilterBy(e.target.value as 'name' | 'id' | 'email' | 'mobile_phone_number');
                                }}
                            >
                                <MenuItem value={'name'}>Name</MenuItem>
                                <MenuItem value={'id'}>ID</MenuItem>
                                <MenuItem value={'email'}>Email</MenuItem>
                                <MenuItem value={'mobile_phone_number'}>Login Phone Number (Mobile)</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <RoleSelect
                        setRole={setRole}
                        organizationType={organizationType}
                        setOrganizationType={setOrganizationType}
                    />
                </Box>
                <Box sx={{ width: '20%' }}>
                    <OrganizationWithTypeSelect
                        setOrganizationId={setOrganizationId}
                        setOrganizationType={setOrganizationType}
                        organizationType={organizationType}
                    />
                </Box>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Login Email</TableCell>
                        <TableCell>Login Phone Number (Mobile)</TableCell>
                        <TableCell>Deactivated</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && (
                        <TableRow>
                            <TableCell colSpan={4} align={'center'}>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                    {!loading &&
                        rows &&
                        rows.length > 0 &&
                        rows.map(
                            (row: {
                                first_name: string;
                                last_name: string;
                                id: string;
                                email: string;
                                mobile_phone_number?: string | null | undefined;
                                number_of_staff: number;
                            }) => (
                                <TableRow
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor:
                                            row.number_of_staff === 0 ? FlossPalette.ATTENTION_BACKGROUND : 'inherit',
                                    }}
                                    key={row.id}
                                    onClick={() => navigate(`/users/${row.id}`)}
                                >
                                    <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>
                                        {row.mobile_phone_number &&
                                            PhoneNumberUtils.prettyPhoneNumber(row.mobile_phone_number)}
                                    </TableCell>
                                    <TableCell>{row.number_of_staff === 0 ? 'True' : ''}</TableCell>
                                </TableRow>
                            ),
                        )}
                </TableBody>
            </Table>

            <TablePagination
                sx={{
                    display: 'flex',
                    '.MuiTablePagination-toolbar': {
                        width: '100%',
                    },
                }}
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRows ?? 0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

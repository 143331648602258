import { controlPointsAtom, shadeDataAtom } from '../../store/store';
import { Gradient } from '../../utils/Gradient';
import { Paper, styled } from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import React from 'react';

const GradientBackgroundRoot = styled(Paper)({
    display: 'flex',
    flex: '1',
    height: '100%',
    position: 'relative',
    padding: '15px',
    margin: '10px',
    borderRadius: '10px',
});

const Canvas = styled('canvas')({
    display: 'none',
});

const GradientBackground: React.FC = ({ children }) => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const elementRef = React.useRef<HTMLDivElement>(null);

    const [controlPoints] = useAtom(controlPointsAtom);
    const [shadeData] = useAtom(shadeDataAtom);

    const colors = shadeData.map(shade => shade.color);
    const gradient = Gradient(colors, controlPoints);

    React.useEffect(() => {
        if (canvasRef.current !== null) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            // Resize Canvas if needed
            const element = elementRef.current;
            if (element) {
                canvas.width = element.offsetWidth;
                canvas.height = element.offsetHeight;

                // Apply to Canvas
                for (let y = 0; y < canvas.height; y++) {
                    const colorValue = `#${gradient(100 * (y / canvas.height)).getHexString()}`;
                    if (ctx !== null) {
                        ctx.fillStyle = colorValue;
                        ctx.fillRect(0, y, canvas.width, 1);
                    }
                }

                // Set background image
                element.style.backgroundImage = `url(${canvas.toDataURL()})`;
                element.style.backgroundSize = 'cover';
            }
        }
    }, [shadeData, controlPoints, gradient]);

    return (
        <GradientBackgroundRoot ref={elementRef}>
            <Canvas ref={canvasRef} />
            {children}
        </GradientBackgroundRoot>
    );
};

export default GradientBackground;

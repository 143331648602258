import { automaticAtom, controlPointsAtom, shadeDataAtom } from '../../../store/store';
import type { ShadeData, ShadeEntry } from '../../../utils/ShadeData';
import { DEFAULT_CONTROL_POINTS, pickShade, SHADE_VALUES } from '../../../utils/ShadeValues';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import type { TeethShade } from '@orthly/items';
import { teethHexValues } from '@orthly/items';
import type { SelectChangeEvent } from '@orthly/ui-primitives';
import { Button, DeleteIcon, DragHandle, IconButton, MenuItem, Select, styled, Text } from '@orthly/ui-primitives';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';

const ShadeList = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const ShadeItem = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '12px',
});

const ShadeControls = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const PanelRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '12px',
});

const ColorPicker = styled('input')({
    border: 'none',
    margin: '14px',
    height: '32px',
    maxWidth: '32px',
    padding: '0px',
    borderRadius: '18px',
    '&:hover': {
        cursor: 'pointer',
    },
    '::-webkit-color-swatch': {
        border: 'none',
        padding: '0px',
    },
    '::-webkit-color-swatch-wrapper': {
        border: 'none',
        padding: '0px',
    },
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
});

const ShadeSelect = styled(Select)({
    height: '48px',
    width: '160px',
    padding: '0px',
    '& .MuiInput-input': {
        padding: '0px 0px 0px 12px !important',
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const StyledDragHandle = styled(DragHandle)({
    display: 'none',
    userSelect: 'none',
    '&:focus': {
        outline: 'none',
    },
});

const ShadeType = styled('div')({
    width: '80px',
});

const ShadeSetting = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    justifyContent: 'space-between',
});

const SortableShadeItem: React.FC<{
    shade: ShadeEntry;
    id: number;
    updateColor: (index: number, color: string) => void;
    deleteColor: (index: number) => void;
}> = React.memo(({ shade, id, updateColor, deleteColor }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: id });

    const style = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
              transition: isDragging ? 'none' : transition,
          }
        : undefined;

    return (
        <ShadeItem ref={setNodeRef} style={style}>
            <ShadeControls>
                <StyledDragHandle {...attributes} {...listeners} />
                <ShadeType>{shade.type || 'Gingival'}</ShadeType>
                <ColorPicker
                    type={'color'}
                    id={'color-picker'}
                    value={shade.color}
                    onChange={e => updateColor(id, e.target.value)}
                />
                <IconButton style={{ display: 'none' }} size={'large'} onClick={() => deleteColor(id)}>
                    <DeleteIcon />
                </IconButton>
            </ShadeControls>
        </ShadeItem>
    );
});

const ShadePanel: React.FC = () => {
    const [shadeData, setShadeData] = useAtom(shadeDataAtom);
    const setControlPoints = useSetAtom(controlPointsAtom);
    const automatic = useAtomValue(automaticAtom);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const addColor = React.useCallback(() => {
        const newShade: ShadeEntry = { color: teethHexValues.A1 };
        setShadeData([...shadeData, newShade]);
    }, [setShadeData, shadeData]);

    const deleteColor = React.useCallback(
        (index: number) => {
            setShadeData(shadeData.filter((_, i) => i !== index));
        },
        [setShadeData, shadeData],
    );

    const updateColor = React.useCallback(
        (index: number, color: string) => {
            setShadeData(shadeData.map((shade, i) => (i === index ? { ...shade, color } : shade)));
        },
        [setShadeData, shadeData],
    );

    const getShadeName = React.useCallback((shade: ShadeData) => {
        // Iterate over keys of SHADE_VALUES and compare with shade
        for (const key in SHADE_VALUES) {
            const expected = pickShade(key as keyof typeof SHADE_VALUES);
            if (expected.length === shade.length && expected.every((entry, i) => entry.color === shade[i]?.color)) {
                return key;
            }
        }
        return '';
    }, []);

    const handleDragEnd = React.useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            if (over && active.id !== over.id) {
                const oldIndex = Number(active.id);
                const newIndex = Number(over.id);

                setShadeData(arrayMove(shadeData, oldIndex, newIndex));
            }
        },
        [setShadeData, shadeData],
    );

    function onChangeShade(event: SelectChangeEvent<unknown>) {
        const value = event.target.value as keyof typeof SHADE_VALUES;
        setShadeData(pickShade(value as TeethShade));
        setControlPoints(DEFAULT_CONTROL_POINTS);
    }

    return (
        <PanelRoot>
            <Button
                sx={{ display: 'none', alignSelf: 'stretch', margin: '12px 0px' }}
                variant={'primary'}
                onClick={addColor}
            >
                Add Shade
            </Button>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                {!automatic && (
                    <ShadeSetting>
                        Shade
                        <ShadeSelect label={'Shade'} value={getShadeName(shadeData)} onChange={onChangeShade}>
                            {Object.keys(SHADE_VALUES).map(shade => (
                                <MenuItem value={shade} key={shade}>
                                    {shade}
                                </MenuItem>
                            ))}
                        </ShadeSelect>
                    </ShadeSetting>
                )}
                <ShadeList>
                    <SortableContext items={shadeData.map((_, index) => index)} strategy={verticalListSortingStrategy}>
                        {shadeData.map((shade, index) => (
                            <SortableShadeItem
                                key={`shade-${index}`}
                                shade={shade}
                                id={index}
                                updateColor={updateColor}
                                deleteColor={deleteColor}
                            />
                        ))}
                    </SortableContext>
                </ShadeList>
            </DndContext>
        </PanelRoot>
    );
};

export default ShadePanel;

import { selectedToolAtom } from '../../store/store';
import { ListItemButton, ListItemIcon, ListItemText, styled } from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import React from 'react';

const tools = [
    { name: 'Images', icon: undefined, disabled: true },
    { name: 'Shade', icon: undefined },
    { name: 'Gradient', icon: undefined },
    { name: 'Paint', icon: undefined, disabled: true },
    { name: 'Glaze', icon: undefined, disabled: true },
];

const ListRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const ToolList: React.FC = () => {
    const [, setSelectedTool] = useAtom(selectedToolAtom);

    return (
        <ListRoot>
            {tools.map((tool, index) => (
                <ListItemButton key={index} disabled={tool.disabled} onClick={() => setSelectedTool(tool.name)}>
                    <ListItemIcon>{tool.icon}</ListItemIcon>
                    <ListItemText primary={tool.name} />
                </ListItemButton>
            ))}
        </ListRoot>
    );
};

export default ToolList;

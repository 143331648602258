import type { SmileLibrarySubmissionArgs } from './SmileLibrarySubmissionForm';
import { SmileLibrarySubmissionForm } from './SmileLibrarySubmissionForm';
import { useSmileLibraryCreationForm } from './SmileLibrarySubmissionForm.hooks';
import type { ExtractedSmileLibrary } from './SmileLibrarySubmissionForm.utils';
import type { ApolloQueryResult } from '@apollo/client';
import type { CadSmileLibraryList_QueryQuery } from '@orthly/graphql-inline-react';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface SmileLibraryCreationFormProps {
    existingNames: Set<string>;
    successCallback: () => Promise<ApolloQueryResult<CadSmileLibraryList_QueryQuery>>;
    enableCreation: boolean;
}

export const SmileLibraryCreationForm: React.FC<SmileLibraryCreationFormProps> = ({
    existingNames,
    successCallback,
    enableCreation,
    children,
}) => {
    const { createSmileLibraryCallback, ...formState } = useSmileLibraryCreationForm();

    const selectionErrorCallback = (smileLibrary: ExtractedSmileLibrary) =>
        existingNames.has(smileLibrary.identifier) ? 'Invalid selection - smile library already exists.' : undefined;

    const submitCallback = async (args: SmileLibrarySubmissionArgs) => {
        await createSmileLibraryCallback(args);
    };

    return (
        <SmileLibrarySubmissionForm
            selectionErrorCallback={selectionErrorCallback}
            getSubmissionIdsCallback={() => ({ libraryId: uuidv4(), revisionId: uuidv4() })}
            submitCallback={submitCallback}
            successCallback={successCallback}
            formState={formState}
            title={'Add Smile Library'}
            buttonText={'Create Smile Library'}
            enableCreation={enableCreation}
        >
            {children}
        </SmileLibrarySubmissionForm>
    );
};

import { useGetFormattedNextAvailableLdrTime } from '../../../../../Calendly/calendly-utils.graphql';
import { DesignReviewOptions } from '../GuidedWaxupSelectDesignReviewOptionModal';
import { Box, FlossPalette, RadioPrimitive as Radio, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const DesignReviewOptionButtonLayout = styled(Box)(
    ({ selected, disabled }: { selected: boolean; disabled: boolean }) => ({
        width: '100%',
        padding: '16px',
        background: selected ? FlossPalette.PRIMARY_BACKGROUND : FlossPalette.TAN,
        borderRadius: '16px',
        border: `1px solid ${selected ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.STROKE_LIGHT}`,
        position: 'relative',
        '&:hover': {
            border: `1px solid ${selected ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.STROKE_DARK}`,
            cursor: 'pointer',
        },
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? '0.60' : '1',
    }),
);

const DesignReviewOptionButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
        padding: '24px 24px 0px 24px',
    },
}));

const DesignReviewOptionButtonTitleLayout = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 'auto',
    marginBottom: 4,
    gap: '16px',
});

const DesignReviewOptionButtonSubtitleLayout = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
});

interface DesignReviewOptionButtonProps {
    title: string;
    subtitle?: string;
    availabilitySubtitle?: string;
    selected: boolean;
    setSelectedDesignReviewOption: (value: DesignReviewOptions) => void;
    optionType: DesignReviewOptions;
    disabled?: boolean;
}

const DesignReviewOptionButton: React.VFC<DesignReviewOptionButtonProps> = ({
    title,
    subtitle,
    availabilitySubtitle,
    selected,
    setSelectedDesignReviewOption,
    optionType,
    disabled,
}) => {
    return (
        <DesignReviewOptionButtonLayout
            selected={selected}
            disabled={!!disabled}
            onClick={() => setSelectedDesignReviewOption(optionType)}
        >
            <DesignReviewOptionButtonTitleLayout>
                <Radio disabled={disabled} color={'secondary'} style={{ margin: '0 8px 0 0' }} checked={selected} />
                <Text variant={'body2'} bold>
                    {title}
                </Text>
            </DesignReviewOptionButtonTitleLayout>
            {subtitle && (
                <DesignReviewOptionButtonSubtitleLayout>
                    <Text variant={'body2'} color={'GRAY'} medium>
                        {subtitle}
                    </Text>
                </DesignReviewOptionButtonSubtitleLayout>
            )}
            {availabilitySubtitle && (
                <DesignReviewOptionButtonSubtitleLayout>
                    <Text variant={'caption'} color={'GRAY'} bold>
                        {availabilitySubtitle}
                    </Text>
                </DesignReviewOptionButtonSubtitleLayout>
            )}
        </DesignReviewOptionButtonLayout>
    );
};

interface GuidedWaxupSelectDesignReviewOptionModalContentProps {
    selectedDesignReviewOption: DesignReviewOptions;
    setSelectedDesignReviewOption: (value: DesignReviewOptions) => void;
}

export const GuidedWaxupSelectDesignReviewOptionModalContent: React.VFC<
    GuidedWaxupSelectDesignReviewOptionModalContentProps
> = ({ selectedDesignReviewOption, setSelectedDesignReviewOption }) => {
    const nextAvailableLdrTimeString = useGetFormattedNextAvailableLdrTime();

    const optionButtons = [
        {
            title: 'Reject Design & Continue with Design Preview Only',
            optionType: DesignReviewOptions.CONTINUE_WITHOUT_REVIEW,
        },
        {
            title: 'Talk With a Design Expert',
            subtitle: 'Schedule a live call with a Design Expert to review, update, and approve designs in real time.',
            availabilitySubtitle: nextAvailableLdrTimeString?.length ? nextAvailableLdrTimeString : undefined,
            optionType: DesignReviewOptions.REQUEST_LDR,
        },
        {
            // TODO: coming soon style and metric
            title: 'Coming Soon: Request Video Design Review',
            subtitle: 'Receive a video of your design feedback being applied along with a before-and-after comparison.',
            // TODO availabilitySubtitle ,
            optionType: DesignReviewOptions.REQUEST_VDR,
            disabled: true,
        },
    ];

    return (
        <DesignReviewOptionButtonWrapper>
            {optionButtons.map(props => (
                <DesignReviewOptionButton
                    key={props.optionType}
                    {...props}
                    selected={props.optionType === selectedDesignReviewOption}
                    setSelectedDesignReviewOption={setSelectedDesignReviewOption}
                />
            ))}
        </DesignReviewOptionButtonWrapper>
    );
};

import { useUsersAction } from '../state/Users.actions';
import { useUsersSelector } from '../state/Users.context';
import type { IteroInfoEditUserRoles } from './EditUser.util';
import { EditUserRole } from './EditUserRole/EditUserRole';
import { useListOrgSummariesQuery } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { Grid, CircularProgress, Button, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const EditUserRoles: React.FC<{ iteroInfo?: IteroInfoEditUserRoles }> = ({ iteroInfo }) => {
    const { roles, user, rolesLoaded } = useUsersSelector(s => ({
        roles: s.form?.roles ?? [],
        user: s.user,
        rolesLoaded: s.form?.rolesLoaded ?? false,
    }));
    const addRole = useUsersAction('ADD_ROLE');

    const { data: organizationsRaw, loading: organizationsLoading } = useListOrgSummariesQuery();

    const { activeRoles, inactiveRoles } = React.useMemo(() => {
        const [activeRoles, inactiveRoles] = _.partition(
            roles.map((role, idx) => ({ ...role, idx })),
            r => !r.deactivated,
        );
        return { activeRoles, inactiveRoles };
    }, [roles]);

    if (!user) {
        return null;
    }

    return (
        <LoadBlocker blocking={organizationsLoading}>
            {rolesLoaded && (
                <Grid container spacing={1} direction={'column'}>
                    <Grid
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '16px 0 0px 0',
                            alignItems: 'center',
                        }}
                    >
                        <Text variant={'h6'}>Roles</Text>
                        <Button startIcon={'AddIcon'} onClick={addRole} variant={'primary'} disabled={!rolesLoaded}>
                            Add New Role
                        </Button>
                    </Grid>
                    {activeRoles.length > 0 && (
                        <>
                            <Grid container spacing={3} item direction={'column'}>
                                {activeRoles.map(role => (
                                    <Grid container item key={role.idx}>
                                        <EditUserRole
                                            organizations={organizationsRaw?.listOrganizationSummaries ?? []}
                                            idx={role.idx}
                                            role={role}
                                            inactive={false}
                                            iteroInfo={iteroInfo}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}

                    {inactiveRoles.length > 0 && (
                        <>
                            <Grid item style={{ padding: '16px 0' }}>
                                <Text variant={'h6'}>Inactive Roles</Text>
                            </Grid>

                            <Grid container spacing={3} item direction={'column'}>
                                {inactiveRoles.map(role => (
                                    <Grid container item key={role.idx}>
                                        <EditUserRole
                                            organizations={organizationsRaw?.listOrganizationSummaries ?? []}
                                            idx={role.idx}
                                            role={role}
                                            inactive={true}
                                            iteroInfo={iteroInfo}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
            {!rolesLoaded && <CircularProgress color={'primary'} />}
        </LoadBlocker>
    );
};

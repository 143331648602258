import { DesignReviewOptions } from '../GuidedWaxupSelectDesignReviewOptionModal';
import { Box, Button, FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const DesignReviewOptionFooterLayout = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '12px',
    [theme.breakpoints.down('sm')]: {
        borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        gap: '8px',
    },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
        padding: '12px 16px',
        flexDirection: 'column',
        gap: '8px',
    },
}));

interface GuidedWaxupSelectDesignReviewOptionModalFooterProps {
    onDesignReviewedAction: () => Promise<void>;
    openAbandonmentModal: () => void;
    openCalendlyModal: () => void;
    selectedDesignReviewOption: DesignReviewOptions;
    isMobile: boolean;
}

export const GuidedWaxupSelectDesignReviewOptionModalFooter: React.VFC<
    GuidedWaxupSelectDesignReviewOptionModalFooterProps
> = ({ onDesignReviewedAction, openAbandonmentModal, openCalendlyModal, selectedDesignReviewOption, isMobile }) => {
    const getPrimaryButtonProps = (option: DesignReviewOptions) => {
        switch (option) {
            case DesignReviewOptions.CONTINUE_WITHOUT_REVIEW:
                return {
                    title: ' Reject Design As Is',
                    action: onDesignReviewedAction,
                };
            case DesignReviewOptions.REQUEST_LDR:
                return {
                    title: ' Reject Design & Reserve Consultation',
                    action: openCalendlyModal,
                };
            case DesignReviewOptions.REQUEST_VDR:
                return {
                    title: ' Reject Design & Request Video Review',
                    action: () => {},
                };
        }
    };

    const { title, action } = getPrimaryButtonProps(selectedDesignReviewOption);

    return (
        <DesignReviewOptionFooterLayout>
            <ButtonWrapper>
                <Button variant={'secondary-gray'} onClick={openAbandonmentModal}>
                    Cancel
                </Button>
                <Button variant={'primary'} onClick={action}>
                    {title}
                </Button>
            </ButtonWrapper>
            {selectedDesignReviewOption === DesignReviewOptions.REQUEST_LDR && (
                <Text align={isMobile ? 'center' : 'right'} variant={'caption'} color={'ATTENTION_FOREGROUND'} medium>
                    Missed appointments will prompt Dandy to redesign your case using your rejection notes to prevent
                    delays.
                </Text>
            )}
        </DesignReviewOptionFooterLayout>
    );
};

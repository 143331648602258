import { GuidedWaxupScreenWrapper } from './GuidedWaxupScreenWrapper';
import { GuidedWaxupToolbarWrapper } from './GuidedWaxupToolbarWrapper';
import type { GuidedWaxupContextShape } from './state/GuidedWaxupContext';
import { GuidedWaxupContextProvider } from './state/GuidedWaxupContext';
import { GuidedWaxupProvider } from './state/GuidedWaxupState';
import React from 'react';

export const GuidedWaxupWrapper: React.VFC<GuidedWaxupContextShape> = context => {
    return (
        <GuidedWaxupProvider>
            <GuidedWaxupContextProvider {...context}>
                {!context.onlyShowModelViewer && <GuidedWaxupToolbarWrapper />}
                <GuidedWaxupScreenWrapper />
            </GuidedWaxupContextProvider>
        </GuidedWaxupProvider>
    );
};

import { toothRefAtom, shadeCompleteAtom, automaticAtom, downloadCompleteAtom } from '../../store/store';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import type { Object3D } from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';

interface ExportGLBProps {
    autorun: boolean;
}

const ExportGLB: React.FC<ExportGLBProps> = ({ autorun }) => {
    const toothRef = useAtomValue(toothRefAtom);
    const shadeComplete = useAtomValue(shadeCompleteAtom);
    const [automatic, setAutomatic] = useAtom(automaticAtom);
    const setDownloadComplete = useSetAtom(downloadCompleteAtom);

    if (autorun) {
        setAutomatic(true);
    }

    React.useEffect(() => {
        if (!toothRef || !autorun) {
            return;
        }

        const parseModel = (model: Object3D) => {
            return new Promise<ArrayBuffer>((resolve, reject) => {
                const exporter = new GLTFExporter();
                exporter.parse(
                    model,
                    result => {
                        if (result instanceof ArrayBuffer) {
                            resolve(result);
                        } else {
                            reject(new Error('Unexpected result type'));
                        }
                    },
                    error => {
                        reject(error);
                    },
                    { binary: true, includeCustomExtensions: true },
                );
            });
        };

        const handleExport = () => {
            if (toothRef && shadeComplete && automatic) {
                const toothCopy = toothRef.clone();
                toothCopy.rotation.x = -Math.PI / 2;

                void parseModel(toothCopy)
                    .then(result => {
                        const blob = new Blob([result], { type: 'application/octet-stream' });
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `${toothRef.userData.fileName}.glb`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(url);
                        console.log('downloaded glb');

                        setTimeout(() => {
                            setDownloadComplete(true);
                        }, 500);
                    })
                    .catch(error => {
                        console.error('Export failed:', error);
                    });
            }
        };

        handleExport();
    }, [toothRef, shadeComplete, automatic, autorun, setDownloadComplete]);

    return null;
};

export default ExportGLB;

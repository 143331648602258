import type { CalendlyModal } from '../../../../Calendly';
import { useExperiment } from '../../../../Providers/Statsig';
import { useGuidedWaxupSelector } from '../../state/GuidedWaxupState';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import { useSession } from '@orthly/session-client';
import _ from 'lodash';
import type React from 'react';

export type CalendlyPrefill = React.ComponentProps<typeof CalendlyModal>['prefill'];

/**
 * Fetches data from the current session to form prefilled data for the Calendly block.
 */
export function useGetCalendlySessionPrefillData(): CalendlyPrefill {
    const session = useSession();

    return {
        name: session ? `${session.user.first_name} ${session.user.last_name}` : undefined,
        email: session?.user.email,
        firstName: session?.user.first_name,
        lastName: session?.user.last_name,
    };
}

/**
 * Fetches data from the active state to form prefilled data for the Calendly block.
 * These are things like the user's name, email, and notes.
 */
export function useGetCalendlyPrefillData(): CalendlyPrefill {
    const presets = useGuidedWaxupSelector(s => s.presets);
    const basePrefill = useGetCalendlySessionPrefillData();

    const prefilledNotes = Object.entries(presets).reduce<string>((state, preset) => {
        const [type, info] = preset;

        if (!info.status) {
            return state;
        }

        const block = `- ${_.startCase(type)} (${_.startCase(info.status)})${info.notes ? `: "${info.notes}"` : ``}`;

        return `${state}${block}`;
    }, '');

    return {
        ...basePrefill,
        customAnswers: {
            a1: !!prefilledNotes ? `Notes from current review:\r\n${prefilledNotes}` : '',
        },
    };
}

const GuidedWaxupLDREligibility_Query = graphql(`
    query GuidedWaxupLDREligibility($order_id: String!) {
        request: getLiveDdpReviewRequest(order_id: $order_id) {
            id
        }
    }
`);

/**
 * Utility hook to determine if an order is eligible to have a live design review performed.
 * This is currently defined by the PRD here:
 *     https://docs.google.com/document/d/10M0iZFLrTE5MQJj7ErAJpX61rceLGw-IWmdwQxCvlyY/edit?disco=AAABYYG9WOE
 */
export function useIsOrderEligibleForLiveDesignReview(
    order: Pick<LabsGqlOrder, 'fulfillment_workflow' | 'items_v2' | 'id'>,
) {
    const isEnabled = useExperiment('ldr_experiment').get('show_ldr_entrypoint');

    const { data } = useQuery(GuidedWaxupLDREligibility_Query, {
        variables: {
            order_id: order.id,
        },
        fetchPolicy: 'no-cache',
    });

    // This order already has a live design review request.
    if (!data || !!data.request) {
        return false;
    }

    // This user isn't enabled for the pilot AT ALL.
    if (!isEnabled) {
        return false;
    }

    // Only DDC-designed orders are eligible for this service.
    if (!order.fulfillment_workflow.configuration.internal_design_required) {
        return false;
    }

    const parsedItems = OrderItemV2Utils.parseItems(order.items_v2);
    const allowedSkus: LabOrderItemSKUType[] = [
        LabOrderItemSKUType.Crown,
        LabOrderItemSKUType.Bridge,
        LabOrderItemSKUType.Veneer,
        LabOrderItemSKUType.Model,
        LabOrderItemSKUType.Waxup,
        LabOrderItemSKUType.Implant,
        LabOrderItemSKUType.ImplantBridge,
        LabOrderItemSKUType.Other,
    ];
    return parsedItems.every(item => {
        // The item isn't one of the allowed SKUs, so clearly fails our test.
        if (!OrderItemV2Utils.itemIsType(item, allowedSkus)) {
            return false;
        }

        // Other items are allowed _IF_ they are Reduction Copings, Pink Porcelain, or Die Only.
        // Everything else fails our test.
        if (
            OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Other) &&
            item.unit.unit_type !== 'Reduction Coping' &&
            item.unit.unit_type !== 'Pink Porcelain' &&
            item.unit.unit_type !== 'Die Only'
        ) {
            return false;
        }

        // IF you haven't failed by now, you succeed.
        return true;
    });
}

import { useGetImpersonationToken } from '../../utils/useGetImpersonationToken';
import { ImpersonatePreviewBox } from '../Impersonate/ImpersonatePreviewBox';
import { useUsersAction } from './state/Users.actions';
import { useUsersSelector } from './state/Users.context';
import { useListOrgsWithoutRelationsQuery, useListUserOrganizationsQuery } from '@orthly/graphql-react';
import type { IOrganizationType } from '@orthly/retainer-common';
import {
    ToolbarContainer,
    SimpleInput,
    LoadBlocker,
    SimpleAutocomplete,
    apolloErrorMessage,
    OrthlyBrowserConfig,
} from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    Dialog,
    Grid,
    Button,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    CloseIcon,
    SearchIcon,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    root: {
        width: '100%',
        position: 'relative',
        height: '100%',
        alignContent: 'flex-start',
    },
    textField: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    inputRoot: { paddingRight: 16, background: 'transparent' },
}));

const UserImpersonateButton: React.FC<{
    organizationId: string;
    organizationName: string;
    organizationType: IOrganizationType;
    userId: string;
    userName: string;
}> = ({ organizationId, organizationType, organizationName, userId, userName }) => {
    const [impersonationUrl, setImpersonationUrl] = React.useState<string>('');

    const [getImpersonateToken, { loading: isLoadingImpersonationToken }] = useGetImpersonationToken(token =>
        setImpersonationUrl(`${OrthlyBrowserConfig.practicePortalUrl}/?impersonateToken=${token}`),
    );

    return (
        <>
            <Dialog open={!!impersonationUrl} onClose={() => setImpersonationUrl('')}>
                {impersonationUrl && (
                    <ImpersonatePreviewBox
                        impersonateLink={impersonationUrl}
                        onReset={() => setImpersonationUrl('')}
                        userName={`${userName} in ${organizationName}`}
                    />
                )}
            </Dialog>

            <MenuItem
                disabled={isLoadingImpersonationToken}
                onClick={() =>
                    getImpersonateToken({
                        variables: {
                            user_id: userId,
                            organization_id: organizationId,
                            expires_in_seconds: null,
                        },
                    })
                }
            >
                {organizationName}
                {organizationType === 'lab' && ' (Lab)'}
                {organizationType === 'practice' && ' (Practice)'}
                {organizationType === 'internal' && ' (Internal)'}
            </MenuItem>
        </>
    );
};

const UserEditToolbar: React.FC = () => {
    const stopEditing = useUsersAction('STOP_EDITING');
    const user = useUsersSelector(s => s.user);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const { enqueueSnackbar } = useSnackbar();
    const { loading: isLoadingImpersonateOptions, data: userOrgs } = useListUserOrganizationsQuery({
        variables: {
            user_id: user?.id ?? '',
        },
        skip: !user,
        onError: err =>
            enqueueSnackbar(apolloErrorMessage(err), {
                variant: 'error',
            }),
    });
    const userHasOrgs = !!(userOrgs && userOrgs.organizations.some(org => org.type !== 'internal'));
    const userName = React.useMemo(
        () =>
            [user?.first_name, user?.last_name]
                .filter((s): s is string => !!s)
                .map(s => s.trim())
                .join(' '),
        [user],
    );
    const history = useHistory();

    return (
        <Grid container alignItems={'flex-start'} justifyContent={'space-between'}>
            <Button
                variant={'secondary'}
                startIcon={'ChevronLeft'}
                onClick={() => {
                    stopEditing();
                    history.push('/users');
                }}
            >
                Back
            </Button>

            {user && (
                <>
                    <Button
                        variant={'primary'}
                        startIcon={'OpenInNew'}
                        endIcon={'KeyboardArrowDownIcon'}
                        disabled={isLoadingImpersonateOptions || !user || !userHasOrgs}
                        onClick={e => setAnchorEl(e.currentTarget)}
                        aria-label={'Impersonate Dropdown'}
                    >
                        Impersonate
                    </Button>
                    {userOrgs && user && (
                        <Menu
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            anchorEl={anchorEl}
                            open={!!anchorEl}
                            onClose={() => setAnchorEl(null)}
                        >
                            {userOrgs.organizations.map(
                                org =>
                                    org.type !== 'internal' &&
                                    org.type !== 'external' && (
                                        <UserImpersonateButton
                                            key={org.id}
                                            organizationId={org.id}
                                            organizationType={org.type}
                                            organizationName={org.name}
                                            userId={user.id}
                                            userName={userName}
                                        />
                                    ),
                            )}
                        </Menu>
                    )}
                </>
            )}
        </Grid>
    );
};

const UserSearchToolbar: React.FC = () => {
    const classes = useStyles();
    const { search, orgId } = useUsersSelector(s => ({ search: s.view.search, orgId: s.view.orgId }));
    const setSearch = useUsersAction('SET_SEARCH');

    const { data: listOrgs, loading: orgsLoading } = useListOrgsWithoutRelationsQuery();
    const orgOptions = React.useMemo(() => {
        const orgs = listOrgs?.listOrganizations;
        if (!orgs) {
            return [];
        }

        return _.sortBy(
            orgs.map(org => ({ value: org.id, label: org.legal_name })),
            opt => opt.label,
        );
    }, [listOrgs]);

    return (
        <Grid container>
            <Grid container item alignItems={'flex-start'} xs={8}>
                <SimpleInput
                    fullWidth
                    label={'Search'}
                    onChange={search => setSearch({ orgId, search })}
                    value={search ?? ''}
                    placeholder={'Type to search...'}
                    TextFieldProps={{
                        className: classes.textField,
                        InputLabelProps: { style: { background: 'none' } },
                        InputProps: {
                            className: classes.inputRoot,
                            endAdornment: (
                                <InputAdornment position={'end'} style={{ height: 30, paddingBottom: 4 }}>
                                    <Grid
                                        container
                                        style={{
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        wrap={'nowrap'}
                                    >
                                        {!!search ? (
                                            <IconButton
                                                onClick={() => setSearch({ orgId, search: undefined })}
                                                size={'small'}
                                                style={{ padding: 0 }}
                                            >
                                                <CloseIcon style={{ color: FlossPalette.STAR_GRASS }} />
                                            </IconButton>
                                        ) : (
                                            <SearchIcon color={'action'} />
                                        )}
                                    </Grid>
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                        },
                    }}
                />
            </Grid>
            <Grid container item alignItems={'flex-end'} xs={4}>
                <LoadBlocker blocking={orgsLoading}>
                    <SimpleAutocomplete
                        options={orgOptions}
                        initialInputValue={orgId}
                        label={'Organization'}
                        onChange={value => {
                            setSearch({ search, orgId: value ?? undefined });
                        }}
                        AutocompleteProps={{
                            classes,
                            style: { width: '100%', backgroundColor: 'transparent' },
                        }}
                    />
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};

export const UserToolbar: React.FC = () => {
    const open = useUsersSelector(s => s.open);

    return (
        <ToolbarContainer sticky style={{ flexWrap: 'nowrap', width: '100%', zIndex: 3 }}>
            {open && <UserEditToolbar />}
            {!open && <UserSearchToolbar />}
        </ToolbarContainer>
    );
};

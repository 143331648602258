import { CalendlyContainer } from './CalendlyContainer';
import { Button, Dialog, Icon, styled } from '@orthly/ui-primitives';
import React from 'react';
import type { EventScheduledEvent, EventTypeViewedEvent, InlineWidget } from 'react-calendly';

type CalendlyModalProps = Omit<React.ComponentProps<typeof InlineWidget>, 'rootElement' | 'open'> & {
    /**
     * URL of the calendly calendar, eg: https://calendly.com/d/dzr-hft-66r/aligners-overview
     */
    url: string;
    /**
     * This field is used to send as data in the onEventScheduled and onEventTypeViewed events that are automatically fired.
     * It helps make sure that the events sent to Segment are properly tagged with a specified origin, so we can attribute
     * these events to a specific place in the code. eg: `Product Hubs - Aligners`
     */
    trackingOrigin?: string;
    /**
     * The onEventScheduled and onEventTypeViewed properties are optional. By default the Calendly components will automatically
     * fire events without these properties defined, as long as a `trackingOrigin` field is specified. If you'd like to customize
     * the events fired when a calendly schedule is shown or an appointment is scheduled, you can defined these properties to override
     * the default behavior.
     */
    onEventScheduled?: (e: EventScheduledEvent) => void;
    /**
     * The onEventScheduled and onEventTypeViewed properties are optional. By default the Calendly components will automatically
     * fire events without these properties defined, as long as a `trackingOrigin` field is specified. If you'd like to customize
     * the events fired when a calendly schedule is shown or an appointment is scheduled, you can defined these properties to override
     * the default behavior.
     */
    onEventTypeViewed?: (e: EventTypeViewedEvent) => void;
    /**
     * The onModalClose property is a callback function that is called when the modal is closed.
     */
    onModalClose: () => void;
};

// Styles based on react-calendly popup
// https://github.com/tcampb/react-calendly/blob/5318b91d886dc67ec64a2463d7b78b194dc80a01/src/calendly-widget.css#L52-L80
const ModalContainer = styled('div')({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    '&&': {
        overflow: 'hidden',
        position: 'fixed',
        margin: 0,
        maxHeight: 'none',
        maxWidth: 'none',
    },
});

const Container = styled('div')({
    width: '100%',
    minWidth: '900px',
    height: '100%',
    maxHeight: '694px',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 975px)': {
        position: 'fixed',
        top: '50px',
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        minWidth: 0,
        height: 'auto',
        maxHeight: 'none',
    },
});

const IconButton = styled(Button)({
    position: 'absolute',
    top: '15px',
    right: '15px',
    fontSize: '2em',
    height: '40px',
    width: '40px',
    '&&': {
        minWidth: 0,
        minHeight: 0,
        padding: 0,
    },
    '@media (max-width: 975px)': {
        top: '5px',
        right: '5px',
    },
});

/**
 * The CalendlyModal is a component used to show a Schedule from Calendly. It loads an iframe which loads the interface
 * from Calendly, allowing users to select a time and a date to reserve a slot in a calendar.
 */
export const CalendlyModal = ({
    url,
    pageSettings,
    utm,
    prefill,
    onModalClose,
    trackingOrigin,
    onEventScheduled,
    onEventTypeViewed,
}: CalendlyModalProps) => {
    return (
        <Dialog open onClose={onModalClose} PaperComponent={ModalContainer}>
            <Container>
                <CalendlyContainer
                    url={url}
                    pageSettings={pageSettings}
                    utm={utm}
                    prefill={prefill}
                    styles={{ height: '100%' }}
                    trackingOrigin={trackingOrigin}
                    onEventScheduled={onEventScheduled}
                    onEventTypeViewed={onEventTypeViewed}
                />
            </Container>
            <IconButton variant={'secondary'} size={'small'} onClick={onModalClose}>
                <Icon icon={'CloseIcon'} />
            </IconButton>
        </Dialog>
    );
};

import { GuidedWaxupCancelLiveCallModal } from './GuidedWaxupCancelLiveCallModal';
import type { CalendlyPrefill } from './GuidedWaxupScheduleLiveCall.hooks.graphql';
import { GuidedWaxupScheduleLiveCallAbandonmentModal } from './GuidedWaxupScheduleLiveCallAbandonmentModal';
import { GuidedWaxupScheduleLiveCallCalendlyModal } from './GuidedWaxupScheduleLiveCallCalendlyModal';
import { GuidedWaxupSelectDesignReviewOptionModal } from './GuidedWaxupSelectDesignReviewOptionModal';
import { useMutation } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { graphql } from '@orthly/graphql-inline-react';
import { OrthlyBrowserConfig, useRootActionCommand } from '@orthly/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const VeneerScheduleLiveDDPCall_Mutation = graphql(`
    mutation VeneerScheduleLiveDDPCall_Mutation($args: CreateLiveDdpReviewRequestArgs!) {
        createLiveDdpReviewRequest(args: $args) {
            id
        }
    }
`);

export type GuidedWaxupScheduleModal = 'closed' | 'initial' | 'abandonment' | 'calendly' | 'options' | 'cancel';

export const GuidedWaxupAccessoryModals: React.VFC<{
    orderId: string;
    revisionId: string;
    isMobile: boolean;
    setModal: (modal: GuidedWaxupScheduleModal) => void;
    modal: GuidedWaxupScheduleModal;
    isRejected?: boolean;
    calendlyPrefill?: CalendlyPrefill;
    onDesignReviewedAction?: () => Promise<void>;
    onScheduleCompletion?: () => void;
    numRejections: number;
    isRefab: boolean;
}> = ({
    orderId,
    revisionId,
    setModal,
    modal,
    isMobile,
    isRejected,
    calendlyPrefill,
    onDesignReviewedAction,
    onScheduleCompletion,
    numRejections,
    isRefab,
}) => {
    const navigate = useNavigate();

    const rawMtn = useMutation(VeneerScheduleLiveDDPCall_Mutation);
    const { submit } = useRootActionCommand(rawMtn, {
        onSuccess: async () => {
            setModal('closed');
            navigate(`/orders/${orderId}`);
        },
        successMessage: 'Live Design Review successfully scheduled!',
    });

    return (
        <>
            {modal === 'abandonment' && (
                <GuidedWaxupScheduleLiveCallAbandonmentModal
                    closeRootModal={reason => {
                        BrowserAnalyticsClientFactory.Instance?.track(
                            'Practice - Portal - Live Design Review - Abandoned Scheduling Flow',
                            {
                                $groups: { order: orderId },
                                reason,
                            },
                        );
                        setModal('closed');
                    }}
                />
            )}
            {modal === 'calendly' && (
                <GuidedWaxupScheduleLiveCallCalendlyModal
                    orderId={orderId}
                    revisionId={revisionId}
                    calendlyPrefill={calendlyPrefill}
                    variant={OrthlyBrowserConfig.env === 'production' ? 'production' : 'development'}
                    onModalClose={() => {
                        setModal('abandonment');
                    }}
                    submit={async args => {
                        await onDesignReviewedAction?.();
                        await submit({ args });
                        onScheduleCompletion?.();
                    }}
                />
            )}
            {modal === 'options' && onDesignReviewedAction && (
                <GuidedWaxupSelectDesignReviewOptionModal
                    onDesignReviewedAction={onDesignReviewedAction}
                    openModal={() => setModal('options')}
                    openAbandonmentModal={() => setModal('abandonment')}
                    openCalendlyModal={() => setModal('calendly')}
                    onModalClose={() => setModal('closed')}
                    isMobile={isMobile}
                    numRejections={numRejections}
                    isRefab={isRefab}
                />
            )}
            {modal === 'cancel' && onDesignReviewedAction && (
                <GuidedWaxupCancelLiveCallModal
                    onDesignReviewedAction={onDesignReviewedAction}
                    openModal={() => setModal('cancel')}
                    onModalClose={() => setModal('closed')}
                    isMobile={isMobile}
                    isRejected={isRejected}
                />
            )}
        </>
    );
};

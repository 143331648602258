import { RouterUtils } from '../../../../utils/router/RouterUtils';
import { useAutomationBuilderAction } from '../../state/AutomationBuilder.actions';
import type { LabsGqlOrderAutomationFragment } from '@orthly/graphql-operations';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

// Utility hook to both set the editor form state with the selected automation, and also update the URL bar.
export function useAutomationStartEditing() {
    const startEditingAction = useAutomationBuilderAction('ENTER_EDITING');
    const navigate = useNavigate();

    return React.useCallback(
        (automation?: LabsGqlOrderAutomationFragment) => {
            navigate(`${RouterUtils.pathForScreen('automations')}/${automation?.id ?? ''}`, { replace: true });
            startEditingAction(automation);
        },
        [navigate, startEditingAction],
    );
}

import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrganizationType, LabsGqlStaffRole } from '@orthly/graphql-schema';

const GetPaginatedUsers_Query = graphql(`
    query GetPaginatedUsers($page: Float!, $rows_per_page: Float!, $filter: GetPaginatedUsersFilter) {
        getPaginatedUsers(page: $page, rows_per_page: $rows_per_page, filter: $filter) {
            users {
                id
                first_name
                last_name
                email
                mobile_phone_number
                phone_number
                number_of_staff
            }
            total
        }
    }
`);

export const useGetPaginatedUsers = (
    page: number,
    rows_per_page: number,
    filters: {
        name?: string;
        id?: string;
        email?: string;
        mobile_phone_number?: string;
        organization_id?: string;
        roles?: LabsGqlStaffRole[];
        staff_organization_type?: LabsGqlOrganizationType[];
    },
) => {
    const { data, loading, error, refetch } = useQuery(GetPaginatedUsers_Query, {
        variables: {
            page,
            rows_per_page,
            filter: {
                name: filters.name,
                id: filters.id,
                email: filters.email,
                mobile_phone_number: filters.mobile_phone_number,
                organization_id: filters.organization_id,
                roles: filters.roles,
                staff_organization_type: filters.staff_organization_type,
            },
        },
    });

    const returnData = data?.getPaginatedUsers;

    return {
        users: returnData?.users,
        totalCount: returnData?.total,
        loading,
        error: error?.message,
        refetch,
    };
};

// We import a CSS file because we need to set a specific class that the react-calendly modal uses,
// while the react-calendly package doesn't expose any way to provide custom styles.
import '../../../../../../assets/styles/GuidedWaxupScheduleLiveCall.css';
import { CalendlyModal } from '../../../../Calendly';
import type { CalendlyPrefill } from './GuidedWaxupScheduleLiveCall.hooks.graphql';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlCreateLiveDdpReviewRequestArgs } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import React from 'react';

interface GuidedWaxupScheduleLiveCallCalendlyModalProps {
    variant: 'production' | 'development';
    onModalClose: () => void;
    orderId: string;
    revisionId: string;
    calendlyPrefill?: CalendlyPrefill;
    submit: (args: LabsGqlCreateLiveDdpReviewRequestArgs) => Promise<void>;
}

export const GuidedWaxupScheduleLiveCallCalendlyModal: React.VFC<GuidedWaxupScheduleLiveCallCalendlyModalProps> = ({
    variant,
    onModalClose,
    orderId,
    revisionId,
    calendlyPrefill,
    submit,
}) => {
    const calendlyUrl =
        variant === 'production'
            ? 'https://calendly.com/boyle-meetdandy/schedule-a-live-design-review'
            : 'https://calendly.com/engineering-meetdandy/live-design-review';

    const session = useSession();

    if (!session) {
        return null;
    }

    return (
        <CalendlyModal
            url={calendlyUrl}
            onModalClose={onModalClose}
            prefill={calendlyPrefill}
            onEventScheduled={async event => {
                const eventId = event.data.payload.event.uri.split('/').at(-1) ?? '';
                await submit({
                    order_id: orderId,
                    design_order_revision_id: revisionId,
                    calendly_event_id: eventId,
                    practice_id: session.organization_id,
                });

                BrowserAnalyticsClientFactory.Instance?.track(
                    'Practice - Portal - Live Design Review - Scheduled Call',
                    {
                        $groups: { order: orderId },
                        eventId,
                    },
                );
            }}
        />
    );
};

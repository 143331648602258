import { toothRefAtom } from '../../store/store';
import { Button } from '@orthly/ui-primitives';
import { useAtomValue } from 'jotai';
import React from 'react';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';

const ExportGLBButton: React.FC = () => {
    const toothRef = useAtomValue(toothRefAtom);
    function handleExportGLB() {
        if (!toothRef) {
            return;
        }
        const exporter = new GLTFExporter();
        const toothCopy = toothRef.clone();
        toothCopy.rotation.x = -Math.PI / 2;

        if (toothRef) {
            exporter.parse(
                toothCopy,
                result => {
                    if (result instanceof ArrayBuffer) {
                        const blob = new Blob([result], { type: 'application/octet-stream' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = 'tooth.glb';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    } else {
                        console.error('Unexpected result type:', result);
                    }
                },
                error => {
                    console.error('An error occurred during the export:', error);
                },
                { binary: true, includeCustomExtensions: true },
            );
        }
    }

    return (
        <Button variant={'secondary'} disabled={!toothRef} onClick={handleExportGLB}>
            Export as GLB
        </Button>
    );
};

export default ExportGLBButton;

import type { EditCapacityDialogProps } from '../CapacityStationsRoot.graphql';
import type { LabsGqlCapacityStationRuleDto } from '@orthly/graphql-schema';
import { RootActionDialog } from '@orthly/ui';
import { Button, Grid } from '@orthly/ui-primitives';
import React from 'react';

type DisableCapacityStationRuleDialogProps = EditCapacityDialogProps & {
    rule: LabsGqlCapacityStationRuleDto;
};

export const DisableCapacityStationRuleDialog: React.VFC<DisableCapacityStationRuleDialogProps> = (
    props: DisableCapacityStationRuleDialogProps,
) => {
    const { submitting, open, setOpen, submit, rule } = props;
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Disable Rule'}
            content={
                <Grid container style={{ padding: 20 }}>
                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                        Are you sure you want to disable this rule?
                    </Grid>
                    <Grid item xs={12} container justifyContent={'flex-end'}>
                        <Button
                            variant={'primary'}
                            onClick={() => submit({ disableCapacityStationRuleArgs: { id: rule.id } })}
                        >
                            Yes, Disable
                        </Button>
                        <Button variant={'secondary'} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            }
            hideButton={true}
        />
    );
};

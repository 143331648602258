import type { TooltipProps } from '@orthly/ui-primitives';
import { IconButton, styled, Tooltip } from '@orthly/ui-primitives';
import type { ReactNode } from 'react';
import React from 'react';

export type ChangingIconToggleButtonProps = {
    active: boolean;
    activeIcon: ReactNode;
    inactiveIcon: ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    activeTooltip?: string;
    inactiveTooltip?: string;
    tooltipPlacement?: TooltipProps['placement'];
    /** Duration of the transition animation in milliseconds. Set to 0 to disable animation */
    animationDuration?: number;
    enterDelay?: number;
};

const IconWrapper = styled('div')<{ active: boolean; animationDuration: number }>(({ active, animationDuration }) => ({
    position: 'absolute',
    transition: animationDuration > 0 ? `opacity ${animationDuration}ms ease-in-out` : 'none',
    display: 'flex',
    opacity: active ? 1 : 0,
    '&:active': {
        backgroundColor: 'transparent', // Prevent background color change on mousedown
    },
}));

const StyledIconButton = styled(IconButton)({
    width: '42px',
    height: '42px',
});

export const ChangingIconToggleButton: React.VFC<ChangingIconToggleButtonProps> = props => {
    const {
        active,
        onClick,
        activeTooltip,
        inactiveTooltip,
        activeIcon,
        inactiveIcon,
        tooltipPlacement,
        animationDuration = 200, // Default animation duration
        enterDelay,
        ...rest
    } = props;

    const tooltipText = active ? activeTooltip : inactiveTooltip;

    return (
        <div>
            <Tooltip
                title={tooltipText}
                enterDelay={enterDelay}
                placement={tooltipPlacement ?? 'bottom'}
                PopperProps={{
                    sx: {
                        zIndex: 10000, // Ensure tooltip is above fullscreen layout
                    },
                }}
            >
                <StyledIconButton aria-label={tooltipText} onClick={onClick} {...rest}>
                    <IconWrapper active={active} animationDuration={animationDuration}>
                        {activeIcon}
                    </IconWrapper>
                    <IconWrapper active={!active} animationDuration={animationDuration}>
                        {inactiveIcon}
                    </IconWrapper>
                </StyledIconButton>
            </Tooltip>
        </div>
    );
};

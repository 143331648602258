import { useMutation } from '@apollo/client';
import { DownloadUtils } from '@orthly/dentin';
import { graphql } from '@orthly/graphql-inline-react';
import { useBulkUpdateDesignStaffRosterMutation } from '@orthly/graphql-react';
import { getFullStoragePath, DesignStorageConfigs } from '@orthly/shared-types';
import { useChangeSubmissionFn, OrthlyBrowserConfig, useRootActionCommand } from '@orthly/ui';
import { Button, FlossPalette, Medium, Text, stylesFactory } from '@orthly/ui-primitives';
import { FileUploadStatus, SimpleDropzone, useUploadFiles } from '@orthly/veneer';
import React from 'react';

const BulkDownloadDesignStaffRosterMutation = graphql(`
    mutation BulkDownloadDesignStaffRoster {
        bulkDownloadDesignStaffRoster {
            downloadUrl
        }
    }
`);

const DownloadButton: React.VFC<{}> = _ => {
    const downloadMutation = useMutation(BulkDownloadDesignStaffRosterMutation);
    const { submit } = useRootActionCommand(downloadMutation, {
        onSuccess: async result => {
            const url = result.data.bulkDownloadDesignStaffRoster.downloadUrl;
            DownloadUtils.downloadUrl(url);
        },
    });
    return (
        <Button
            variant={'secondary'}
            onClick={() => {
                void submit({});
            }}
            endIcon={'DownloadIcon'}
        >
            Download CSV
        </Button>
    );
};

const useStyles = stylesFactory(() => ({
    root: {
        width: '100%',
    },
    title: { padding: '24px 24px 24px', borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}` },
    body: { display: 'flex', padding: '24px 0px' },
    leftColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 32px',
        borderRight: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        gap: '16px',
    },
    rightColumn: { padding: '0px 32px' },
    dropzoneContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
    },
}));

export const DesignAutoAssignmentBulkEditRosterView = () => {
    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, DesignStorageConfigs.rosterDataBulkUpload);
    const { uploadFiles } = useUploadFiles({ storagePathConfig });

    const [mutationFn] = useBulkUpdateDesignStaffRosterMutation();
    const { submit } = useChangeSubmissionFn(mutationFn, {
        closeOnComplete: true,
        successMessage: () => ['Data uploaded successfully. Processing may take a few moments.', {}],
    });

    const onDropAccepted = (filesToUpload: File[]) => {
        void uploadFiles(filesToUpload, { productName: '', device: 'web' }, newFiles => {
            const [file] = newFiles;
            if (file && file.filepath && file.status === FileUploadStatus.completed) {
                void submit({ variables: { uploadPath: file.filepath } }).catch(err => console.error(err));
            }
        });
    };

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Text variant={'h5'} className={classes.title}>
                Manage designer roster
            </Text>
            <div className={classes.body}>
                <div className={classes.leftColumn}>
                    <SimpleDropzone
                        wrapperStyle={{ width: 328 }}
                        options={{
                            accept: { 'text/csv': ['.csv'] },
                            multiple: false,
                            onDropAccepted,
                        }}
                        dropzoneContent={
                            <div className={classes.dropzoneContent}>
                                <Text variant={'body2'} color={'BLACK'}>
                                    Drop .CSV file here
                                </Text>
                                <Text variant={'body2'} color={'GRAY'}>
                                    or
                                </Text>
                                <Button variant={'secondary'} endIcon={'AttachIcon'}>
                                    Choose file
                                </Button>
                            </div>
                        }
                    />
                    <DownloadButton />
                </div>
                <div className={classes.rightColumn}>
                    <Text variant={'body1'} color={'BLACK'} medium style={{ marginBottom: '16px' }}>
                        .CSV formatting instructions
                    </Text>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        Upload a CSV file with the following columns. All but Portal ID are optional. Excess columns are
                        ignored.
                        <br />
                        <Medium>Portal ID:</Medium> The designer's user ID (required)
                        <br />
                        <Medium>Country:</Medium> The designer's country
                        <br />
                        <Medium>Status:</Medium> The designer's employment status (Full-time, Fixed rate, Pay as you go,
                        etc.)
                        <br />
                        <Medium>Region:</Medium> The designer's region (MEA, LATM, SA, etc.)
                        <br />
                        <Medium>Team:</Medium> The name of the designer's team (create teams in the User management UI)
                        <br />
                        <Medium>Role:</Medium> The designer's role (Lead, Quality, Designer, etc.)
                        <br />
                        <Medium>QC Percentage:</Medium> The QC percentage as a number (0 to 100) for the designer
                        <br />
                        <Medium>Main Capability:</Medium> The designer's main capability (Anterior C&B, Posterior C&B,
                        Implant, Model, Waxup, Dentures, PFM, PFZ, Partials, Partials Injection Mold, Aligners, or
                        Removables)
                        <br />
                        <Medium>Timezone:</Medium> The designer's timezone identifier (America/New_York, Africa/Cairo,
                        etc.). See the list of valid identifiers on{' '}
                        <a href={'https://en.wikipedia.org/wiki/List_of_tz_database_time_zones'} target={'blank'}>
                            Wikipedia
                        </a>
                        .
                        <br />
                        <Medium>Workweek:</Medium> A comma-separated list of 3-letter abbreviated days the designer
                        works ("Mon, Tue, Wed, Thu")
                        <br />
                        <Medium>Start Time:</Medium> The time that the designer starts work, in 24-hour format (8:30)
                        <br />
                        <Medium>End Time:</Medium> The time that the designer finishes work, in 24-hour format (16:00)
                        <br />
                        <Medium>Prep Capabilities:</Medium> Comma-separated list of prep capabilities. For example
                        "Implant 3, Anterior 1". Valid capability types are Posterior, Anterior, and Implant. For no
                        capabilities, use a single dash "-".
                        <br />
                        <Medium>Design Capabilities:</Medium> Comma-separated list of design capabilities. For example
                        "Anterior 1, Posterior 3, PFM 1". Valid capability types are Posterior, Anterior, Implant,
                        Model, Waxup, Dentures, Dentures Wax Rim, PFM, PFZ, Refabrication, Red Carpet, Emax, and Noble
                        White. For no capabilities, use a single dash "-".
                        <br />
                        <Medium>Injection Mold Design Capabilities:</Medium> Comma-separated list of design
                        capabilities. For example "Partials Injection Mold 1". Valid capability types are Partials
                        Injection Mold. For no capabilities, use a single dash "-".
                        <br />
                        <Medium>Review Capabilities:</Medium> Comma-separated list of review capabilities. For example
                        "Anterior 1, Posterior 3, PFM 1". Valid capability types are Posterior, Anterior, Implant,
                        Model, Waxup, Dentures, Dentures Wax Rim, PFM, and PFZ. For no capabilities, use a single dash
                        "-".
                        <br />
                        <Medium>Focus Areas:</Medium> Comma-separated list of focus areas, including task type. Valid
                        task types are Prep, Design, Review, and Model. For example "Prep Posterior 1" or "Design
                        Anterior 3, Design Anterior 4". For no focus areas, use a single dash "-".
                    </Text>
                </div>
            </div>
        </div>
    );
};

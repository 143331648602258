import { usePartnersData } from '../../../../../utils/usePartners';
import { ImpersonatePreviewBox } from '../../../../Impersonate/ImpersonatePreviewBox';
import type { LabsGqlScanFileFragment } from '@orthly/graphql-operations';
import { useGetImpersonationTokenLazyQuery } from '@orthly/graphql-react';
import type { FieldsDefProp } from '@orthly/ui';
import { QuickForm, RootActionDialog } from '@orthly/ui';
import { Collapse, Grid, IconButton, Tooltip, Icon } from '@orthly/ui-primitives';
import React from 'react';

interface OpenScanSubmissionProps {
    scan: LabsGqlScanFileFragment;
}

export const OpenScanSubmission: React.FC<OpenScanSubmissionProps> = ({ scan }) => {
    const { assigned_practice_id } = scan.scanner;
    const [open, setOpen] = React.useState(false);
    const { data, loading } = usePartnersData();
    const [, setFormState] = React.useState<Partial<{ partner: string }>>({});
    const formFields: FieldsDefProp<{ partner: string }> = {
        partner: {
            type: 'select',
            options: (data?.listPracticeNames || []).map(p => ({ label: p.name, value: p.id })),
        },
    };
    const [practiceUrl, setPracticeUrl] = React.useState<string | undefined>();
    const [triggerQuery, { loading: tokenLoading }] = useGetImpersonationTokenLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        onCompleted: data => {
            const url = data?.getImpersonationToken.practiceUrl.replace('?', `/lab/submit?scanId=${scan.id}&`);
            setPracticeUrl(url);
        },
    });
    const onClickButton = React.useCallback(() => {
        if (assigned_practice_id) {
            triggerQuery({
                variables: { organization_id: assigned_practice_id, user_id: null, expires_in_seconds: null },
            });
        }
        setOpen(true);
    }, [assigned_practice_id, triggerQuery]);
    return (
        <RootActionDialog
            open={open}
            loading={loading || tokenLoading}
            buttonText={'Submit Order'}
            buttonColor={'#7300FF'}
            buttonTextColor={'#fff'}
            setOpen={newStatus => {
                if (loading) {
                    return;
                }
                setOpen(newStatus);
            }}
            actions={null}
            title={'Submit Order'}
            content={
                <Grid container>
                    <Collapse in={!!practiceUrl}>
                        <ImpersonatePreviewBox
                            userName={scan.scanner?.owner?.name ?? 'Scan Practice'}
                            impersonateLink={practiceUrl ?? ''}
                            onReset={() => setPracticeUrl(undefined)}
                        />
                    </Collapse>
                    <Collapse in={!practiceUrl}>
                        <QuickForm<{ partner: string }>
                            fields={formFields}
                            onChange={setFormState}
                            initialValues={{ partner: assigned_practice_id ?? undefined }}
                            onSubmit={async result => {
                                triggerQuery({
                                    variables: {
                                        organization_id: result.partner,
                                        user_id: null,
                                        expires_in_seconds: null,
                                    },
                                });
                            }}
                            submitButtonProps={{ children: 'Open Order Submission Dialog' }}
                        />
                    </Collapse>
                </Grid>
            }
            style={{ width: 'auto', display: 'flex', flexBasis: 'auto' }}
            CustomButton={() => (
                <IconButton onClick={onClickButton}>
                    <Tooltip title={'Place Order'}>
                        <Icon icon={'ShoppingCart'} />
                    </Tooltip>
                </IconButton>
            )}
        />
    );
};

import type { TeethShade } from '@orthly/items';
import { ValidShadeTypeEnum } from '@orthly/items';

// pulled from https://docs.google.com/spreadsheets/d/1smYz5uMuk7T92YAaaZ1nrKQqX3HUSkIqtyZPEFTUzhA/edit?gid=0#gid=0
export const colorData = `
	L	A	B	L	A	B	L	A	B	R	G	B	R	G	B	R	G	B
A1	98	-1	16	96	0	15	90	-1	10	255	248	217	251	242	213	231	228	209
A2	97	-3	10	96	-3	10	90	-5	3	247	249	227	242	244	223	219	229	220
A3	98	-3	14	97	-3	15	92	-5	6	251	250	222	248	247	217	224	234	220
A3,5	97	-1	11	94	-1	11	87	-4	3	253	248	226	244	239	217	213	220	212
A4	96	-2	20	93	-2	19	87	-4	11	251	243	203	242	234	197	217	220	198
B1	99	-1	10	97	-1	11	97	-1	4	255	251	231	251	247	225	228	229	221
B2	97	-2	17	251	243	206	92	-3	14	252	244	207	251	243	206	235	234	206
B3	95	-1	27	94	1	29	91	-2	22	253	240	187	254	235	180	237	229	186
B4	95	-2	30	94	0	30	91	-1	20	254	241	183	254	238	181	239	230	191
C1	94	0	17	94	-1	17	89	-2	9	247	236	204	245	236	203	225	225	205
C2	91	3	23	92	0	23	87	-1	10	248	227	185	245	230	187	222	217	197
C3	92	3	26	89	3	26	85	1	18	253	230	184	243	221	174	225	212	179
C4	88	5	32	85	4	31	83	2	20	246	216	160	235	208	153	219	203	169
D2	96	1	18	94	0	16	91	-1	10	255	242	209	247	236	206	234	229	210
D3	91	3	32	91	3	29	86	1	19	253	227	169	250	225	171	227	213	179
D4	91	3	31	86	3	31	81	1	19	250	225	168	237	214	158	213	199	165
0M1	93	0	16	95	0	16	97	0	16	245	234	204	252	241	211	255	245	215
0M2	92	0	17	94	1	18	96	1	15	243	232	200	252	237	204	254	242	214
0M3	95	0	16	97	0	16	97	-1	14	250	239	209	254	245	214	254	247	221
1M1	93	2	23	94	1	25	95	1	18	252	233	190	254	237	191	254	239	206
1M2	92	3	39	92	4	29	94	1	20	253	229	175	255	229	178	249	235	198
2L1.5	91	3	31	92	2	32	94	1	21	250	225	168	254	230	170	253	238	198
2L2.5	91	2	35	93	0	36	94	2	23	225	227	163	254	234	165	253	235	193
2M1	90	2	26	93	2	27	96	-1	19	245	224	177	253	232	183	254	244	207
2M2	96	-1	19	92	2	33	96	-1	23	254	244	207	255	230	170	254	243	199
2M3	91	3	40	93	0	39	95	-2	29	254	225	152	255	232	158	254	242	186
2R1.5	88	5	28	92	4	29	93	3	19	244	216	168	254	228	177	251	231	197
2R2.5	89	6	36	91	4	37	92	3	25	253	219	155	255	225	158	253	230	184
3L1.5	90	4	31	91	3	32	95	1	23	250	223	168	253	227	168	254	238	194
3L2.5	89	6	38	90	4	39	94	0	26	254	220	151	255	223	151	254	237	187
3M1	88	5	27	92	4	30	95	1	21	245	217	169	254	227	174	255	239	200
3M2	88	7	36	89	7	38	91	4	30	251	214	151	254	218	151	253	226	173
3M3	87	7	40	88	7	41	94	1	29	250	213	143	254	216	143	255	253	181
3R1.5	88	5	26	90	6	29	95	2	19	245	218	172	254	222	172	254	237	203
3R2.5	87	6	36	89	7	39	93	1	28	248	213	149	253	217	147	254	234	182
4L1.5	93	1	28	89	6	35	92	4	24	254	234	182	253	219	158	254	230	187
4L2.5	86	9	43	88	8	43	92	3	33	252	208	133	254	213	137	255	229	168
4M1	86	7	28	90	6	30	94	3	22	241	208	161	253	220	168	255	234	195
4M2	87	8	37	88	8	39	92	4	30	250	212	147	254	215	146	254	227	172
4M3	85	13	45	98	9	48	93	1	34	254	202	126	255	209	124	254	233	168
4R1.5	88	9	33	89	8	32	95	1	22	252	213	158	255	217	162	254	239	198
4R2.5	86	10	39	87	10	40	91	4	33	252	207	142	254	209	141	254	227	167
5M1	82	10	31	87	10	34	91	6	23	236	196	145	252	210	153	251	223	183
5M2	86	12	39	85	13	21	93	3	30	253	205	141	254	203	133	255	231	176
5M3	86	10	47	84	13	50	90	5	33	254	207	126	254	200	114	253	222	164
BL1	94	-3	3	95	-2	3	93	-1	4	231	238	230	237	240	233	235	236	228
BL2	91	-3	5	93	-2	7	92	-2	6	228	231	221	235	237	223	232	234	221
BL3	90	-3	9	92	-2	10	93	-1	8	227	228	210	235	234	214	236	234	219
BL4	87	-2	13	91	-2	14	92	-1	10	223	219	194	233	229	202	237	232	213
`;

// export function parseColorData(data: string) {
//     // Helper function to convert a number to a two-digit hex value.
//     function componentToHex(c: number): string {
//         return c.toString(16).padStart(2, '0');
//     }

//     const lines = data.trim().split('\n');
//     const result = {};

//     for (let i = 2; i < lines.length; i++) {
//         // Start from line with A1
//         // Changed: split the line by tab instead of whitespace.
//         const line = lines[i]?.split('\t');
//         const shadeName = line?.[0] as TeethShade;
//         if (shadeName && line !== undefined && line.length > 19) {
//             result[shadeName] = {
//                 [ValidShadeTypeEnum.Gingival]: `#${componentToHex(+line[10])}${componentToHex(+line[11])}${componentToHex(+line[12])}`,
//                 [ValidShadeTypeEnum.Base]: `#${componentToHex(+line[13])}${componentToHex(+line[14])}${componentToHex(+line[15])}`,
//                 [ValidShadeTypeEnum.Incisal]: `#${componentToHex(+line[16])}${componentToHex(+line[17])}${componentToHex(+line[18])}`,
//             } as ShadeValue;

//         }
//     }

//     return result;
// }
// const parsedData = parseColorData(colorData);
// console.log(parsedData);

interface ShadeValue {
    [ValidShadeTypeEnum.Gingival]: string;
    [ValidShadeTypeEnum.Base]: string;
    [ValidShadeTypeEnum.Incisal]: string;
}

export const DEFAULT_CONTROL_POINTS = [0, 10, 18, 50, 67, 75, 100];

//parsed from above
export const SHADE_VALUES: Readonly<Partial<Record<TeethShade, ShadeValue>>> = {
    A2: {
        Gingival: '#f7f9e3',
        Base: '#f2f4df',
        Incisal: '#dbe5dc',
    },
    A3: {
        Gingival: '#fbfade',
        Base: '#f8f7d9',
        Incisal: '#e0eadc',
    },
    'A3,5': {
        Gingival: '#fdf8e2',
        Base: '#f4efd9',
        Incisal: '#d5dcd4',
    },
    A4: {
        Gingival: '#fbf3cb',
        Base: '#f2eac5',
        Incisal: '#d9dcc6',
    },
    B1: {
        Gingival: '#fffbe7',
        Base: '#fbf7e1',
        Incisal: '#e4e5dd',
    },
    B2: {
        Gingival: '#fcf4cf',
        Base: '#fbf3ce',
        Incisal: '#ebeace',
    },
    B3: {
        Gingival: '#fdf0bb',
        Base: '#feebb4',
        Incisal: '#ede5ba',
    },
    B4: {
        Gingival: '#fef1b7',
        Base: '#feeeb5',
        Incisal: '#efe6bf',
    },
    C1: {
        Gingival: '#f7eccc',
        Base: '#f5eccb',
        Incisal: '#e1e1cd',
    },
    C2: {
        Gingival: '#f8e3b9',
        Base: '#f5e6bb',
        Incisal: '#ded9c5',
    },
    C3: {
        Gingival: '#fde6b8',
        Base: '#f3ddae',
        Incisal: '#e1d4b3',
    },
    C4: {
        Gingival: '#f6d8a0',
        Base: '#ebd099',
        Incisal: '#dbcba9',
    },
    D2: {
        Gingival: '#fff2d1',
        Base: '#f7ecce',
        Incisal: '#eae5d2',
    },
    D3: {
        Gingival: '#fde3a9',
        Base: '#fae1ab',
        Incisal: '#e3d5b3',
    },
    D4: {
        Gingival: '#fae1a8',
        Base: '#edd69e',
        Incisal: '#d5c7a5',
    },
    '0M1': {
        Gingival: '#f5eacc',
        Base: '#fcf1d3',
        Incisal: '#fff5d7',
    },
    '0M2': {
        Gingival: '#f3e8c8',
        Base: '#fcedcc',
        Incisal: '#fef2d6',
    },
    '0M3': {
        Gingival: '#faefd1',
        Base: '#fef5d6',
        Incisal: '#fef7dd',
    },
    '1M1': {
        Gingival: '#fce9be',
        Base: '#feedbf',
        Incisal: '#feefce',
    },
    '1M2': {
        Gingival: '#fde5af',
        Base: '#ffe5b2',
        Incisal: '#f9ebc6',
    },
    '2L1.5': {
        Gingival: '#fae1a8',
        Base: '#fee6aa',
        Incisal: '#fdeec6',
    },
    '2L2.5': {
        Gingival: '#e1e3a3',
        Base: '#feeaa5',
        Incisal: '#fdebc1',
    },
    '2M1': {
        Gingival: '#f5e0b1',
        Base: '#fde8b7',
        Incisal: '#fef4cf',
    },
    '2M2': {
        Gingival: '#fef4cf',
        Base: '#ffe6aa',
        Incisal: '#fef3c7',
    },
    '2M3': {
        Gingival: '#fee198',
        Base: '#ffe89e',
        Incisal: '#fef2ba',
    },
    '2R1.5': {
        Gingival: '#f4d8a8',
        Base: '#fee4b1',
        Incisal: '#fbe7c5',
    },
    '2R2.5': {
        Gingival: '#fddb9b',
        Base: '#ffe19e',
        Incisal: '#fde6b8',
    },
    '3L1.5': {
        Gingival: '#fadfa8',
        Base: '#fde3a8',
        Incisal: '#feeec2',
    },
    '3L2.5': {
        Gingival: '#fedc97',
        Base: '#ffdf97',
        Incisal: '#feedbb',
    },
    '3M1': {
        Gingival: '#f5d9a9',
        Base: '#fee3ae',
        Incisal: '#ffefc8',
    },
    '3M2': {
        Gingival: '#fbd697',
        Base: '#feda97',
        Incisal: '#fde2ad',
    },
    '3M3': {
        Gingival: '#fad58f',
        Base: '#fed88f',
        Incisal: '#fffdb5',
    },
    '3R1.5': {
        Gingival: '#f5daac',
        Base: '#fedeac',
        Incisal: '#feedcb',
    },
    '3R2.5': {
        Gingival: '#f8d595',
        Base: '#fdd993',
        Incisal: '#feeab6',
    },
    '4L1.5': {
        Gingival: '#feeab6',
        Base: '#fddb9e',
        Incisal: '#fee6bb',
    },
    '4L2.5': {
        Gingival: '#fcd085',
        Base: '#fed589',
        Incisal: '#ffe5a8',
    },
    '4M1': {
        Gingival: '#f1d0a1',
        Base: '#fddca8',
        Incisal: '#ffeac3',
    },
    '4M2': {
        Gingival: '#fad493',
        Base: '#fed792',
        Incisal: '#fee3ac',
    },
    '4M3': {
        Gingival: '#feca7e',
        Base: '#ffd17c',
        Incisal: '#fee9a8',
    },
    '4R1.5': {
        Gingival: '#fcd59e',
        Base: '#ffd9a2',
        Incisal: '#feefc6',
    },
    '4R2.5': {
        Gingival: '#fccf8e',
        Base: '#fed18d',
        Incisal: '#fee3a7',
    },
    '5M1': {
        Gingival: '#ecc491',
        Base: '#fcd299',
        Incisal: '#fbdfb7',
    },
    '5M2': {
        Gingival: '#fdcd8d',
        Base: '#fecb85',
        Incisal: '#ffe7b0',
    },
    '5M3': {
        Gingival: '#fecf7e',
        Base: '#fec872',
        Incisal: '#fddea4',
    },
    BL1: {
        Gingival: '#e7eee6',
        Base: '#edf0e9',
        Incisal: '#ebece4',
    },
    BL2: {
        Gingival: '#e4e7dd',
        Base: '#ebeddf',
        Incisal: '#e8eadd',
    },
    BL3: {
        Gingival: '#e3e4d2',
        Base: '#ebead6',
        Incisal: '#eceadb',
    },
    BL4: {
        Gingival: '#dfdbc2',
        Base: '#e9e5ca',
        Incisal: '#ede8d5',
    },
};

// Updated pickShade to handle missing shades.
export function pickShade(shade: TeethShade): { color: string; type: ValidShadeTypeEnum }[] {
    const shadeEntry = SHADE_VALUES[shade];
    if (!shadeEntry) {
        console.warn(`Missing shade entry for ${shade}`);
        return [];
    }
    return [
        { color: shadeEntry[ValidShadeTypeEnum.Incisal], type: ValidShadeTypeEnum.Incisal },
        { color: shadeEntry[ValidShadeTypeEnum.Base], type: ValidShadeTypeEnum.Base },
        { color: shadeEntry[ValidShadeTypeEnum.Gingival], type: ValidShadeTypeEnum.Gingival },
    ];
}

import { useApolloClient, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import React from 'react';

const GetPaginatedOrganizations_Query = graphql(`
    query GetPaginatedOrganizations($page: Float!, $rows_per_page: Float!, $filter: GetPaginatedOrganizationsFilter) {
        getPaginatedOrganizations(page: $page, rows_per_page: $rows_per_page, filter: $filter) {
            organizations {
                id
                name
                created_at
                type
                ancestors {
                    id
                    name
                    created_at
                }
                practices_count
            }
            total
        }
    }
`);

export const useGetPaginatedOrganizations = (
    page: number,
    rows_per_page: number,
    filters: {
        name?: string;
        id?: string;
        include_ancestors?: boolean;
        include_hierarchy?: boolean;
        type?: LabsGqlOrganizationType[];
    },
) => {
    const { data, loading, error, refetch } = useQuery(GetPaginatedOrganizations_Query, {
        variables: {
            page,
            rows_per_page,
            filter: {
                name: filters.name,
                id: filters.id,
                include_ancestors: filters.include_ancestors ?? false,
                include_hierarchy: filters.include_hierarchy ?? false,
                type: filters.type,
            },
        },
    });

    const returnData = data?.getPaginatedOrganizations;

    return {
        organizations: returnData?.organizations,
        totalCount: returnData?.total,
        loading,
        error: error?.message,
        refetch,
    };
};

export const useGetPaginatedOrganizationsLazy = () => {
    const client = useApolloClient();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<Error | null>(null);
    const [data, setData] = React.useState<any>(null);

    const getOrganizations = React.useCallback(
        async (
            page: number,
            rows_per_page: number,
            filters: {
                name?: string;
                id?: string;
                include_ancestors?: boolean;
                include_hierarchy?: boolean;
                type?: LabsGqlOrganizationType[];
            },
        ) => {
            setLoading(true);
            /**
             * This _should_ be a lazy query, but we're encountering a bug where every network request gets cancelled instead.
             * This IS resolved newer versions of Apollo Client, but we're not able to upgrade until we fix useChangeSubmissionFn
             * Ticket to fix useChangeSubmissionFn: https://meetdandy.atlassian.net/browse/EPDPLT-4244
             * Related official apollo bug: https://github.com/apollographql/apollo-client/issues/6133
             */
            const { data, error } = await client.query({
                query: GetPaginatedOrganizations_Query,
                variables: {
                    page,
                    rows_per_page,
                    filter: {
                        name: filters.name,
                        id: filters.id,
                        include_ancestors: filters.include_ancestors ?? false,
                        include_hierarchy: filters.include_hierarchy ?? false,
                        type: filters.type,
                    },
                },
                fetchPolicy: 'network-only',
            });
            if (error) {
                setError(error);
            }
            setLoading(false);
            setData(data?.getPaginatedOrganizations);
        },
        [client],
    );

    return {
        loading,
        error: error?.message,
        data,
        getOrganizations,
    };
};

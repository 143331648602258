import { OrderDetailPageFrame } from '../../../OrderDetailPageFrame.graphql';
import { useOrderDetailContext } from '../../../state/OrderDetailProvider.graphql';
import { OrderDesignToolbarFileDownload } from '../../OrderDesignToolbar/OrderDesignToolbarFileDownload.graphql';
import { BodyContainer, ToolbarActionsContainer } from './common';
import { useApolloClient } from '@apollo/client';
import { getDandyFinishingDisallowedReason } from '@orthly/forceps';
import { graphql } from '@orthly/graphql-inline-react';
import { type LabsGqlFinishingInBrowserPayload } from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { useQueryParam, styled } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import {
    OrderDesignRevisions,
    useDesignOrderRevisionsLoader,
    DesignFinishingStartBannerInternal,
    useFeatureFlag,
} from '@orthly/veneer';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const SubmitAutomateReview_Mutation = graphql(`
    mutation SubmitAutomateReview($data: SubmitAutomateReviewCommand!) {
        submitAutomateReview(data: $data) {
            id
        }
    }
`);

const SubmitFinishingInBrowser_Mutation = graphql(`
    mutation SubmitFinishingInBrowser($payload: FinishingInBrowserPayload!) {
        submitFinishingInBrowser(payload: $payload)
    }
`);

function useSubmitCallbacks(orderId: string) {
    const client = useApolloClient();

    const submitReviewDecision = async (args: { approved: boolean; reviewDurationMs: number }) => {
        await client.mutate({
            mutation: SubmitAutomateReview_Mutation,
            variables: {
                data: { order_id: orderId, approved: args.approved, review_duration_ms: args.reviewDurationMs },
            },
        });
    };

    const submitFinishedDesign = async (args: { payload: LabsGqlFinishingInBrowserPayload }) => {
        await client.mutate({
            mutation: SubmitFinishingInBrowser_Mutation,
            variables: { payload: args.payload },
        });
    };

    return {
        submitReviewDecision,
        submitFinishedDesign,
    };
}

const StartBannerContainer = styled(Grid)(() => ({
    padding: 16,
    width: 360,
}));

export const OrderDetailFinishingTabPage: React.FC = () => {
    const { value: disableFinishingBannerGating } = useFeatureFlag('disableFinishingBannerGating');
    const { value: disableDandyFinishingArgenMaterials } = useFeatureFlag('disableDandyFinishingArgenMaterials');
    const { value: disableDandyFinishingDistortedMargins } = useFeatureFlag('disableDandyFinishingDistortedMargins');

    const { order, id: orderId } = useOrderDetailContext();

    const {
        slimDesignFragments,
        selectedDesignFragment: selectedDesign,
        loadAndSelectDesign,
    } = useDesignOrderRevisionsLoader(orderId);

    const disabledReason = disableFinishingBannerGating
        ? null
        : getDandyFinishingDisallowedReason(
              selectedDesign?.id,
              slimDesignFragments,
              OrderItemV2Utils.parseItems(order?.items_v2 ?? []),
              {
                  disallowArgen: !!disableDandyFinishingArgenMaterials,
                  disallowDistortedMargins: !!disableDandyFinishingDistortedMargins,
              },
          );

    const [openVal, setOpenQueryParam] = useQueryParam('open');
    const initiallyOpen = openVal === 'true';

    const submitCallbacks = useSubmitCallbacks(orderId);
    const onCancel = () => {
        // Clear the "open" param so that `initiallyOpen` is false if the UI is opened again.
        setOpenQueryParam(undefined);
    };

    const navigate = useNavigate();

    const onComplete = () => {
        // Clear the "open" param so that the UI is not automatically opened if the user navigates back to this page.
        setOpenQueryParam(undefined);
        navigate(`/orders/${orderId}`);
    };

    return (
        <OrderDetailPageFrame
            body={
                order && (
                    <BodyContainer container>
                        <StartBannerContainer item>
                            {selectedDesign ? (
                                <DesignFinishingStartBannerInternal
                                    order={order}
                                    design={selectedDesign}
                                    disabled={!!disabledReason}
                                    initiallyOpen={initiallyOpen}
                                    callbacks={{ ...submitCallbacks, onComplete, onCancel }}
                                />
                            ) : (
                                <div>This order does not have any designs.</div>
                            )}
                        </StartBannerContainer>
                    </BodyContainer>
                )
            }
            sidebar={null}
            toolbarActions={
                <ToolbarActionsContainer container>
                    {order && selectedDesign && (
                        <OrderDesignToolbarFileDownload
                            order={order}
                            selectedDesignFragment={selectedDesign}
                            isDesignMostRecent={selectedDesign.source_file_zip_path === order.design_file_path}
                        />
                    )}
                    {order && (
                        <OrderDesignRevisions
                            order={order}
                            userRole={'psr'}
                            setSelectedDesignRevisionId={loadAndSelectDesign}
                            designRevisionFragments={slimDesignFragments}
                            selectedDesignRevisionId={selectedDesign?.id}
                        />
                    )}
                </ToolbarActionsContainer>
            }
        />
    );
};

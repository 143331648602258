import { store } from '../store/store';
import type { Atom } from 'jotai';
import { Observable } from 'rxjs';

export function atomToObservable<T>(atom: Atom<T>): Observable<T> {
    return new Observable<T>(subscriber => {
        // Emit the current value
        subscriber.next(store.get(atom));

        // Subscribe to future changes
        const unsubscribe = store.sub(atom, () => {
            subscriber.next(store.get(atom));
        });

        // Clean up when unsubscribed
        return () => unsubscribe();
    });
}

import { FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';
import type { GetRailProps, GetHandleProps, GetTrackProps, SliderItem } from 'react-compound-slider';

// *******************************************************
// RAIL
// *******************************************************
const RailOuter = styled('div')({
    position: 'absolute',
    height: '100%',
    width: 42,
    transform: 'translate(-50%, 0%)',
    borderRadius: 7,
    cursor: 'pointer',
});

const RailInner = styled('div')({
    position: 'absolute',
    height: '100%',
    width: 4,
    transform: 'translate(-50%, 0%)',
    borderRadius: 7,
    pointerEvents: 'none',
    backgroundColor: `${FlossPalette.LIGHT_GRAY}55`,
});

interface SliderRailProps {
    getRailProps: GetRailProps;
}

export const SliderRail: React.FC<SliderRailProps> = ({ getRailProps }) => {
    return (
        <>
            <RailOuter {...getRailProps()} />
            <RailInner />
        </>
    );
};

// *******************************************************
// HANDLE COMPONENT
// *******************************************************

const HandleVisualization = styled('div')<{
    percent: number;
}>(({ percent }) => ({
    top: `${percent}%`,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    width: 18,
    height: 8,
    borderRadius: '4px',
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
    backgroundColor: FlossPalette.STAR_GRASS,
    '&:hover': {
        backgroundColor: FlossPalette.STAR_GRASS_HOVER,
    },
}));

const HandleWrapper = styled('div')<{
    percent: number;
}>(({ percent }) => ({
    top: `${percent}%`,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    zIndex: 5,
    width: 42,
    height: 28,
    cursor: 'pointer',
    backgroundColor: 'none',
}));

interface HandleProps {
    domain: number[];
    handle: SliderItem;
    getHandleProps: GetHandleProps;
}

export const Handle: React.FC<HandleProps> = ({
    domain: [min, max],
    handle: { id, value, percent },
    getHandleProps,
}) => {
    return (
        <>
            <HandleWrapper percent={percent} {...getHandleProps(id)} />
            <HandleVisualization
                role={'slider'}
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                percent={percent}
            />
        </>
    );
};

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************
const KeyboardHandleButton = styled('button')<{
    percent: number;
}>(({ percent }) => ({
    top: `${percent}%`,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    width: 24,
    height: 24,
    zIndex: 5,
    cursor: 'pointer',
    border: 0,
    borderRadius: '50%',
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
    backgroundColor: FlossPalette.TAN,
}));

export const KeyboardHandle: React.FC<HandleProps> = ({
    domain: [min, max],
    handle: { id, value, percent },
    getHandleProps,
}) => {
    return (
        <KeyboardHandleButton
            role={'slider'}
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            percent={percent}
            {...getHandleProps(id)}
        />
    );
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
interface TrackProps {
    source: SliderItem;
    target: SliderItem;
    getTrackProps: GetTrackProps;
    disabled?: boolean;
}

const TrackDiv = styled('div')<{
    top: number;
    height: number;
}>(({ top, height }) => ({
    position: 'absolute',
    zIndex: 1,
    backgroundColor: FlossPalette.LIGHT_GRAY,
    borderRadius: 7,
    width: 6,
    transform: 'translate(-50%, 0%)',
    top: `${top}%`,
    height: `${height}%`,
}));

export const Track: React.FC<TrackProps> = ({ source, target, getTrackProps }) => {
    return <TrackDiv top={source.percent} height={target.percent - source.percent} {...getTrackProps()} />;
};

// *******************************************************
// TICK COMPONENT
// *******************************************************
interface TickProps {
    tick: SliderItem;
    format?: (val: number) => string;
}

const TickLine = styled('div')<{
    percent: number;
}>(({ percent }) => ({
    position: 'absolute',
    marginTop: -0.5,
    marginLeft: 10,
    height: 1,
    width: 6,
    backgroundColor: 'rgb(200,200,200)',
    top: `${percent}%`,
}));

const TickLabel = styled('div')<{
    percent: number;
}>(({ percent }) => ({
    position: 'absolute',
    marginTop: -5,
    marginLeft: 20,
    fontSize: 10,
    top: `${percent}%`,
}));

export const Tick: React.FC<TickProps> = ({ tick, format = d => d }) => {
    return (
        <div>
            <TickLine percent={tick.percent} />
            <TickLabel percent={tick.percent}>{format(tick.value)}</TickLabel>
        </div>
    );
};

import { LabOrderItemConditionField } from '../../../../components/LabOrderItemConditionEditor';
import { RoutingRuleUtils } from '../../../../components/RoutingRule.util';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlUpdateCapacityStationRuleArgs } from '@orthly/graphql-schema';
import { LoadBlocker, QuickForm } from '@orthly/ui';
import React from 'react';

const CapacityStationRuleFormListLabtracProductCodes_Query = graphql(`
    query CapacityStationRuleFormListLabtracProductCodes_Query {
        listLabtracProductCodes
    }
`);

type EditCapacityStationRuleFormFields = Pick<
    LabsGqlUpdateCapacityStationRuleArgs,
    'name' | 'lab_product_codes' | 'matching_logic_json'
>;

interface CapacityStationRuleFormProps {
    onSubmit: (result: EditCapacityStationRuleFormFields) => void;
    initialValues: EditCapacityStationRuleFormFields;
}

export const EditCapacityStationRuleForm: React.VFC<CapacityStationRuleFormProps> = ({ onSubmit, initialValues }) => {
    const { data: labtracProductCodes, loading: labtracProductCodesLoading } = useQuery(
        CapacityStationRuleFormListLabtracProductCodes_Query,
    );

    return (
        <LoadBlocker blocking={labtracProductCodesLoading}>
            <QuickForm<EditCapacityStationRuleFormFields>
                onSubmit={onSubmit}
                initialValues={initialValues}
                fields={{
                    name: {
                        label: 'Name',
                        type: 'text',
                    },
                    matching_logic_json: {
                        label: 'Condition',
                        type: 'custom',
                        component: LabOrderItemConditionField,
                        validation: RoutingRuleUtils.ItemConditionValidation,
                        optional: true,
                    },
                    lab_product_codes: {
                        label: 'Labtrac Product Codes',
                        type: 'multiselect',
                        options: labtracProductCodes?.listLabtracProductCodes ?? [],
                        optional: true,
                    },
                }}
            />
        </LoadBlocker>
    );
};

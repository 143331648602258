import { useUsersAction } from './state/Users.actions';
import { SessionGuard, SessionGuardUtils } from '@orthly/session-client';
import { Button } from '@orthly/ui-primitives';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

export const AddUser: React.FC = () => {
    const createUser = useUsersAction('START_EDITING');
    const navigate = useNavigate();

    return (
        <SessionGuard
            test={SessionGuardUtils.hasRole(['admin', 'internal__accountManager', 'internal__customerExperience'])}
            fallback={null}
        >
            <Button
                variant={'primary'}
                startIcon={'AddIcon'}
                onClick={() => {
                    navigate('/users/new');
                    createUser({ user: undefined });
                }}
            >
                New
            </Button>
        </SessionGuard>
    );
};

import { DesignSidebarSection } from './DesignDetailSidebarSection';
import { Button } from '@orthly/ui-primitives';
import { atom, useAtom } from 'jotai';
import React from 'react';

export const designComparisonOpenAtom = atom(false);

export const DesignComparisonSection: React.FC = () => {
    const [designComparisonOpen, setDesignComparisonOpen] = useAtom(designComparisonOpenAtom);
    const text = designComparisonOpen ? 'Close Design Comparison' : 'Open Design Comparison';
    return (
        <DesignSidebarSection title={'Digital Design'}>
            <Button
                variant={'secondary'}
                analytics={{
                    AssetLocation: 'Ops - Order Detail - Design Tab',
                    AssetName: text,
                }}
                onClick={() => setDesignComparisonOpen(!designComparisonOpen)}
            >
                {text}
            </Button>
        </DesignSidebarSection>
    );
};

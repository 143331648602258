import type { OrderDesignFileStatus } from './ThreeshapePlugin.types';
import {
    getSubdirectoryForTask,
    useRecursiveTimeout,
    logThreeshapePluginOperation,
    getAssignedDesignTasks,
} from './ThreeshapePluginUtils';
import type { ApolloClient } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type {
    LabsGqlGenerateEmptyDesignFileMutation,
    LabsGqlGenerateEmptyDesignFileMutationVariables,
} from '@orthly/graphql-operations';
import { GenerateEmptyDesignFileDocument } from '@orthly/graphql-react';
import { LabsGqlThreeshapePluginOperationTypeInput } from '@orthly/graphql-schema';
import { useRetainerToken } from '@orthly/session-client';
import { DesignTaskType } from '@orthly/shared-types';
import { getFirebaseDownloadUrl, useFirebaseStorage, OrderDownloadFilesUtils } from '@orthly/veneer';
import type Firebase from 'firebase/compat/app';
import _ from 'lodash';

const LabOrderScan_Query = graphql(`
    query GetLabOrderScanId($id: String!) {
        lab_order(id: $id) {
            scan_export_id
            order_number
        }
    }
`);

const LatestDesignRevisionArtifactPath_Query = graphql(`
    query GetLatestDesignOrderDesignRevisionArtifactPath($designOrderId: String!) {
        getLatestDesignOrderDesignRevision(labOrderId: $designOrderId) {
            source_file_zip_path
        }
    }
`);

/**
 * Attempts case file generation for an order, and returns a valid path if successful
 */
async function getGeneratedDesignFileFirebasePath(
    client: ApolloClient<any>,
    orderId: string,
): Promise<string | undefined> {
    const generatedDesignFileRes = await client.mutate<
        LabsGqlGenerateEmptyDesignFileMutation,
        LabsGqlGenerateEmptyDesignFileMutationVariables
    >({
        mutation: GenerateEmptyDesignFileDocument,
        variables: {
            order_id: orderId,
        },
    });
    return generatedDesignFileRes.data?.generateEmptyDesignFile;
}

/**
 * Retrieves the firebase path to the most recent design file associated with the order
 * @returns {Promise<string | undefined>} The firebase path to the design file or undefined if no design file exists
 */
async function getLatestDesignFileFirebasePath(client: ApolloClient<any>, orderId: string) {
    const designArtifactResult = await client.query({
        query: LatestDesignRevisionArtifactPath_Query,
        variables: {
            designOrderId: orderId,
        },
    });
    return designArtifactResult.data.getLatestDesignOrderDesignRevision?.source_file_zip_path;
}

/**
 * Retrieves the firebase path to the most recent design file associated with the order, or attempts to generate a new design file if none exists
 * @returns {Promise<string | undefined>} The firebase path to the design file or undefined if the design file does not exist, and we were unable to generate a new design file
 */
async function getDesignFileFirebasePath(client: ApolloClient<any>, orderId: string): Promise<string | undefined> {
    const existingDesignFilePath = await getLatestDesignFileFirebasePath(client, orderId);
    if (existingDesignFilePath) {
        return existingDesignFilePath;
    }
    // otherwise we attempt to generate a new design file
    return getGeneratedDesignFileFirebasePath(client, orderId);
}

async function downloadFile(downloadUrl: string, directoryHandle: FileSystemDirectoryHandle, filename: string) {
    const res = await fetch(downloadUrl);
    if (!res.ok) {
        throw new Error(`Failed to download design file from ${downloadUrl} ${res.status} ${res.statusText}`);
    }
    const blob = await res.blob();
    const newFile = await directoryHandle.getFileHandle(filename, { create: true });
    const writeable = await newFile.createWritable();
    await writeable.write(blob);
    await writeable.close();
}

async function downloadScanFile(
    client: ApolloClient<any>,
    orderId: string,
    orderDir: FileSystemDirectoryHandle,
    retainerToken: string,
): Promise<string | null> {
    const scanQueryResult = await client.query({
        query: LabOrderScan_Query,
        variables: {
            id: orderId,
        },
    });
    const scanData = scanQueryResult.data.lab_order;
    const scanId = scanData.scan_export_id;
    const sanitizedScanURL = OrderDownloadFilesUtils.getSanitizedScanUrl({
        retainerToken,
        orderId: orderId,
        scanExportId: scanId,
    });
    const filename = `Order ${scanData.order_number}.3oxz`;
    await downloadFile(sanitizedScanURL, orderDir, filename);
    return filename;
}

/**
 *
 * @param {ApolloClient<any>} client
 * @param {firebase.storage.Storage} firebase
 * @param {string} orderId
 * @param {FileSystemDirectoryHandle} orderDir
 * @returns {Promise<string | null>}
 */
async function downloadDesignFile(
    client: ApolloClient<any>,
    firebase: Firebase.storage.Storage,
    orderId: string,
    orderDir: FileSystemDirectoryHandle,
): Promise<string | null> {
    try {
        const existingFileName = await getExistingDesignArtifactPath(orderDir);
        if (existingFileName) {
            // nothing to do
            // Note: This will likely get too noisy and will be turned off before wider rollout
            await logThreeshapePluginOperation(
                client,
                orderId,
                DesignTaskType.InternalDesign,
                LabsGqlThreeshapePluginOperationTypeInput.DownloadSkipped,
            );
            return existingFileName;
        }

        await logThreeshapePluginOperation(
            client,
            orderId,
            DesignTaskType.InternalDesign,
            LabsGqlThreeshapePluginOperationTypeInput.DownloadStarting,
        );

        const designFilePath = await getDesignFileFirebasePath(client, orderId);
        if (!designFilePath) {
            return null;
        }
        // parse the filename for local storage
        const filename = _.last(designFilePath.split('/'));

        if (!filename) {
            return null;
        }

        const downloadUrl = await getFirebaseDownloadUrl(firebase, designFilePath);
        await downloadFile(downloadUrl, orderDir, filename);
        await logThreeshapePluginOperation(
            client,
            orderId,
            DesignTaskType.InternalDesign,
            LabsGqlThreeshapePluginOperationTypeInput.DownloadComplete,
        );
        return filename;
    } catch (e) {
        console.error(`Unable to download a design file: ${e}`);
        await logThreeshapePluginOperation(
            client,
            orderId,
            DesignTaskType.InternalDesign,
            LabsGqlThreeshapePluginOperationTypeInput.DownloadFailed,
            e as Error,
        );
        return null;
    }
}

/**
 *
 * @param {ApolloClient<any>} client
 * @param {firebase.storage.Storage} firebase
 * @param {FileSystemDirectoryHandle} downloadsRootDirHandle
 * @param {string} orderId
 * @param {DesignTaskType} activeDesignTask
 * @param {string} retainerToken
 * @returns {Promise<string | null>} The filename of the downloaded file
 * or null if we were unable to download the file or verify the existence of the file on disk
 */
async function downloadDesignArtifact(
    client: ApolloClient<any>,
    firebase: Firebase.storage.Storage,
    downloadsRootDirHandle: FileSystemDirectoryHandle,
    orderId: string,
    activeDesignTask: DesignTaskType,
    retainerToken: string,
): Promise<string | null> {
    // we use the orderId as the directory name
    const orderDirHandle = await getDownloadDir(orderId, activeDesignTask, downloadsRootDirHandle);

    if (activeDesignTask !== DesignTaskType.DesignPrep) {
        // we attempt to download a design file
        const designFile = await downloadDesignFile(client, firebase, orderId, orderDirHandle);
        if (designFile) {
            return designFile;
        }
    }

    // otherwise we fall back to downloading the scan, because this could be an unprepped case
    return downloadScanFile(client, orderId, orderDirHandle, retainerToken);
}

/**
 * Retrieves all assigned design tasks for the user and downloads the associated design files
 * @param {ApolloClient<any>} client
 * @param retainerToken The retainer token (required for zipstream calls to retrieve the latest scan)
 * @param {firebase.storage.Storage} firebase
 * @param {FileSystemDirectoryHandle} downloadsRootDir
 * @param updateDesignFileStatus
 * @returns {Promise<void>}
 */
async function downloadDesignFiles(
    client: ApolloClient<any>,
    retainerToken: string,
    firebase: Firebase.storage.Storage,
    downloadsRootDir: FileSystemDirectoryHandle,
    updateDesignFileStatus: (updatedOrders: Record<string, OrderDesignFileStatus>) => void,
) {
    const assignedDesignTasks = await getAssignedDesignTasks(client);

    const latestOrderStatus: Record<string, OrderDesignFileStatus> = {};

    for (const task of assignedDesignTasks) {
        const orderId = task.orderId;
        const activeDesignTask = task.taskType;
        try {
            const filename = await downloadDesignArtifact(
                client,
                firebase,
                downloadsRootDir,
                orderId,
                activeDesignTask,
                retainerToken,
            );
            latestOrderStatus[orderId] = {
                taskType: activeDesignTask,
                currentAction: 'Download',
                success: !!filename,
            };
        } catch (error) {
            console.error(error);
            latestOrderStatus[orderId] = {
                taskType: activeDesignTask,
                currentAction: 'Download',
                success: false,
            };
        }
    }
    updateDesignFileStatus(latestOrderStatus);
}

/**
 * Checks for the presence of a design file or 3oxz in the downloads directory
 * @param orderDir The directory to check for the design artifact
 * @returns The name of the existing design artifact, if it could be found
 */
async function getExistingDesignArtifactPath(orderDir: FileSystemDirectoryHandle): Promise<string | null> {
    // directory entries
    for await (const file of orderDir.values()) {
        if (file.kind === 'file' && (file.name.endsWith('.zip') || file.name.endsWith('.3oxz'))) {
            return file.name;
        }
    }
    return null;
}

/**
 * Returns the handle to a directory to persist the design file for the design task
 */
async function getDownloadDir(
    orderId: string,
    designTaskType: DesignTaskType,
    downloadsDirHandle: FileSystemDirectoryHandle,
): Promise<FileSystemDirectoryHandle> {
    const activeTaskDirectory = await getSubdirectoryForTask(downloadsDirHandle, designTaskType);
    // we just use the orderId as the directory name
    const orderDir = await activeTaskDirectory.getDirectoryHandle(orderId, { create: true });
    return orderDir;
}

export const DesignFileDownloadPlugin: React.FC<{
    directoryHandle: FileSystemDirectoryHandle;
    updateDesignFileStatus: (updatedOrders: Record<string, OrderDesignFileStatus>) => void;
}> = ({ directoryHandle, updateDesignFileStatus }) => {
    const firebase = useFirebaseStorage();
    const apolloClient = useApolloClient();
    const { retainerToken } = useRetainerToken();
    useRecursiveTimeout(async () => {
        if (!retainerToken) {
            return;
        }
        await downloadDesignFiles(apolloClient, retainerToken, firebase, directoryHandle, updateDesignFileStatus);
    }, 30000);

    return null;
};

import { useRowsFromCsv } from '../../../Pricing/useCsvImport';
import type { BillingCreditCategories } from '../../CreditCategories/BillingCreditCategoriesQuery.graphql';
import { GetAllBillingCreditCategories_Query } from '../../CreditCategories/BillingCreditCategoriesQuery.graphql';
import { getOrderTotalAmountDue } from '../../OrderPricing.utils';
import type { OrderPrices } from '../../types';
import type { CreditImportCsvRow, CreditImportCsvRowRaw, EditableKey, ErrorRow, FinalizedImportRow } from './types';
import { useMutation, useQuery } from '@apollo/client';
import type {
    CreateAttributedInvoiceCreditsMutationVariables,
    CreateInvoiceCreditMutationVariables,
} from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { LabsGqlInvoiceAdjustmentAttributionType } from '@orthly/graphql-schema';
import { UuidUtils } from '@orthly/runtime-utils';
import { dayjsExt as dayjs } from '@orthly/runtime-utils';
import { apolloErrorMessage, useChangeSubmissionFn } from '@orthly/ui';
import { useFeatureFlag } from '@orthly/veneer';
import type { Dictionary } from 'lodash';
import { chunk, keyBy, omit, partition } from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';

const ListPracticeNames_Query = graphql(`
    query ListPracticeNames {
        listPracticeNames {
            name
            id
        }
    }
`);

const CreateInvoiceCredit_Mutation = graphql(`
    mutation CreateInvoiceCredit($data: CreateInvoiceCreditInput!) {
        createInvoiceCredit(data: $data) {
            id
        }
    }
`);

const CreateAttributedInvoiceCredits_Mutation = graphql(`
    mutation CreateAttributedInvoiceCredits($data: CreateAttributedCreditsInput!) {
        createAttributedCredits(data: $data) {
            id
        }
    }
`);

export function useRowsWithPartnerName<R extends { practice_id: string }>(rows: R[]): (R & { name: string })[] {
    const { data: { listPracticeNames: practices } = {} } = useQuery(ListPracticeNames_Query);

    return React.useMemo(() => {
        const practicesById = keyBy(practices ?? [], p => p.id);
        return rows.map<R & { name: string }>(row => ({
            name: practicesById[row.practice_id]?.name ?? 'Failed to load practice name',
            ...row,
        }));
    }, [practices, rows]);
}

export function useBulkCreateCredits(onCompletePractice: (id: string) => void) {
    const [submitInvoiceCreditMtn] = useMutation(CreateInvoiceCredit_Mutation);
    const [submitAttributedInvoiceCreditMtn] = useMutation(CreateAttributedInvoiceCredits_Mutation);

    const { enqueueSnackbar } = useSnackbar();
    const [erroredRows, setErroredRows] = React.useState<ErrorRow[]>([]);

    const createCreditsBulk = React.useCallback(
        async (inputRows: FinalizedImportRow[]) => {
            setErroredRows([]);
            const errorRows: ErrorRow[] = [];
            for (const chunkedRows of chunk<FinalizedImportRow>(inputRows, 20)) {
                const results = await Promise.all(
                    chunkedRows.map<Promise<boolean>>(async row => {
                        const {
                            row_id,
                            amount_cents,
                            practice_id: partner_id,
                            expiration_date: expires,
                            description,
                            credit_category,
                            invoice_to_apply_date,
                            order_id,
                        } = row;

                        try {
                            let result: any = null;
                            if (order_id) {
                                const variables: CreateAttributedInvoiceCreditsMutationVariables = {
                                    data: {
                                        description,
                                        expires,
                                        invoice_to_apply_date,
                                        credit_category_id: credit_category,
                                        organization_id: partner_id,
                                        order_attributions: [
                                            {
                                                type: LabsGqlInvoiceAdjustmentAttributionType.Order,
                                                order_id,
                                                amount_cents,
                                            },
                                        ],
                                        order_item_attributions: [],
                                        invoice_item_attributions: [],
                                    },
                                };
                                result = await submitAttributedInvoiceCreditMtn({ variables });
                            } else {
                                const variables: CreateInvoiceCreditMutationVariables = {
                                    data: {
                                        description,
                                        expires,
                                        invoice_to_apply_date,
                                        amount_issued_cents: amount_cents,
                                        credit_category_id: credit_category,
                                        organization_id: partner_id,
                                    },
                                };
                                result = await submitInvoiceCreditMtn({ variables });
                            }
                            const error = result.errors?.[0];
                            if (error) {
                                throw error;
                            }
                            onCompletePractice(row_id);
                            return true;
                        } catch (e: any) {
                            const errorMsg = `${apolloErrorMessage(e)}. Practice ID: ${partner_id}${order_id ? `, Order ID: ${order_id}` : ''}`;
                            errorRows.push({ ...row, error: errorMsg });
                            return false;
                        }
                    }),
                );
                const [successes, errors] = partition(results, r => r);
                enqueueSnackbar(`${successes.length} credits created. ${errors.length} failures`, {
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                });
            }
            setErroredRows(errorRows);
            if (errorRows.length > 0) {
                throw new Error(`Failed to import ${errorRows.length} credits`);
            }
        },
        [enqueueSnackbar, onCompletePractice, submitInvoiceCreditMtn, submitAttributedInvoiceCreditMtn],
    );

    return { createCreditsBulk, erroredRows, setErroredRows };
}

export function useSubmitCredits(
    inputRows: CreditImportCsvRow[],
    onCompletePractice: (id: string) => void,
    ordersById: Dictionary<LabsGqlLabOrderFragment>,
    orderPricesByOrderId: Dictionary<OrderPrices>,
) {
    const { value: enableReadFromOrderPriceEntity } = useFeatureFlag('enableReadFromOrderPriceEntity');
    const { createCreditsBulk, erroredRows, setErroredRows } = useBulkCreateCredits(onCompletePractice);
    const { submit, submitting } = useChangeSubmissionFn<any, [FinalizedImportRow[]]>(createCreditsBulk, {
        successMessage: () => ['Credits created!', {}],
    });

    const { finalRows, dataError, anyMissingCategory } = React.useMemo(() => {
        type SubmitState = { error?: string; anyMissingCategory: boolean; finalRows: FinalizedImportRow[] };
        const { finalRows, error, anyMissingCategory } = inputRows.reduce<SubmitState>(
            (submitState, row) => {
                const order = ordersById[row.order_id ?? ''];
                const orderTotalAmountDue = getOrderTotalAmountDue({
                    order,
                    orderPrices: orderPricesByOrderId[row.order_id ?? ''] ?? [],
                    enableReadFromOrderPriceEntity,
                });
                const creditCategory = row.credit_category;
                if (!creditCategory) {
                    return {
                        ...submitState,
                        anyMissingCategory: true,
                        error: submitState.error ?? 'Row missing category',
                    };
                }
                if (order && row.practice_id !== order.partner_id) {
                    return {
                        ...submitState,
                        error: submitState.error ?? `Order ID doesn't exist for practice`,
                    };
                }
                if (order && row.amount_cents > orderTotalAmountDue) {
                    return {
                        ...submitState,
                        error: submitState.error ?? 'Credit must be <= order amount due',
                    };
                }
                if (row.amount_cents <= 0) {
                    return { ...submitState, error: submitState.error ?? 'Row with value < $0' };
                }
                return {
                    ...submitState,
                    finalRows: [...submitState.finalRows, { ...row, credit_category: creditCategory }],
                };
            },
            { finalRows: [], anyMissingCategory: false },
        );
        return { anyMissingCategory, finalRows, dataError: error };
    }, [inputRows, ordersById, enableReadFromOrderPriceEntity, orderPricesByOrderId]);

    React.useEffect(() => {
        if (finalRows.length !== erroredRows.length) {
            const finalRowIds = finalRows.map(r => r.practice_id);
            setErroredRows(current => current.filter(r => finalRowIds.includes(r.practice_id)));
        }
    }, [erroredRows.length, finalRows, setErroredRows]);

    const onSubmit = React.useCallback(() => {
        if (!dataError && window.confirm(`Create ${finalRows.length} billing credits?`)) {
            submit(finalRows).catch(console.error);
        }
    }, [dataError, finalRows, submit]);

    return { onSubmit, dataError, anyMissingCategory, submitting, submissionErrors: erroredRows };
}

export function useImportInputRowsState() {
    const { data: { getAllBillingCreditCategories } = {}, loading: creditCategoriesLoading } = useQuery<{
        getAllBillingCreditCategories: BillingCreditCategories;
    }>(GetAllBillingCreditCategories_Query, { variables: { include_archived: false } });
    const [inputRows, setInputRows] = React.useState<Record<string, CreditImportCsvRow>>({});

    const parseCreditCategory = (categoryString: string | undefined) => {
        if (!categoryString) {
            return undefined;
        }
        if (UuidUtils.isUUID(categoryString)) {
            return categoryString;
        }
        const foundByName = getAllBillingCreditCategories?.find(o => o.name === categoryString);
        return foundByName?.id ?? undefined;
    };

    const onDropAccepted = useRowsFromCsv<CreditImportCsvRowRaw>({
        checkColumns: columns => {
            const invalid = !columns.includes('practice_id') || !columns.includes('amount_cents');
            invalid && window.alert('Columns must be named practice_id and amount_cents!');
            return !invalid;
        },
        onValid: (rows: CreditImportCsvRowRaw[]) => {
            const validRows = rows.flatMap<CreditImportCsvRow>((row, index) => {
                if (!row.amount_cents || isNaN(parseInt(row.amount_cents)) || !row.practice_id) {
                    return [];
                }

                const invoice_to_apply_date =
                    row.invoice_to_apply_date && dayjs(row.invoice_to_apply_date).isValid()
                        ? dayjs(row.invoice_to_apply_date).toJSON()
                        : null;

                const expiration_date =
                    row.expiration_date && dayjs(row.expiration_date).isValid()
                        ? dayjs(row.expiration_date).toJSON()
                        : null;

                // convert the credit category that was manually entered in the csv
                // to the appropriate category id (if applicable)
                const parsedCreditCategoryId = parseCreditCategory(row.credit_category);

                return {
                    row_id: index.toString(),
                    expiration_date,
                    invoice_to_apply_date,
                    order_id: row.order_id ?? null,
                    amount_cents: parseInt(row.amount_cents),
                    practice_id: row.practice_id,
                    description: row.description ?? null,
                    credit_category: parsedCreditCategoryId,
                };
            });
            setInputRows(keyBy(validRows, r => r.row_id));
        },
    });

    const onPracticesSelected = React.useCallback((partnerIds: string[]) => {
        const rows = partnerIds.map<CreditImportCsvRow>((practice_id, index) => ({
            row_id: index.toString(),
            invoice_to_apply_date: null,
            practice_id,
            order_id: null,
            amount_cents: 0,
            description: null,
            expiration_date: null,
        }));
        setInputRows(keyBy(rows, r => r.row_id));
    }, []);

    return {
        inputRows,
        setInputRows,
        onDropAccepted,
        onPracticesSelected,
        creditCategories: getAllBillingCreditCategories ?? [],
        creditCategoriesLoading,
    };
}

export function useImportBillingCreditsState() {
    const { inputRows, setInputRows, onDropAccepted, onPracticesSelected, creditCategories, creditCategoriesLoading } =
        useImportInputRowsState();

    const onEditRow = React.useCallback(
        <K extends EditableKey>(rowId: string, key: K, value: CreditImportCsvRow[K]) => {
            setInputRows(current => {
                const currentRow = current[rowId];
                // fix empty strings
                const newValue = key === 'description' && !value ? null : value;
                return currentRow ? { ...current, [rowId]: { ...currentRow, [key]: newValue } } : current;
            });
        },
        [setInputRows],
    );

    const onRemoveRow = React.useCallback(
        (index: string) => {
            setInputRows(current => omit(current, index));
        },
        [setInputRows],
    );

    const resetRows = React.useCallback(() => setInputRows({}), [setInputRows]);

    return {
        inputRows,
        resetRows,
        onDropAccepted,
        onEditRow,
        onRemoveRow,
        onPracticesSelected,
        creditCategories,
        creditCategoriesLoading,
    };
}

import { isGuidedPreset } from '../GuidedWaxup.util';
import { GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT } from '../VisualConstants.util';
import { GuidedPresetControls, RevisionHistoryTabs } from '../components';
import { CurrentWaxupModelViewerAndControls } from '../components/CurrentWaxupModelViewerControls';
import { GuidedWaxupTimelineAndImagesSidebar } from '../components/GuidedWaxupTimelineAndImagesSidebar';
import { IncompletedWorkReminderModal } from '../components/IncompletedWorkReminderModal';
import { PresetTabsSidebar } from '../components/PresetTabSidebar';
import { SkipToGeneralReviewModal } from '../components/SkipToGeneralReviewModal';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { ContentContainer } from './ContentContainer';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory<{ isSidebarExpanded?: boolean }>(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        height: GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
    },
    presetControlsContainer: {
        marginBottom: 20,
    },
}));

interface CurrentWaxupScreenProps {
    submit: () => Promise<unknown>;
}

export const CurrentWaxupDesktopScreen: React.VFC<CurrentWaxupScreenProps> = ({ submit }) => {
    const { selectedTab, enableGuidedWaxupComparison, isSidebarExpanded, onlyShowModelViewer } =
        useGuidedWaxupContext();
    const guidedPreset = isGuidedPreset(selectedTab);
    const classes = useStyles({ isSidebarExpanded });

    return (
        <Grid container className={classes.root}>
            <SkipToGeneralReviewModal />
            <IncompletedWorkReminderModal />
            <PresetTabsSidebar />
            <ContentContainer submit={submit}>
                {enableGuidedWaxupComparison && <RevisionHistoryTabs />}
                <CurrentWaxupModelViewerAndControls />
                {!onlyShowModelViewer && (
                    <Grid
                        item
                        container
                        justifyContent={guidedPreset ? 'space-between' : 'flex-end'}
                        className={classes.presetControlsContainer}
                        key={selectedTab}
                    >
                        {guidedPreset && <GuidedPresetControls />}
                    </Grid>
                )}
            </ContentContainer>
            {!onlyShowModelViewer && <GuidedWaxupTimelineAndImagesSidebar />}
        </Grid>
    );
};

import { AddUser } from './AddUser';
import { useUsersAction } from './state/Users.actions';
import { useUsersSelector } from './state/Users.context';
import type { UserScreen } from './state/Users.types';
import type { SubNavigationEntry } from '@orthly/ui';
import { SubNavigation } from '@orthly/ui';
import { stylesFactory } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

type ScreenTab = {
    screen: UserScreen;
    title?: string;
};
const generateSectionScreens = (section: ScreenTab[]) => {
    return section.map(tab => ({
        value: tab.screen,
        label: tab.title ?? _.startCase(_.last(tab.screen.split('__'))),
    }));
};

const generateScreens = (): SubNavigationEntry<UserScreen>[][] => {
    const sections: ScreenTab[][] = [
        [{ screen: 'all' }],
        [
            { screen: 'internal__all', title: 'All Internal Users' },
            { screen: 'internal__admin' },
            { screen: 'internal__billingAdmin' },
            { screen: 'internal__accountManager' },
            { screen: 'internal__salesAccountExecutive' },
            { screen: 'internal__customerExperience' },
            { screen: 'internal__designManager' },
            { screen: 'internal__designStaff' },
            { screen: 'internal__developer' },
            { screen: 'internal__employee' },
            { screen: 'internal__orderOperations' },
            { screen: 'internal__configurationsAdmin' },
            { screen: 'internal__automationsAdmin' },
            { screen: 'internal__labManagementAdmin' },
        ],
        [
            { screen: 'practice__all', title: 'All Practice Users' },
            { screen: 'practice__admin' },
            { screen: 'practice__doctor' },
            { screen: 'practice__billing' },
            { screen: 'practice__assistant' },
            { screen: 'practice__receptionist' },
            { screen: 'practice__office_manager' },
        ],
        [
            { screen: 'lab__all', title: 'All Lab Users' },
            { screen: 'lab__admin' },
            { screen: 'lab__designer' },
            { screen: 'lab__qc', title: 'QC' },
        ],
        [{ screen: 'deactivated' }],
    ];

    return sections.map(generateSectionScreens);
};

const useStyles = stylesFactory(() => ({
    list: { width: '100%', bottom: 80, top: 64, position: 'absolute', overflow: 'auto' },
    bottomContainer: { position: 'absolute', bottom: 16, padding: 16 },
}));

export const UsersNavigation: React.FC = () => {
    const classes = useStyles();
    const stopEditing = useUsersAction('STOP_EDITING');
    const screen = useUsersSelector(s => s.view.screen);
    const setScreen = useUsersAction('SCREEN_SET');
    const mainScreenSections = generateScreens();
    const navigate = useNavigate();

    return (
        <>
            <SubNavigation<UserScreen>
                title={'Users'}
                classes={{ list: classes.list, bottomContainer: classes.bottomContainer }}
                titleAction={<AddUser />}
                value={screen}
                onChange={screen => {
                    stopEditing();
                    navigate('/users');
                    setScreen({ screen });
                }}
                sections={mainScreenSections}
            />
        </>
    );
};

import { ORDER_DETAIL_LIST_ITEM_HEIGHT } from '../../../../components/OrderDetailSidebar/OrderDetailSidebarItemLayout';
import { OrderDetailSidebarLayout } from '../../../../components/OrderDetailSidebar/OrderDetailSidebarLayout';
import { ORDER_LIST_ITEM_HEIGHT } from '../../../../components/OrderListItem/OrderListItemLayout';
import { RouterUtils } from '../../../../utils/router/RouterUtils';
import { useCurrentOrderDetailId } from '../../../../utils/router/useOpenOrderDetail';
import { usePartnerAndLabNames } from '../../../../utils/usePartnerAndLabNames';
import { useInboxState, OpsInboxScreenLabels } from '../../state/Inbox.selectors';
import { useInboxTasks } from '../../state/InboxTasksProvider';
import { InboxDetailSidebarItem } from './InboxDetailSidebarItem';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { Text, Grid, IconButton, Tooltip, ChevronLeft, RefreshIcon } from '@orthly/ui-primitives';
import { useVirtualizedListOrders, WorkflowTasksVirtualList, useOrderToOpsListItemContent } from '@orthly/veneer';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const InboxDetailSidebarTitle: React.FC = () => {
    const { refetch, loading: tasksLoading } = useInboxTasks();
    const screenLabel = useInboxState(s =>
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        s.screen === LabsGqlWorkflowTaskType.ResolveScanRejection
            ? 'Flagged scans'
            : s.screen === 'Custom'
              ? s.view.title
              : OpsInboxScreenLabels[s.screen],
    );
    const navigate = useNavigate();
    return (
        <Grid container alignItems={'center'} wrap={'nowrap'}>
            <Grid container alignItems={'center'}>
                <IconButton size={'small'} onClick={() => navigate(RouterUtils.pathForScreen('tasks'))}>
                    <ChevronLeft />
                </IconButton>
                <Text style={{ marginLeft: 8 }} variant={'h5'}>
                    {screenLabel}
                </Text>
            </Grid>
            <IconButton onClick={() => refetch()} disabled={tasksLoading} size={'small'} style={{ marginRight: -4 }}>
                <Tooltip title={'Refresh task list'}>
                    <RefreshIcon />
                </Tooltip>
            </IconButton>
        </Grid>
    );
};

export const InboxDetailSidebar: React.FC = () => {
    const selectedOrderId = useCurrentOrderDetailId();
    const { orderIds, loading } = useInboxTasks();
    const { labNamesById, partnerNamesById } = usePartnerAndLabNames();
    const toListItemContent = useOrderToOpsListItemContent(labNamesById, partnerNamesById);
    const listProps = useVirtualizedListOrders({
        orderIds,
        itemHeight: ORDER_LIST_ITEM_HEIGHT,
        idsLoading: loading,
        initialFocusedId: selectedOrderId,
        orderToListItemContent: toListItemContent,
    });
    const { tasks } = useInboxTasks();
    return (
        <OrderDetailSidebarLayout toolbarContent={<InboxDetailSidebarTitle />} position={'left'} loading={loading}>
            <WorkflowTasksVirtualList
                listItemHeight={ORDER_DETAIL_LIST_ITEM_HEIGHT}
                ListItem={InboxDetailSidebarItem}
                tasks={tasks}
                {...listProps}
            />
        </OrderDetailSidebarLayout>
    );
};

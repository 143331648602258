import { automaticAtom, shadeDataAtom, toothRefAtom, downloadCompleteAtom, controlPointsAtom } from '../../store/store';
import { prepStl } from '../../utils/PrepStl';
import { DEFAULT_CONTROL_POINTS, pickShade } from '../../utils/ShadeValues';
import { teethHexValues } from '@orthly/items';
import { Button, styled } from '@orthly/ui-primitives';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';

const UploadSTLButton: React.FC = () => {
    const setToothRef = useSetAtom(toothRefAtom);
    const setShadeData = useSetAtom(shadeDataAtom);
    const setControlPoints = useSetAtom(controlPointsAtom);
    const automatic = useAtomValue(automaticAtom);
    const [fileList, setFileList] = React.useState<File[]>([]);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [downloadComplete, setDownloadComplete] = useAtom(downloadCompleteAtom);

    const processFile = React.useCallback(
        (file: File | undefined) => {
            if (!file) {
                return;
            }

            function isKeyOfTeethHexValues(key: string): key is keyof typeof teethHexValues {
                return key in teethHexValues;
            }

            function parseFilename(filename: string): keyof typeof teethHexValues | undefined {
                const nameParts = filename.split('_');
                const shade = nameParts[0];
                if (shade && isKeyOfTeethHexValues(shade)) {
                    return shade;
                }
                return undefined;
            }

            const shade = parseFilename(file.name);
            if (shade) {
                setShadeData(pickShade(shade));
                setControlPoints(DEFAULT_CONTROL_POINTS);
            }
            const reader = new FileReader();
            reader.onload = e => {
                const contents = e.target?.result as ArrayBuffer;
                const loader = new STLLoader();
                const geometry = loader.parse(contents);
                const mesh = prepStl(geometry);
                const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, ''); // Remove extension
                mesh.userData.fileName = fileNameWithoutExtension;
                setToothRef(mesh);
            };
            reader.readAsArrayBuffer(file);
        },
        [setToothRef, setShadeData, setControlPoints],
    );

    function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;
        if (files) {
            const fileArray = Array.from(files);
            setFileList(fileArray);
            processFile(fileArray[0]);
        }
    }

    React.useEffect(() => {
        if (automatic && downloadComplete && currentIndex < fileList.length - 1) {
            setDownloadComplete(false);
            setCurrentIndex(prevIndex => prevIndex + 1);
            processFile(fileList[currentIndex + 1]);
        }
    }, [downloadComplete, automatic, currentIndex, fileList, setDownloadComplete, processFile]);

    function handleButtonClick() {
        document.getElementById('stl-upload-input')?.click();
    }

    const UploadButton = styled(Button)({
        marginBottom: '10px',
    });

    const STLInput = styled('input')({
        display: 'none',
    });

    return (
        <>
            <UploadButton variant={'secondary'} onClick={handleButtonClick}>
                Upload STL
            </UploadButton>
            <STLInput
                type={'file'}
                accept={'.stl'}
                multiple={automatic}
                id={'stl-upload-input'}
                onChange={handleFileUpload}
            />
        </>
    );
};

export default UploadSTLButton;

import { UserToolbar } from '../UserToolbar';
import { useUsersAction } from '../state/Users.actions';
import { useUsersSelector } from '../state/Users.context';
import { DesignStaffSection } from './DesignStaffSection';
import { buildIteroInfo } from './EditUser.util';
import { EditUserCommonFields } from './EditUserCommonFields';
import { EditUserRoles } from './EditUserRoles';
import { SalesforceSync } from './SalesforceSync';
import { useUpsertUser } from './hooks/useUpsertUser';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useGetUserQuery, useListUserPositionsQuery } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { Grid, Divider, Button, Text } from '@orthly/ui-primitives';
import { isEqual } from 'lodash';
import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

export const EditUser_Query = graphql(`
    query EditUserQuery($staffIds: [String!]!) {
        getIteroUsersByStaffIds(staff_member_ids: $staffIds) {
            itero_doctor_id
            staff_member_id
            should_immediately_place_order
        }
        getStaffMembersOnboardingPreferences(staff_member_ids: $staffIds) {
            staff_member_id
            wants_preference_review
            set_dandy_as_preferred_lab_status
        }
    }
`);

export const EditUser: React.FC = () => {
    const { open } = useUsersSelector(s => ({ open: s.open }));
    const { edited } = useUsersSelector(s => ({ edited: s.edited }));
    const editUser = useUsersAction('START_EDITING');
    const [requiredFieldsCompleted, setRequiredFieldsCompleted] = React.useState<boolean>(false);

    const { roles } = useUsersSelector(s => ({
        roles: s.form?.roles ?? [],
    }));

    const staffIds = roles.map(r => r.member_id || '').filter(Boolean) || [];

    const { data: iteroData, loading: __loading } = useQuery(EditUser_Query, {
        variables: {
            staffIds,
        },
        skip: !staffIds.length,
    });

    const iteroInfo = buildIteroInfo(iteroData);

    // load user from network
    const { id } = useParams<{ id: string }>();
    const isNew = useRouteMatch('/users/new');

    const { data: userData } = useGetUserQuery({
        variables: { id },
        skip: !id || !!isNew,
    });

    // if we are loading from network, put that user data into state
    const getUser = userData?.getUser;
    const userFromState = useUsersSelector(s => s.user);
    if (id && getUser && !isEqual(getUser, userFromState)) {
        editUser({ user: getUser });
    }

    if (!!isNew && userFromState) {
        editUser({ user: undefined });
    }

    const user = useUsersSelector(s => s.user);
    const onRolesLoaded = useUsersAction('ROLES_LOADED');
    const { submit: upsertUser, submitting } = useUpsertUser();

    useListUserPositionsQuery({
        variables: { user_id: user?.id ?? '', active: null },
        skip: !user,
        onCompleted: result => {
            const roles = result.listUserPositions;
            if (roles) {
                onRolesLoaded({ roles });
            }
        },
    });

    if (!open) {
        return null;
    }

    const name = user ? `${user.first_name} ${user.last_name}` : 'New User';

    return (
        <Grid container style={{ height: '100vh', overflow: 'auto', alignContent: 'flex-start' }}>
            <UserToolbar />
            <Grid container direction={'column'} style={{ padding: '16px 16px 48px' }}>
                <Grid container spacing={1} item direction={'column'}>
                    <Grid item>
                        <Grid container spacing={2} style={{ padding: '8px 0px 16px 0px' }} direction={'row'}>
                            <Grid item>
                                <Text variant={'h3'}>{user ? 'Editing' : 'New User'}</Text>
                            </Grid>
                            {user && (
                                <Grid item>
                                    <Text variant={'h3'} color={'DARK_GRAY'}>
                                        {name}
                                    </Text>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <EditUserCommonFields setRequiredFieldsCompleted={setRequiredFieldsCompleted} />
                    </Grid>
                    <Grid item>
                        <LoadBlocker blocking={submitting}>
                            <Button
                                startIcon={'Save'}
                                onClick={upsertUser}
                                variant={'primary'}
                                disabled={!edited || !requiredFieldsCompleted}
                            >
                                Save User
                            </Button>
                        </LoadBlocker>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                </Grid>
                {id && (
                    <>
                        <SalesforceSync
                            userId={user?.id || ''}
                            salesforceContactId={user?.salesforce_contact_id || ''}
                        />
                        <Grid item>
                            <Divider />
                        </Grid>
                        <EditUserRoles iteroInfo={iteroInfo} />
                        <DesignStaffSection userId={id} />
                    </>
                )}
            </Grid>
        </Grid>
    );
};

import { getScanPayloadItems, useDesignModelPayloadsForScan } from '../DesignViewer';
import { FileUpload, UploadedFiles, useOrderFileUpload } from '../FirebaseUpload';
import type { ImpersonatedQRCodeLinkResult } from '../QRCode';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type {
    RefabAttachment,
    RefabFlowLabOrder,
    TextUploadLinkResult,
    MainViewModelRef,
    MainViewCameraControlsRef,
    ModelAppearance,
    ModelPayload,
    ModelPayloadItem,
} from '@orthly/dentin';
import {
    useRefabFlowSelector,
    useRefabFlowAction,
    AttachScansUtils,
    orderMaterialCanHaveLayers,
    NewModelViewer,
    MODEL_VIEWER_INITIAL_APPEARANCE,
} from '@orthly/dentin';
import type { LabsGqlPatientScanFileFragment } from '@orthly/graphql-operations';
import { useScanFilesForPatientQuery } from '@orthly/graphql-react';
import { LoadBlocker, RootActionDialog } from '@orthly/ui';
import {
    CheckIcon,
    Tabs,
    Tab,
    Text,
    useScreenIsMobileOrVerticalTablet,
    styled,
    FlossPalette,
} from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

type TabOption = 'diagnostic' | 'chairside' | 'upload';

const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: '100%',
    width: '100%',
});

const TabLabelWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '14px',
});

const TabBadge = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    backgroundColor: FlossPalette.DARK_TAN,
    borderRadius: '2px',
    paddingRight: '6px',
    paddingLeft: '6px',
});

const ContentWrapper = styled('div')(({ grow, isMobile }: { grow?: boolean; isMobile?: boolean }) => ({
    width: isMobile ? '100%' : '700px',
    display: 'flex',
    minHeight: 0,
    flex: grow === false ? undefined : 1,
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    borderRadius: '8px',
    padding: 0,
    overflow: 'scroll',
}));

const EmptyText = styled(Text)({
    margin: 'auto auto auto auto',
});

const ScanRowWrapper = styled('div')(({ selected }: { selected: boolean }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: '12px',
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    backgroundColor: selected ? FlossPalette.PRIMARY_BACKGROUND : undefined,
    '&:last-child': {
        borderBottom: 'none',
    },
}));

const ScanRowLeft = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const ScanRowRight = styled('div')({
    display: 'flex',
    gap: '16px',
    flexDirection: 'row',
    marginLeft: 'auto',
    flexWrap: 'wrap',
    alignContent: 'center',
    '& a': {
        textDecoration: 'none',
    },
});

const ScanRowButton = styled('button')({
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
});

const FileUploadSideBySide = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: '24px',
    height: '280px',
    alignItems: 'center',
});

const UploadedFilesWrapper = styled('div')({
    padding: '0 24px 24px 24px',
});

type PreviewDialogContentProps = {
    order: RefabFlowLabOrder;
    designPayloads: ModelPayload[];
};
const PreviewDialogContent: React.VFC<PreviewDialogContentProps> = ({ order, designPayloads }) => {
    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);
    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);

    const modelPayloadItems = React.useMemo<ModelPayloadItem[]>(
        () => getScanPayloadItems(designPayloads),
        [designPayloads],
    );

    if (!order) {
        return null;
    }

    return (
        <NewModelViewer
            orderMaterialsHaveLayers={orderMaterialCanHaveLayers(order)}
            designQcConfig={{ appearance, setAppearance, modelRef, controlRef }}
            model_payload_items={modelPayloadItems}
            hideUi
            disableHotKeys
            showOrderScans
            enableNewScanMeshMaterial
            enable_qc_tools={{
                enableAnatomyLayers: false,
                enableCollisions: false,
                enableCrossSections: false,
                enableDynamicHeatmaps: false,
                enableHeatmaps: false,
                enableUndercutHeatmap: false,
                enableTissuePressureHeatmap: false,
                enableMarginLines: false,
                enableDoctorMarginLines: false,
                enableDoctorToothMarkings: false,
                editMarginLines: false,
                enableUndercutView: false,
            }}
        />
    );
};

type PreviewDialogProps = {
    order?: RefabFlowLabOrder;
    previewed: LabsGqlPatientScanFileFragment | null;
    onClose: () => void;
};
const PreviewDialog: React.VFC<PreviewDialogProps> = ({ order, previewed, onClose }) => {
    const { result: designPayloads, loading: designPayloadsLoading } = useDesignModelPayloadsForScan(
        previewed?.id || null,
    );

    if (!order || !previewed) {
        return null;
    }

    let content = null;
    if (order && !designPayloadsLoading && designPayloads) {
        content = <PreviewDialogContent order={order} designPayloads={designPayloads} />;
    }

    return (
        <RootActionDialog
            title={'Previewing Scan'}
            setOpen={open => !open && onClose()}
            open={previewed !== null}
            content={content}
            dialogProps={{ maxWidth: 'md' }}
            onClose={onClose}
            showCloseButton
            loading={designPayloadsLoading}
            hideButton
        />
    );
};

type TabLabelProps = {
    title: string;
    count: number;
};
const TabLabel: React.VFC<TabLabelProps> = ({ title, count }: TabLabelProps) => {
    return (
        <TabLabelWrapper>
            {title}
            <TabBadge>
                <Text variant={'caption'} medium color={'GRAY'}>
                    {count}
                </Text>
            </TabBadge>
        </TabLabelWrapper>
    );
};

type ScanRowProps = {
    scan: LabsGqlPatientScanFileFragment;
    selected: boolean;
    onToggle: () => void;
    onPreview: () => void;
};
const ScanRow: React.VFC<ScanRowProps> = ({ scan, selected, onToggle, onPreview }: ScanRowProps) => {
    return (
        <ScanRowWrapper selected={selected}>
            <ScanRowLeft>
                <Text variant={'body2'} medium color={'BLACK'} sx={{ display: 'flex', gap: '4px' }}>
                    {selected && <CheckIcon style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />}
                    {AttachScansUtils.getScanTitle(scan)}
                </Text>
                <Text variant={'caption'} color={'GRAY'}>
                    Scanned: {dayjs(scan.created_at).format('MM/DD, h:mma')}
                </Text>
            </ScanRowLeft>
            <ScanRowRight>
                <ScanRowButton onClick={onPreview}>
                    <Text variant={'body2'} medium color={'PRIMARY_FOREGROUND'}>
                        Preview
                    </Text>
                </ScanRowButton>
                <ScanRowButton onClick={onToggle}>
                    <Text variant={'body2'} medium color={'PRIMARY_FOREGROUND'}>
                        {selected ? 'Unselect' : 'Select'}
                    </Text>
                </ScanRowButton>
            </ScanRowRight>
        </ScanRowWrapper>
    );
};

type FileUploaderProps = {
    orderId: string;
    itemId?: string;
    setAttachments: (attachments: RefabAttachment[]) => void;
    qrCode?: ImpersonatedQRCodeLinkResult;
    textUploadLink?: TextUploadLinkResult;
};
const FileUploader: React.VFC<FileUploaderProps> = ({
    orderId,
    itemId,
    setAttachments,
    qrCode,
    textUploadLink,
}: FileUploaderProps) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { onDropAccepted, onRemoveFile, files } = useOrderFileUpload({
        orderId,
        itemId,
        analyticsMetadata: {
            productName: 'order refab scan files',
            device: 'web',
        },
        forRefab: true,
        setAttachments,
    });

    if (!textUploadLink) {
        return null;
    }

    return (
        <ContentWrapper grow={false}>
            <FileUploadSideBySide>
                <FileUpload
                    title={''}
                    onDropAccepted={onDropAccepted}
                    qrCode={qrCode ?? {}}
                    textUploadLink={textUploadLink}
                    allowMultipleFiles
                    isMobile={isMobile}
                />
            </FileUploadSideBySide>
            <UploadedFilesWrapper>
                <UploadedFiles files={files} onRemoveFile={onRemoveFile} maxHeight={385} maxThumbnailSize={60} />
            </UploadedFilesWrapper>
        </ContentWrapper>
    );
};

type Props = {
    order?: RefabFlowLabOrder;
    qrCode?: ImpersonatedQRCodeLinkResult;
    textUploadLink?: TextUploadLinkResult;
};

export const RefabAttachExistingScans: React.VFC = ({ order, qrCode, textUploadLink }: Props) => {
    const [tab, setTab] = React.useState<TabOption>('diagnostic');
    const [previewed, setPreviewed] = React.useState<LabsGqlPatientScanFileFragment | null>(null);
    const selected = useRefabFlowSelector(s => s.selectedScan);
    const patchState = useRefabFlowAction('PATCH_STATE');
    const isMobile = useScreenIsMobileOrVerticalTablet();

    const { data, loading } = useScanFilesForPatientQuery({
        variables: { patient_id: order?.patient.id || '' },
    });

    React.useEffect(() => {
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Portal - Refab Flow - Attach Existing Scans Displayed',
            {
                $groups: { order: order?.id || '' },
            },
        );
    }, [order?.id]);

    if (!order) {
        return null;
    }

    function setSelected(selected: LabsGqlPatientScanFileFragment | null): void {
        patchState({ selectedScan: selected });
    }

    const diagnosticScans: LabsGqlPatientScanFileFragment[] = [];
    const chairsideScans: LabsGqlPatientScanFileFragment[] = [];
    for (const scan of data?.scan_files_for_patient || []) {
        if (scan.id === order.scan_export_id) {
            continue;
        }

        if (AttachScansUtils.isDiagnosticScan(scan)) {
            diagnosticScans.push(scan);
        } else {
            chairsideScans.push(scan);
        }
    }

    const setAttachments = (attachments: RefabAttachment[]) => patchState({ attachments });

    let content = (
        <FileUploader
            orderId={order.id}
            setAttachments={setAttachments}
            qrCode={qrCode}
            textUploadLink={textUploadLink}
        />
    );
    if (tab !== 'upload') {
        const scansToRender = tab === 'diagnostic' ? diagnosticScans : chairsideScans;
        const renderedScans = scansToRender.map(scan => {
            const onPreview = () => {
                setPreviewed(scan);
                BrowserAnalyticsClientFactory.Instance?.track(
                    'Practice - Portal - Refab Flow - Attach Existing Scans Preview',
                    {
                        $groups: { order: order.id },
                        scanExportId: scan.id,
                        tab,
                    },
                );
            };

            const onToggle = () => {
                let scanExportId = null;
                if (selected?.id === scan.id) {
                    setSelected(null);
                } else {
                    setSelected(scan);
                    scanExportId = scan.id;
                }
                BrowserAnalyticsClientFactory.Instance?.track(
                    'Practice - Portal - Refab Flow - Attach Existing Scans Selected',
                    {
                        $groups: { order: order.id },
                        scanExportId,
                        tab,
                    },
                );
            };

            return (
                <ScanRow
                    key={scan.id}
                    scan={scan}
                    selected={scan.id === selected?.id}
                    onToggle={onToggle}
                    onPreview={onPreview}
                />
            );
        });

        let emptyMessage = null;
        if (scansToRender.length === 0) {
            emptyMessage = (
                <EmptyText variant={'body2'} color={'GRAY'}>
                    No {tab} scans found
                </EmptyText>
            );
        }

        content = (
            <ContentWrapper className={'scrollbar-override'} isMobile={isMobile}>
                {renderedScans}
                {emptyMessage}
            </ContentWrapper>
        );
    }

    return (
        <LoadBlocker blocking={loading} loader={'circular'} ContainerProps={{ sx: { height: '100%' } }}>
            <Wrapper>
                <Tabs value={tab} onChange={(_, value) => setTab(value)}>
                    <Tab
                        value={'diagnostic'}
                        label={<TabLabel title={'Diagnostic Scans'} count={diagnosticScans.length} />}
                    />
                    <Tab
                        value={'chairside'}
                        label={<TabLabel title={'Chairside Scans'} count={chairsideScans.length} />}
                    />
                    {!isMobile ? <Tab value={'upload'} label={'Upload from Computer'} /> : null}
                </Tabs>
                {content}
                <PreviewDialog order={order} previewed={previewed} onClose={() => setPreviewed(null)} />
            </Wrapper>
        </LoadBlocker>
    );
};
